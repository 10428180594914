.main-wrapper {
    background-color: #080C29;
    position: relative;
    padding-top: 16px;

    &.white-bg {
        background-color: #fff;
    }

    .container-wrapper {
        background: #fff;
        border-radius: 5px;
        // border: 1px solid black;
        margin-top: 80px !important;

        @include mediaquery(md, max) {
            padding: 0 25px !important;
        }

        &.remove-border {
            border: none;
        }

        &.register-page__max-width {
            max-width: 800px;
        }
    }
}

.supplier-main-wrapper {
    position: relative;

    &.white-bg {
        background-color: #fff;
    }
}
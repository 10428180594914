.main-wrapper, .supplier-main-wrapper, .web-catalogue-wrapper, .website-product-details-wrapper, .company-profile-wrap {
    
    .button-primary {
        background-color: $primary-btn-color !important;
        mix-blend-mode: normal;
        border-radius: 5px;
        border: none;
        box-shadow: none;
        // height: 50px;
        // margin-top: 10px;
        color: #fff !important;
        font-family: $font-inter !important;
        padding: 10px 60px;
        font-size: 14px;
        &:hover {
            background-color: #1841f6 !important;
            @include mediaquery(md, max) {
                background-color: #1841f6 !important;
            }
        }
        @include mediaquery(md, max) {
            // font-size: 12px;
            padding: 10px 30px;
        }

        &.brand-button-cust-style {
            font-size: 16px;
            padding: 8px 40px;
            height: 44px;
            @include mediaquery(md, max) {
                width: 228px;
                padding: 0;
            }
        }
        &.button-plus {
            @include mediaquery(md, max) {
                width: 64px;
            }
        }
        &.onboarding-cust-style {
            padding: 13px 56px;
        }
    }

    .button-shadow {
        box-shadow: -3px 0px 8px rgb(0 0 0 / 25%) !important;
    }

    // blue border button
    .button-with-blue-border {
        border: 1px solid $secondary-color !important;
        border-radius: 5px !important;
        font-weight: 600 !important;
        cursor: pointer !important;
        padding: 5px 27px !important;
        font-family: $font-inter !important;
        font-size: 14px !important;
        line-height: 24px !important;
        text-transform: capitalize;
        color: $secondary-color !important;
        a {
            color: $secondary-color !important;
            text-decoration: none;
            @include mediaquery(md, max) {
                font-weight: 500 !important;
            }
        }
        &:hover {
            background-color: $primary-btn-color !important;
            color: #fff !important;
            a {
                color: #fff !important;
            }
        }
        @include mediaquery(md, max) {
            font-size: 10px !important;
            padding: 0px 10px !important;
        }

        &.cust-button-brand {
            color: $secondary-color;
            border-radius: 2px !important;
            cursor: default !important;
            &:hover {
                color: $secondary-color;
                background-color: transparent !important
            }
            @include mediaquery(md, max) {
                font-size: 14px !important;
            }
        }
    }

    .btn-primary-after-login {
            background-color: $primary-btn-color;
            padding: 5px 13px;
            text-transform: capitalize;
            font-family: $font-inter;
            color: #fff;
            border-radius: 5px;
            &:hover {
                background: lighten($primary-btn-color, 10%);
            }   
            &:focus {
                box-shadow:0px 0px 0 3px rgb(216 223 255) !important;
                transition:opacity .25s,box-shadow .25s
            }

            &.btn-modal {
                width: 73%;
                @include mediaquery(md, max) {
                    width: 100%;
                }
            }
    }

    .btn-secondary-after-login {
            background-color: transparent;
            color: #262626;
            border: #D4D4D4 solid 1px;
            padding: 5px 13px;
            font-family: $font-inter;
            cursor: pointer;
            border-radius: 5px;
            &:hover {
                background: #D4D4D4;
                border: #D4D4D4 solid 1px;
            }      
            &:focus {
                box-shadow:0px 0px 0 3px rgb(214 214 214 / 29%) !important;
                transition:opacity .25s,box-shadow .25s
            }
    }

    .button-with-blue-border {
        background-color: transparent;
        color: $secondary-color;
        border: $secondary-color solid 1px;
        padding: 5px 13px;
        font-family: $font-inter;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
            background: $secondary-color;
            color: #fff;
            border: $secondary-color solid 1px;
        }      
        &:focus {
            box-shadow:0px 0px 0 3px rgb(214 214 214 / 29%) !important;
            transition:opacity .25s,box-shadow .25s
        }
    }

    .button-with-blue-border-no-effect {
        background-color: transparent;
        color: $secondary-color;
        border: $secondary-color solid 1px;
        padding: 5px 13px;
        font-family: $font-inter;
        cursor: pointer;
        border-radius: 5px;
        text-transform: capitalize;
        &:hover {
            background: transparent;
        }
        // &:hover {
        //     background: $secondary-color;
        //     color: #fff;
        //     border: $secondary-color solid 1px;
        // }      
        // &:focus {
        //     box-shadow:0px 0px 0 3px rgb(214 214 214 / 29%) !important;
        //     transition:opacity .25s,box-shadow .25s
        // }
    }

    // white border button
    .button-with-white-border {
        border: 1px solid #fff !important;
        border-radius: 5px !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        text-transform:capitalize !important;
        color: #fff !important;
        cursor: pointer !important;
        padding: 6px 26px 7px 27px !important;
        font-family: $font-inter !important;
        line-height: 24px !important;
        a {
            text-decoration: none !important;
            color: #ffffff;
            @include mediaquery(md, max) {
                font-weight: 500 !important;
            }
        }
        &:hover {
            background-color: #fff;
            color: $primary-color !important;
            a {
                color: $primary-color !important;
            }
        }

        @include mediaquery(md, max) {
            font-size: 10px !important;
            padding: 0px 10px !important;
        }
    }


    // back button
    .button-back {
        border: 1px solid #97A8B8;
        border-radius: 5px;
        background: #fff;
        text-align: center;
        color: #97A8B8;
        margin-right: 10px;
        box-shadow: none;
        padding: 12px 55px 12px 54px;
        font-size: 14px;
        &:hover {
            background-color: #97A8B8;
            box-shadow: none;
            color: #fff;
        }

        @include mediaquery(md) {
        margin-right: 20px;
        }
    }

    .button-status {
        border-radius: 2px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 5px 7px;

        &.out-of-stock {
            border: 1px solid #EA4E2C;
            color: #eb4d2c;
        }
        
        &.draft {
            border: 1px solid $secondary-color;
            color: $secondary-color;
        }

        &.detail {
            border: 1px solid $secondary-color;
            background-color: rgba(124, 182, 255, 30%);
            color: $secondary-color;
            cursor: pointer;
            border-radius: 5px;
            margin: 0 20px;
            &:hover {
                background-color: $secondary-color;
                color: #fff;
            }
            &:focus {
                box-shadow:0px 0px 0 3px rgb(216 223 255) !important;
                transition:opacity .25s,box-shadow .25s
            }
        }
    }
}
.right-panel {
    padding: 25px;

    .setting-section-wrapper {
        .setting-wrap {
            padding: 10px 0 0 0;
            .heading-wrap {
                .heading {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 32px;
                    display: flex;
                    align-items: center;
                    color: #262626;

                    @include mediaquery(md, max) {
                        font-size: 16px;
                    }
                }

                .close-icon {
                    display: none;
                }
            }

            .setting {
                grid-column-gap: 50px;

                @include mediaquery(md, max) {
                    grid-column-gap: 20px;
                    // overflow-y: scroll;
                    // height: 300px;
                }

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #313E42;
                }
            }

            .button-wrapper {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                border-top: #cccccc solid 1px;
                width: calc(80% - 60px);
                padding-top: 18px;
                z-index: -1;
                @include mediaquery(md, max) {
                    width: calc(100% - 30px);
                }
                button {
                    width: 100px;
                }

                &.save-btn {
                    z-index: 1;
                    background: #fff;
                }
            }
        }
    }
}
.create-brand-wrapper {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 6;

    .popup-wrapper {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
        @include mediaquery(md, max) {
            width: 80%;
            zoom: 84%;
        }

        @include mediaquery(md) {
            width: 740px;
        }

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                cursor: pointer;
                height: 25px;
                width: 25px;
                fill : #C4C4C4;
            }

            &:hover {
                svg {
                    fill: red;
                }
            }
        }

        .first-heading {
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            text-align: center;
            color: $primary-color;
            @include mediaquery(md, max) {
                font-size: 20px;
            }
        }

        .second-heading {
            margin-top: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #262626;
            opacity: 0.7;
        }

        .form-input-control {
            width: 70%;
            margin-top: 30px;
            @include mediaquery(md, max) {
                width: 90%;
            }
            .label {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 10px;
            }

            .brand-logo-section-wrap {
                .logo-wrap {
                    position: relative;
                    width: 160px;
                    height: 160px;

                    .logo {
                        margin-top: 20px;
                        width: 160px;
                        height: 160px;
                        background: #F3F3F4;
                        border-radius: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(8, 12, 41, 0.4);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border: #F3F3F4 solid 1px;
                        &:hover {
                            border: $secondary-color dashed 1px;
                        }
                    }

                    .edit-icon {
                        position: absolute;
                        bottom: -10%;
                        left: 35%;
                        @include mediaquery(md, max) {
                            left: unset;
                            right: -10px;
                        }
                        @include mediaquery(md) {
                            bottom: -6%;
                            left: 85%;
                        }
                        svg {
                            circle {
                                stroke: $secondary-color;
                            }
                        }
                        &:hover  {
                            svg {
                                circle {
                                    fill: white;
                                }
                                g {
                                    path {
                                        stroke: $secondary-color;
                                        fill: $secondary-color;
                                    }
                                }
                            }
                        }
                    }
                }

                div {
                    cursor: pointer;

                    img {
                        width: 160px;
                        height: 160px;
                        // object-fit: contain;
                    }
                }
            }

            .input-wrap {
                width: 100%;

                input {
                    &::placeholder {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(8, 12, 41, 0.4);
                    }
                }
            }

            .hr {
                opacity: 0.1;
                border: 1px solid #000000;
                margin-bottom: 28px;
            }

            .button-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
    }
}
$site-max-width: 1030px;

// Fonts
$font-inter: 'Inter', sans-serif;

// text colors
$primary-color: #313E42;
$secondary-color: #3357FF;
$anchor-tag-color: #5b8ed6;
$toast-msg-text-color: #313E42;
$default-text-color:#313e42;

// button colors
$primary-btn-color: #3357FF;

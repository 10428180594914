.login-container {
	.container-wrapper {
		// max-width: 474px !important;
		.login-component-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-family: $font-inter;

			form {
				// width: 100%;

				div {
					width: 100%;
					input {
						border: none;
					}
				}

				

				// .label {
				//   font-style: normal;
				//   font-weight: 500;
				//   font-size: 14px;
				//   line-height: 20px;
				//   color: $primary-color;
				// }

				.show-label {
					font-weight: 500;
					font-size: 12px;
					line-height: 20px;
					color: $primary-color;
					cursor: pointer;
				}

				input {
					// background: #f3f3f4;
					// border-radius: 5px;
					// border: none;
				}
				// ::placeholder {
				//   font-style: normal;
				//   font-weight: 400;
				//   font-size: 14px;
				//   line-height: 17px;
				//   color:$primary-color;
				//   opacity: 0.4;
				// }

				.login-password__hide-show {
					font-weight: 500;
					font-size: 12px;
					line-height: 20px;
					font-family: $font-inter;
				}

				.submit-btn {
				// mix-blend-mode: normal;
				// border-radius: 5px;
				// border: none;
				// box-shadow: none;
				// height: 50px;
				// margin-top: 10px;
				}

				.button-primary {
					@include mediaquery(md, max) {
						margin-top: 7px;
					}
				}

				.forgot-password-label {
					font-weight: 500;
					font-size: 14px;
					line-height: 20px;
					color: $secondary-color;
					cursor: pointer;
					margin-top: 20px;
					margin-bottom: 50px;
					display: block;
					text-align: center;
					display: inline-block;
					text-decoration: none;
				}
			}
		}
	}
// @media only screen and (min-width: 767px) {
//     .login-component-wrap form {
//         width: 50%;
//     }
// }
}


.register-container {
	@include mediaquery(md, max) {
		background-color: #080C29 !important;
		padding-bottom: 20px;
	}
	.not-for-some-pages {
		display: none;
	}
	header {
		@include mediaquery(md, max) {
			background-color: #080C29 !important;
		}
		.register-logo-img {
			@include mediaquery(md, max) {
				display: none;
			}
		}
		.text {
			@include mediaquery(md, max) {
				display: flex;
				color: #fff !important;
			}
			.logo-img {
				@include mediaquery(md, max) {
					display: block !important;
				}
			}
		}
	}
	.container-wrapper {
		@include mediaquery(md, max) {
			width: 90%;
			margin: 0 auto;
			padding-bottom: 43px !important;
			margin-top: 50px !important;
		}
	}
}
.registeration-page {
	header {
		.logo-img {
			display: none;
		}
	}
}

.register-wrapper {
	padding-bottom: 10px;
	// zoom: 95%;
	// @include mediaquery(md, max) {
	// 	zoom: 100%;
	// }
	.first-heading {
		text-align: left;
		padding-top: 0;
		@include mediaquery(md, max) {
			padding-top: 40px;
		}
	}

	.second-heading {
		text-align: left;
		margin-bottom: 20px;
		padding: 0;
	}

	// 	.first-heading {
	// 		font-weight: 600;
	// 		font-size: 30px;
	// 		line-height: 34px;
	// 		color: $primary-color;
	// 		font-family: $font-inter;
	// 		margin-bottom: 5px;
	// 	}

	//   .second-heading {
	// 	font-weight: 300; //for matching design i have added 300 instead of 400
	// 	font-size: 14px;
	// 	line-height: 20px;
	// 	color: $primary-color;
	// 	font-family: $font-inter;
	//   }

	form {
		margin-top: 30px;

		input,
		#country-code-select {
			background: #f3f3f4;
			border-radius: 5px;
			color: $primary-color;
			border: none;
		}

		#country-code-select {
			zoom: 86%;
		}

		div {
			width: 100%;
		}

		// .label {
		// 	font-weight: 500;
		// 	font-size: 14px;
		// 	line-height: 20px;
		// 	color: $primary-color;
		// 	margin-bottom: 5px;
		// }
		.account-type-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-bottom: 28px;

			div {
				height: 50px;
				background: #f3f3f4;
				box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
				border-radius: 5px;

				display: flex;
				align-items: center;
				justify-content: space-around;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				color: $primary-color;
				cursor: pointer;
				width: 50%;
				// margin-left: -448px;

				@include mediaquery(md) {
					font-size: 16px;
				}

				&:first-of-type {
					border-top-right-radius: 0;
    				border-bottom-right-radius: 0;
				}
				&:last-of-type {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			.button-primary {
				padding: 0;
			}

			.selected {
				background: $primary-btn-color;
				mix-blend-mode: normal;
				box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.25);
				border-radius: 5px;
				color: #ffffff;
			}
		}

		.name-wrapper {
			display: block;
			margin-bottom: 28px;

			@include mediaquery(md) {
				display: flex;
			}

			.fname-wrapper {
				@include mediaquery(md) {
					margin-right: 50px;
				}
			}
		}

		.email-wrapper {
			margin-bottom: 28px;
		}

		.phone-number-wrapper {
			margin-bottom: 28px;

			>div {
				display: flex;
				// align-items: center;
				// border: #f3f3f4 solid 1px;
				border-radius: 5px;
				margin-top: 8px;
				// border: #f3f3f4 solid 1px;
				border: #f3f3f4 solid 1px;
				background: #f3f3f4;
				&:hover {
					// border:$secondary-color solid 1px;
					border: rgba(0, 0, 0, 0.87) solid 1px;
				}

				.country-code-wrapper {
					flex: 1 1 33%;
					margin-top: 1px;

					>div {
						// margin-top: 3px;
					}

					@include mediaquery(md) {
						flex: 1 1 25%;
					}

					div {
						border-top-right-radius: 0 !important;
						border-bottom-right-radius: 0 !important;
						top: -0.5px;
					}

					.flag-wrapper {
						padding-right: 15px;
					}
				}

				.phone-number-wrap {
					position: relative;
					>div {
						margin: 0 !important;
					}

					input {
						border-top-left-radius: 0 !important;
						border-bottom-left-radius: 0 !important;
						border: transparent solid 1px;
						&:focus {
							// border: 1px solid #f3f3f4 !important;
							border: $secondary-color solid 1px;
							
						}
					}
				}
			}

			.error {
				// border: 1px solid red;
				// border-top-right-radius: 5px;
				// border-bottom-right-radius: 5px;
				// position: absolute;
				// top: -1px;
				// left: -2px;

				input {
					border: 1px solid red !important;
					&:focus {
						// border: none !important;
					}	
				}
			}
		}

		.password-wrapper {
			position: relative;
			margin-bottom: 28px;
			height: 90px;
			z-index: 2;
			transition: all .5s;
			background: #fff;

			@include mediaquery(md) {
				margin-top: 10px;
			}

			&.height-160 {
				height: 128px;
				transition: all .5s;
			}

			.show-label {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				color: $primary-color;
				cursor: pointer;

				@include mediaquery(md) {
					font-size: 14px;
				}
			}

			.password-validation {
				transition: all 1s;
				height: 80px;
				left: 0;
				padding: 0 10px;
				position: absolute;
				transition: all .5s;
				width: 100%;
				z-index: -1;
				transform: translateY(-75%);
				visibility: hidden;
				background-color: #fff;

				&.show {
					transform: translateY(0%);
					visibility: visible;
					transition: all .5s;
				}

				p {
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: #696969;
					display: flex;

					svg {
						height: 25px;
						width: 25px;
					}
				}
			}
		}

		.disclaimer-wrapper {
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: #273042;

			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color: #273042;
			}

			@include mediaquery(md) {
				font-size: 18px;
			}

			a {
				padding: 5px;
			}
		}

		.button-wrapper {
			margin-bottom: 30px;
		}
		.margin-top-66 {
			@include mediaquery(md, max) {
				margin-top: 66px;
			}
		}
		.cookie-policy-text {
			font-size: 14px;
			margin-bottom: 20px;
			color: #313E42;
		}

		// .button-wrapper {
		//   button {
		//     mix-blend-mode: normal;
		//     border-radius: 5px;
		//     text-align: center;
		//     color: #ffffff;
		//   }
		// }
		// .submit-btn {
		//   background: $primary-btn-color;
		//   mix-blend-mode: normal;
		//   border-radius: 5px;
		//   border: none;
		//   box-shadow: none;
		//   height: 50px;
		//   margin-top: 10px;
		// }
	}

	.flag-wrapper {
		display: flex;
		align-items: center;
		img {
			height: 20px;
			width: 20px;
			margin-right: 5px;
			object-fit: contain;

			@include mediaquery(md) {
				margin-right: 20px !important;
			}
		}

	}
}
.product-marketing-wrapper {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .marketing-form {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        box-shadow: 0px 0px 50px rgba(89, 107, 198, 0.05);
        border-radius: 10px;
        min-height: 300px;
        padding: 25px 20px;

        .form-control {
            .promotional-budget {
                &:hover {
                    fieldset {
                        border-right-width: 1px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #686868;

        }

        .budget-wrapper {
            div {
                width: 100%;
                margin: 0;
                background: #F3F3F4;
                border-radius: 5px;
            }
        }

        .add-image-wrapper {
            margin-top: 25px;
            display: flex;
            gap: 20px;
            overflow-x: auto;
            overflow-y: hidden;

            @include mediaquery(md, max) {
                overflow-x: scroll;
                overflow-y: hidden;
            }

            .add-image-wrap {
                background: #F3F3F4;
                border: 1.5px dashed #979797;
                border-radius: 5px;
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #AAAAAA;
                cursor: pointer;
                flex-direction: column;

                @include mediaquery(md, max) {
                    white-space: nowrap;
                }

                .img-info {
                    width: 250px;
                }

                &:hover {
                    border: 1.5px dashed $secondary-color;
                }
            }

            .added-image-wrap {
                display: flex;
                position: relative;

                .default-radio {
                    position: absolute;

                    .primary {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: $secondary-color;
                    }

                    .Mui-checked {
                        svg {
                            &:first-of-type {
                                background: #c6d1fa;
                                border-radius: 50%;

                                path {
                                    display: none;
                                }
                            }
                        }
                    }

                    svg {
                        &:first-of-type {
                            stroke: white;
                            fill: white;

                            path {

                                fill: #000;
                            }
                        }
                    }
                }

                .image {
                    width: 280px;
                    height: 250px;
                    // margin-right: 20px;

                    img {
                        width: 280px;
                        height: 250px;
                        object-fit: cover;
                    }
                }

                .remove {
                    position: absolute;
                    right: 10px;
                    top: 3%;
                    cursor: pointer;
                    background: #fff;
                    width: 24px;
                    height: 24px;
                    zoom: 70%;

                    // zoom: 80%;
                    svg {
                        fill: #666766;

                        @include mediaquery(md, max) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &:hover {
                        svg {
                            fill: red;
                        }
                    }

                    @include mediaquery(md, max) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .video-wrapper {
            div {
                width: 100%;
                background: #F3F3F4;
                border-radius: 5px;
            }
        }
    }

    .upload-wrapper {
        position: relative;
    }

    .planogram-section-wrapper {
        .image-upload-wrapper {
            .upload-wrapper {
                display: flex;
                align-items: center;
                column-gap: 20px;
                margin-bottom: 20px;
                grid-template-columns: 350px 120px 100px;
                // position: relative;
                // margin-bottom: 25px;
                // position: relative;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }

                .input-type-wrap {
                    // flex: 1 1 50%;
                    // flex-basis: 60%;
                    @include mediaquery(md, max) {
                        flex-basis: 100%;
                    }
                    input {
                        width: calc(100% - 30px);
                        @include mediaquery(md, max) {
                            width: 90px;
                        }
                        &:hover {
                            border:#000 solid 1px;
                        }
                    }
                    .field-err-msg {
                        margin-top: 5px;
                    }
                }
                .input-type-wrap1 {
                    // flex: 1 1 50%;
                    flex-basis: 67%;
                    @include mediaquery(md, max) {
                        flex-basis: 100%;
                    }

                    input {
                        width: calc(100% - 30px);
                        @include mediaquery(md, max) {
                            width: auto;
                        }
                    }
                    
                }

                .skip-btn {
                    font-size: 13px;
                    width: auto;
                    @include mediaquery(md, max) {
                        width: 93px;
                    }
                }

                .image-upload-wrap1 {
                    flex-basis: 175px;
                }

                .image-upload-wrap {
                    // flex-basis: 22%;
                    // flex: 1 1 25%;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    // font-weight: 500;
                    // font-size: 16px;
                    // line-height: 26px;
                    // color: $secondary-color;
                    // cursor: pointer;
                    // position: relative;
                    .skip-btn {
                        font-size: 13px;
                    }

                    .image-error {
                        top:100%
                    }
                }

                .close-icon-wrap {
                    // flex: 1 1 25%;

                    svg {
                        height: 20px;
                        width: 20px;
                        cursor: pointer;
                    }
                    &:hover {
                        svg {
                            fill: red;
                        }
                    }

                }

                .file-upload {
                    // position: absolute;
                    // right: 0;
                    // height: 0;
                    // width: 0;
                }
            }

        }

        .add-another-contact-wrap {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: $secondary-color;
            display: flex;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;
        }

        .hide {
            display: none !important;
        }
    }
}
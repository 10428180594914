.bulk-upload-wrapper {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 6;

    .popup-wrapper {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;

        @include mediaquery(md) {
            width: 740px;
        }

        .close-icon {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;

            svg {
                cursor: pointer;
                height: 25px;
                width: 25px;
                fill : #C4C4C4;
            }
        }

        .image-uploaded-wrapper {
            display: flex;
            padding:5px
        }

        .first-heading {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            text-align: center;
            color: $primary-color;
        }

        .second-heading {
            margin-top: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #262626;
            opacity: 0.7;
        }

        .form-input-control {
            width: 70%;
            margin-top: 30px;

            .label {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 10px;
            }

            .brand-logo-section-wrap {
                .logo-wrap {
                    position: relative;

                    .logo {
                        margin-top: 20px;
                        width: 130px;
                        height: 130px;
                        background: #F3F3F4;
                        border-radius: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(8, 12, 41, 0.4);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .edit-icon {
                        position: absolute;
                        bottom: -10%;
                        left: 35%;

                        @include mediaquery(md) {
                            bottom: -10%;
                            left: 19%;
                        }
                    }
                }

                div {
                    cursor: pointer;

                    img {
                        width: 130px;
                        height: 130px;
                        object-fit: contain;
                    }
                }
            }

            .input-wrap {
                width: 100%;

                input {
                    &::placeholder {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: rgba(8, 12, 41, 0.4);
                    }
                }
            }

            .hr {
                opacity: 0.1;
                border: 1px solid #000000;
                margin-bottom: 28px;
            }

            .button-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
    }
}
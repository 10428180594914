.product-share-wrapper {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 6;


    .popup-wrapper {
        align-items: center;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 40%;
        z-index: 1;
        overflow-x: hidden;
        overflow-y: auto;
        
        @include mediaquery(md, max) {
            width: 90%;
            padding: 0;
        }

        .close-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include mediaquery(md, max) {
                padding: 30px 30px 0 30px;
            }
            .back-wrap {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #6E6E6E;
                cursor: pointer;

                svg {
                    fill: #6E6E6E;
                    margin-right: 10px;
                }

                &:hover {
                    svg {
                        fill: $secondary-color;
                    }
                    color: $secondary-color;
                }
            }

            .close-wrap {
                cursor: pointer;

                svg {
                    fill: #C4C4C4;
                }

                &:hover {
                    svg {
                        fill:red;
                    }
                }
            }
        }

        .header-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            @include mediaquery(md, max) {
                flex-direction: column;
                text-align: left;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 15px;
                padding: 0px 30px 0 30px;
            }
            .text {
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
                @include mediaquery(md, max) {
                    text-align: left;
                }
            }

            .share-icons-wrap {
                display: flex;
                gap: 15px;

                .icons-shadow {
                    background: #FFFFFF;
                    border: 1px solid #EAEAEA;
                    box-shadow: 0px 0px 32px rgb(105 129 241 / 20%);
                    border-radius: 5px;
                    padding: 8px;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        border: $secondary-color solid 1px;
                    }
                }
            }
        }

        .divider {
            border-top: 1px solid #E6E6E6;
            margin: 25px 0;
        }

        .copy-link-description {
            .text {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                margin-bottom: 15px;
            }

            .desc {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #6E6E6E;
            }
        }

        .contact-details-form-wrap {
            .text {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                
                @include mediaquery(md, max) {
                    margin-top: 20px;
                    padding: 0 15px;
                    margin-bottom: 15px;
                }
            }

            form {
                .contact-y-scroll {
                    max-height: 250px;
                    overflow-y: scroll;
                    padding-right: 24px;
                    .input-wrapper {
                        .form-control {
                            input {
                                &:hover {
                                    border: #000 solid 1px;
                                }
                            }
                            .field-err-msg {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }

            .contact-y-scroll {
                @include mediaquery(md, max) {
                    overflow-y: scroll;
                    height: 200px;
                }
            }

            .skip-btn {
                @include mediaquery(md, max) {
                    margin-left: 20px;
                    zoom: 90%;
                }
            }

            .input-wrapper {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 30px;
                position: relative;
                &:first-of-type {
                    margin-top: 10px;
                }
                @include mediaquery(md, max) {
                    background: #f1f1f1;
                    padding: 10px 20px 14px 20px;
                    flex-direction: column;
                    &:not(:first-child) {
                        padding: 25px 20px 14px 20px;
                    }
                    gap: 0;
                    &:last-of-type {
                        @include mediaquery(md, max) {
                            margin-bottom: 0 !important;
                        }
                    }
                    &:first-of-type {
                        @include mediaquery(md, max) {
                            margin-top: 0 !important;
                        }
                    }
                }
                .form-control {
                    width: 100%;

                    div {
                        width: 100%;
                    }

                    .field-err-msg {
                        @include mediaquery(md, max) {
                            position: relative;
                        }
                    }

                    input {
                        width: 90%;
                        height: 25px;
                        @include mediaquery(md, max) {
                            background: #fff;
                        }
                        &:hover {
                            border: #000 solid 1px;
                        }

                        // &:focus {
                        //     outline-color: #C9C9C9 ;
                        // }
                    }

                    .has-error {
                        border: rgb(211, 47, 47) !important;
                        outline-color: rgb(211, 47, 47) !important;
                    }
                }

                .remove-field {
                    position: absolute;
                    right: -15px;
                    top: 20px;
                    z-index: 1;
                    cursor: pointer;
                    zoom: 78%;
                    @include mediaquery(md, max) {
                        right: 5px;
                        top: 10px;
                    }
                    &:hover {
                        svg {
                            fill: red;
                        }
                    }

                    svg {
                        height: 25px;
                        width: 25px;
                    }
                }
                
                    padding-right: 15px;
                
            }

            .name-input,
            .email-input {
                div {
                    width: 100%;
                }
            }
        }

        .add-another-contact-wrap {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: $secondary-color;
            display: flex;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;
        }

        .button-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include mediaquery(md, max) {
                align-items: center;
                justify-content: center;
            }

            button {
                font-weight: 600;
                font-size: 16px;
                text-align: center;
                // text-transform: uppercase;
                color: #FFFFFF;
                height: 45px;
                width: 150px;
                padding: 10px;
                zoom: 90%;
                @include mediaquery(md, max) {
                    zoom: 70%;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    margin-right: 20px;
                    width: 100%;
                    margin-left: 20px;
                }
            }
        }
    }
}
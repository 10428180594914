$breakpoints: (
	// Generic
	// "phone":        400px,
	// "phone-wide":   480px,
	// "phablet":      560px,
	// "tablet-small": 640px,
	// "tablet":       768px,
	// "tablet-wide":  1024px,
	// "desktop":      1248px,
	// "desktop-wide": 1440px,
	// Pure css breakpoints
	"xsm": 360px,
	"ssm": 420px,
	"sm": 568px,
	"md": 768px,
	"slg": 992px,
	"lg": 1024px,
	"xl": 1280px
);

@mixin mediaquery($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	} @else {
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}
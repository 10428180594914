.product-preview-wrapper {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    .category-input-wrap, .select-brand {
        .MuiFormControl-root {
            .Mui-disabled {
                fieldset {
                    border-color: transparent;
                }
            }
        }
    }
    .form-control {
        margin-top: 28px;

        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 5px;
        }

        select {
            &:focus-visible {
                outline: none;
                border: $secondary-color solid 1px;
            }
        }

        .input-wrap {
            width: 100%;

            input {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #080C29;

                &.Mui-disabled {
                    opacity: 1;
                    background: #F3F3F4 !important;
                    color: rgba(0, 0, 0, 0.38);
                }
            }
        }

        .add-image-wrapper {
            margin-top: 25px;
            display: flex;
            gap: 20px;
            padding-bottom: 10px;

            @include mediaquery(md, max) {
                overflow-x: scroll;
                overflow-y: hidden;
            }

            .add-image-wrap {
                background: #F3F3F4;
                border: 1.5px dashed #979797;
                border-radius: 5px;
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #AAAAAA;
                cursor: pointer;
                flex-direction: column;

                @include mediaquery(md, max) {
                    white-space: nowrap;
                }

                .img-info {
                    width: 250px;
                }

                &:hover {
                    border: 1.5px dashed $secondary-color;
                }
            }

            .added-image-wrap {
                display: flex;
                position: relative;
                border: #f1f1f1 solid 1px;
                .default-radio {
                    position: absolute;
                    z-index: 1;
                    .primary {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: $secondary-color;
                    }

                    .Mui-checked {
                        svg {
                            &:first-of-type {
                                background: #c6d1fa;
                                border-radius: 50%;

                                path {
                                    display: none;
                                }
                            }
                        }
                    }

                    svg {
                        &:first-of-type {
                            stroke: white;
                            fill: white;

                            path {

                                fill: #000;
                            }
                        }
                    }
                }

                .image {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    height: 250px;
                    // margin-right: 20px;

                    img {
                        width: 250px;
                        height: auto;
                        // object-fit: contain;
                        // transform: scale(.9);
                        @include mediaquery(md, max) {
                            transform: unset;
                        }
                    }
                }

                .remove {
                    position: absolute;
                    right: 8px;
                    top: 3%;
                    cursor: pointer;
                    background: #fff;
                    width: 24pxpx;
                    height: 24px;
                    zoom: 70%;

                    // zoom: 80%;
                    svg {
                        fill: #666766;

                        @include mediaquery(md, max) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &:hover {
                        svg {
                            fill: red;
                        }
                    }

                    @include mediaquery(md, max) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .information-lables {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;

            .count-info {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }

            .link-info {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                // text-decoration-line: underline;
                cursor: pointer;
                color: $secondary-color;
            }
        }

        .chips-list {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;

            .chips {
                // width: 100px;
                // height: 29px;
                background: lighten($secondary-color, 30%) !important;
                border-radius: 17.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 17px;
                color: $secondary-color;
                // opacity: 0.65;
                margin-right: 10px;
                padding: 5px 7px 5px 11px;
                border: $secondary-color dashed 1px;
                gap: 5px;

                div {
                    word-break: break-all;
                }

                .remove {
                    display: flex;
                    margin-left: 5px;
                    zoom: 65%;

                    svg {
                        height: 17px;
                        width: 17px;
                        cursor: pointer;
                        background: $secondary-color;
                        border-radius: 50%;
                        padding: 3px;
                        stroke: #fff;
                        fill: #fff;
                        border: $secondary-color solid 1px;
                    }

                    &:hover {
                        svg {
                            background: #fff;
                            fill: red;
                            stroke: red;
                            border: red solid 1px;
                        }
                    }
                }
            }
        }

        &.packsize-wrapper {
            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
            }

            .packsize-wrap {
                display: grid;
                grid-template-columns: 15% 28.3% 28.3% 28.3%;
                margin-top: 8px;

                @include mediaquery(md, max) {
                    grid-template-columns: 100%;
                }

                .packsize-wrap-items {
                    margin-top: 8px;
                }

                div {
                    .css-4mzek5-MuiFormControl-root-MuiTextField-root {
                        // margin-top: 0;
                        // @include mediaquery(md, max) {
                        //     margin-top: 8px;
                        // }
                    }
                }

                >div {
                    // @include mediaquery(md, max) {
                    //     margin-bottom: 15px;
                    // }
                    
                }

                .measlist-dropdown {
                    display: grid;
                    grid-template-columns: 35% 65%;
                    @include mediaquery(md, max) {
                        grid-template-columns: 30% 70%;
                    }
                    #measlist-select {
                        margin-top: 8px;
                        border-radius: 4px 0px 0px 4px;
                        height: 47px;
                        padding: 2px 15px;
                        font-size: 14px;
                    }

                    .measlist-input {
                        border-radius: 0;

                        input {
                            border-radius: 0 5px 5px 0;
                        }
                    }
                }

                .inner-pack-wrap, .outer-pack-wrap, .weight-volume-wrap {
                    flex: 1 1 33%;
                }
            }
        }

        &.barcode-itemcode-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
                gap: 25px;
            }

            .barcode-wrap {
                display: grid;
                grid-template-columns: 30% 70%;

                #barcode-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .barcode-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }

        &.price-msrp-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
                gap: 25px;
            }

            .price-wrap {
                display: grid;
                grid-template-columns: 30% 35% 35%;

                #price-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .price-input,
                .item-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }

            .MSRP-wrap {
                display: grid;
                grid-template-columns: 30% 70%;

                #MSRP-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .MSRP-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }

        &.product-dimension-wrapper {
            .dimension-wrap {
                display: grid;
                grid-template-columns: 15% 28.3% 28.3% 28.3%;

                @include mediaquery(md, max) {
                    grid-template-columns: 100%;
                }

                .pro-dimension {
                    @include mediaquery(md, max) {
                        margin-bottom: 10px;
                    }
                }

                .width-input,
                .depth-input,
                .height-input {
                    margin: 0;
                }
            }
        }

        .product-volume-weight-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                display: block;
            }

            .product-volume-wrap,
            .product-weight-wrap {
                margin: 0;

                @include mediaquery(md, max) {
                    margin: 35px 0 0 0;
                }

                .volumn-wrap {
                    display: grid;
                    grid-template-columns: 30% 70%;

                    .input-wrap {
                        margin: 0;
                    }

                }
            }
        }

        .product-container-loadability-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                display: block;
            }
        }

        &.country-wrapper {
            @include mediaquery(md, max) {
                margin-bottom: 0 !important;
            }
            div {
                margin: 0;
                width: 100%;
                background: #F3F3F4;
                border-radius: 6px;
                font-size: 14px;
            }
            


            .MuiInputBase-formControl {
                fieldset {
                    // border-right-width: 1px;
                    // border-top-right-radius: 5px;
                    // border-bottom-right-radius: 5px;

                    legend {
                        display: none !important;
                    }
                }
            }

            // select {
            //     background: #F3F3F4;
            //     border-radius: 6px;
            //     font-size: 14px;
            // }
        }

        .autocomplete-wrapper {
            position: relative;
            width: 100%;

            .separator {
                border-top: 1px solid #5f6368;
                width: 95%;
                text-align: center;
                display: block;
                margin: auto;
            }

            .autocomplete {
                background: #F4F5F7;
                border: none;
                width: 100%;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                min-height: 150px;
                max-height: 200px;
                overflow: auto;
                position: absolute;
                display: block;
                margin: auto;
                z-index: 1;

                div {
                    margin: 10px 0;
                    padding: 5px 12px;

                    &:hover {
                        background: #dedede;
                        color: #333;
                        cursor: pointer;
                    }
                }

                .add-category-wrap {
                    display: flex;
                    align-items: center;
                    color: rgba(67, 97, 238);

                    svg {
                        margin-right: 10px;
                        fill: rgba(67, 97, 238);
                    }

                    &:hover {
                        color: rgba(67, 97, 238);
                    }
                }
            }
        }
    }
}
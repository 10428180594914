.catalogues-wrapper {
    margin: 0 50px;

    @include mediaquery(md, max) {
        margin: 30px 0;
    }

    .divider {
        margin-top: 20px;
        opacity: 0.1;
        border: 1px solid #000000;
    }

    .brands-wrapper {
        margin: 30px 0;
        display: flex;
        position: relative;

        .added-brand-wrapper {
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            position: relative;

            @include mediaquery(md) {
                overflow-x: hidden;
                overflow-y: hidden;
                position: initial;
                scroll-behavior: smooth;
            }

            .brand-list-slider-container {

                display: flex;
                overflow-x: hidden;
                scroll-behavior: smooth;
                padding: 3px;

                @include mediaquery(md, max) {
                    overflow-x: auto;
                }

                div {
                    width: 100px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    margin-right: 20px;
                    cursor: pointer;
                    overflow: hidden;
                    padding: 5px;
                    /* Add the blur effect */
                    // filter: blur(2px);
                    // -webkit-filter: blur(2px);

                    &:hover {
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s;
                        border: 1px solid $secondary-color;
                    }

                    &:before {
                        content: '';
                        // background: #fff;
                        // width: 100%;
                        // height: 100%;
                        // opacity: 0.6;
                    }

                    &.selected-brand {
                        border: 1px solid $secondary-color;
                        border-radius: 10px;
                        /* Add the blur effect */
                        // filter: blur(0px);
                        // -webkit-filter: blur(0px);

                        // &:focus {
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s // }
                    }

                    img {
                        height: 90px;
                        width: 100%;
                        object-fit: contain;
                        zoom: 70%;
                        -moz-transform: scale(.7);
                    }
                }
            }

            .prev-btn,
            .next-btn {
                position: absolute;
                left: -10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                background-color: $secondary-color;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: $secondary-color solid 1px;

                svg {
                    fill: #fff;
                    height: 20px;
                    width: 20px;
                    zoom: 70%;
                }

                &:hover {
                    background-color: #fff;

                    svg {
                        fill: $secondary-color;
                    }
                }
            }

            .next-btn {
                left: auto;
                right: -14px;
            }

            .prev-btn {
                svg {
                    position: relative;
                    left: 3px;
                }
            }
        }

        .line {
            border-left: 1px solid #C7D5E1;
            height: 70px;
            margin: 0 20px 0 0;
        }

        .w-100-slider {
            display: flex;
            width: 100%;

            @include mediaquery(md, max) {
                width: 72%;
                flex-basis: 100%;
            }
        }
    }

    .brand-manage-section {
        margin: 50px 0;

        @include mediaquery(md, max) {
            border-bottom: #e6e6e6 solid 1px;
            padding-bottom: 21px;
        }

        .option-wrapper {
            display: flex;
            align-items: center;

            .option {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                cursor: default;

                @include mediaquery(md) {
                    font-size: 21px;
                    line-height: 25px;
                    margin-right: 35px;
                }

                @include hover-underline-animation(#000);

                &.selected {
                    &::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &::after {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 4px;
                    bottom: -21px;
                }
            }
        }
    }

    .catalogue-filter-section {
        margin: 30px 0;
        margin-bottom: 265px;

        @include mediaquery(md, max) {
            margin-bottom: 30px;
        }

        .filter-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;

            @include mediaquery(md, max) {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
            }

            .search-bar {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                .search-input {
                    position: relative;
                    border-radius: 5px;
                    width: 200px;

                    @include mediaquery(md) {
                        width: 285px;
                        margin: 0;
                    }

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    ::placeholder {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                        color: #262626;
                        mix-blend-mode: normal;
                        opacity: 0.7;
                    }

                    >div {
                        padding-left: 0px;
                    }

                    input {
                        padding: 6px 25px 6px 14px !important;
                        width: 85%;
                        font-family: $font-inter;
                    }
                }

                .close-icon {
                    position: absolute;
                    top: 58%;
                    transform: translate(-50%, -50%);
                    right: 60px;
                    cursor: pointer;
                    zoom: 80%;
                }

                .search-icon {
                    position: absolute;
                    top: 58%;
                    transform: translate(-50%, -50%);
                    left: 20px;
                    cursor: pointer;
                }

                .search-icon-wrap {
                    background: #000000;
                    border-radius: 5px;
                    height: 36px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0 0 10px;
                    cursor: pointer;
                    border: #000 solid 1px;

                    svg {
                        path {
                            stroke: #fff;
                        }
                    }

                    &:hover {
                        background: #fff;
                        border: #000 solid 1px;

                        svg {
                            path {
                                stroke: #000;
                            }
                        }
                    }

                    &:focus {
                        box-shadow: 0px 0px 0 3px rgb(211, 210, 210) !important;
                        transition: opacity .25s, box-shadow .25s
                    }
                }
            }

            .filters {
                display: flex;

                .menu {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #262626;
                    opacity: 1;
                    margin-right: 10px;

                    svg {
                        fill: #686868;
                        transform: rotate(90deg);
                        margin-left: 5px;
                    }
                }

                .more-filter-option-icon-wrapper {
                    margin: 0 10px;
                    position: relative;

                    .more-option-icon {
                        cursor: pointer;
                    }

                    svg {
                        height: 25px;
                        width: 25px;

                        @include mediaquery(md) {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
        }

        .catalogue-table {

            table {
                @include mediaquery(md, max) {
                    width: 400% !important;
                }
            }

            table {

                thead {

                    th {

                        &.catalogue {
                            width: 30%;
                            padding-left: 40px;
                        }

                        &.contacts-id,
                        .products,
                        .created-date,
                        .last-date,
                        .more-option {
                            width: 15%;
                        }

                        &.more-option {
                            color: transparent;
                        }
                    }
                }

                tbody {

                    .catalogue-name {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: #262626;
                        margin-left: 15px;
                        gap: 15px;
                        display: flex;
                        align-items: center;
                        // width: calc(100% - 63px);
                    }

                    .cust-button-brand {
                        cursor: pointer !important;
                        text-align: center;
                        text-transform: uppercase;
                        color: $secondary-color;
                        background: rgba(67, 97, 238, 0.3);
                        border: 1px solid $secondary-color;
                        border-radius: 5px;
                    }

                    .contacts {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #262626;
                    }

                    .product-count {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #262626;
                    }



                    .status {
                        border: 1px solid #EA4E2C;
                        border-radius: 2px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FFD8CD;
                        padding: 5px 25px;
                    }

                    .draft {
                        cursor: pointer;
                    }

                }
            }

            .no-catalogue-found {
                background-color: #F1F1F1;
                min-height: 460px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .icon-wrap {
                    width: 200px;
                    height: 200px;
                    background: #FFFFFF;
                    border: 2px dashed #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 100px;
                        width: 100px;
                    }
                }

                .text {
                    margin: 30px 0;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #262626;

                    @include mediaquery(md, max) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    button {
        text-transform: capitalize;
        font-family: $font-inter;
    }

    .add-catalogue-popup-wrapper {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 1;

        .add-catalogue-popup {
            align-items: center;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 50%;
            overflow: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 73%;
            z-index: 1;

            @include mediaquery(md) {
                width: 513px;
            }
        }

        .first-heading {
            font-weight: 600;
            font-size: 28px;
            line-height: 29px;
            color: #262626;

            @include mediaquery(md, max) {
                font-size: 20px;
            }
        }

        .second-heading {
            margin: 5px 0 20px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #696969;
            text-align: center;
        }

        form {
            position: relative;
            width: 100%;

            div {
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;

                @include mediaquery(md, max) {
                    width: 100%;
                }

            }

        }

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                cursor: pointer;
                height: 25px;
                width: 25px;
                fill: #C4C4C4;
            }

            &:hover {
                svg {
                    fill: red;
                }
            }
        }

        .add-new-catalogue-button {
            width: 73%;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            text-transform: capitalize;

            @include mediaquery(md, max) {
                width: 100%;
            }
        }

        .field-err-msg {
            margin-bottom: 10px;
        }
    }

    .edit-catalogue-wrapper {

        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: 100vh;
            left: 0;
            position: fixed;
            right: 0;
            transition: .5s;
            z-index: 6;
        }

        .edit-catalogue-slide {
            background-color: #fff;
            height: calc(100%);
            overflow-x: hidden;
            position: fixed;
            right: 0;
            top: 0;
            transition: .5s;
            width: 0;
            z-index: 1;
            transition: 0.5s;

            &.show {
                width: 50%;

                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            &.hide {
                width: 0%;

                .wrap {
                    .bottom-wrap {
                        width: 0%;
                        overflow-x: hidden;
                        transition: 0.5s;
                        padding: 0;
                    }
                }
            }

            .wrap {
                padding: 30px 20px 0;
                position: relative;
                height: calc(100% - 60px);

                .head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 29px;
                        color: #262626;
                    }

                    .close-icon {
                        cursor: pointer;

                        svg {
                            height: 30px;
                            width: 30px;
                            fill: #000;
                        }

                        &:hover {
                            svg {
                                fill: red;
                            }
                        }
                    }
                }

                .catalogue-input {
                    background: #F3F3F4;
                    border-radius: 5px;
                    width: 100%;
                    margin: 20px 0 0 0;

                    &::placeholder {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #9597A3;
                    }
                }

                .field-err-msg {
                    margin-top: 5px;
                }

                .divider {
                    border: 1px solid #000000;
                }

                .edit-catalogue-detail-wrap {
                    margin-top: 20px;

                    .title {
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 19px;
                        color: #000000;
                        margin-bottom: 10px;
                    }

                    .search-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .position-relative {
                            @include mediaquery(md, max) {
                                width: 100%;
                            }
                        }

                        @include mediaquery(md, max) {
                            gap: 10px;
                            text-align: center;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .search-input {
                            background: #F3F3F4;
                            border-radius: 5px;
                            width: 300px;

                            @include mediaquery(md, max) {
                                width: 100%;
                            }

                            &::placeholder {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #9597A3;
                            }

                            input {
                                padding-right: 30px !important;
                                font-size: 14px;
                            }
                        }

                        .selected-product-text {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            color: $secondary-color;
                        }

                        .close-icon {
                            position: absolute;
                            right: 1%;
                            top: 30%;

                            svg {
                                fill: #C4C4C4;
                                cursor: pointer;
                            }

                            &:hover {
                                svg {
                                    fill: red;
                                }
                            }
                        }
                    }
                }

                .filter-wrap {
                    display: flex;
                    // grid-template-columns: 10% 41% 41%;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;
                    margin-top: 20px;

                    @include mediaquery(md, max) {
                        flex-direction: column;
                        gap: 20px;

                        div {
                            width: 100%;
                        }
                    }
                    .css-2m9kme-MuiFormControl-root {
                        width: 100%;
                        margin: 0;
                        @include mediaquery(md, max) {
                            width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                    .text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #000000;
                        width: 70px;
                        @include mediaquery(md, max) {
                            width: 100%;
                        }
                    }
                    .filters {
                        width: calc(100% - 70px);
                        display: flex;
                        gap: 15px;
                        @include mediaquery(md, max) {
                            width: 100%;
                        }

                        .filter-cat, .filter-brand {
                            width: 100%;
                            height: 43px;
                            margin-bottom: 10px;
                            @include mediaquery(md, max) {
                                width: 100%;
                            }
    
                            >div {
                                width: 100%;
                                margin: 0 !important;
                                div {
                                    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                                        height: 10px !important;
                                        min-height: unset !important;
                                        position: relative;
                                        top: -7px;
                                    }
                                }
    
                                label {
                                    top: -7px !important;
                                    
                                }
                                .MuiInputBase-colorPrimary {
                                        height: 45px;
                                }
                            }
                            
                        }
                    }
                }

                .catalogue-edit-product-list {
                    display: block;
                    position: relative;
                    min-height: 460px;
                    margin-top: 20px;


                    table {
                        border-spacing: 0;
                        width: 100%;
                        border: 1px solid #DDDDDD;

                        thead {
                            border-radius: 5px;

                            tr {
                                background: #080C29;

                                th {
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: #FFFFFF;
                                    text-align: left;
                                    padding: 20px 0;

                                    input[type=checkbox] {
                                        margin: 0 15px;
                                        height: 15px;
                                        width: 15px;
                                        // outline: 1px solid #FFFFFF;
                                        border-radius: 3px;
                                    }



                                    &.product-name {
                                        width: 60%;
                                    }

                                    &.item-code {
                                        width: 20%;
                                    }

                                    &.variants {
                                        width: 20%;
                                        text-indent: 20px;
                                    }
                                }
                            }
                        }

                        tbody {
                            background-color: #FFFFFF;

                            tr {
                                background-color: #FFFFFF;


                                td {
                                    background: #FFFFFF;
                                    // border-top: 1px solid #DDDDDD;
                                    // border-bottom: 1px solid #DDDDDD;
                                    padding: 18px 0 17px 0;

                                    &.brand-category {
                                        width: 100%;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 15px;
                                        color: #000000;

                                        input[type=checkbox] {
                                            margin: 0 15px;
                                            height: 15px;
                                            width: 15px;
                                            // outline: 1px solid #FFFFFF;
                                            border-radius: 3px;
                                        }
                                    }


                                    &.product-name {
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: #262626;

                                        input[type=checkbox] {
                                            margin: 0 15px;
                                            height: 15px;
                                            width: 15px;
                                            // outline: 1px solid #FFFFFF;
                                            border-radius: 3px;
                                        }

                                        img {
                                            height: 25px;
                                            width: 25px;
                                            object-fit: contain;
                                            margin-right: 10px;
                                        }
                                    }

                                    &.item-code {
                                        width: 20%;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #727272;
                                        word-break: break-all;

                                    }

                                    &.variants {
                                        width: 20%;
                                        font-size: 14px;
                                        line-height: 15px;
                                        color: #727272;
                                        text-indent: 20px;
                                    }
                                }
                            }
                        }
                    }
                }

                .catalogue-edit-product-details {
                    display: block;
                    position: relative;
                    min-height: 460px;
                    margin-top: 20px;


                    table {
                        border-spacing: 0;
                        width: 100%;
                        border: 1px solid #DDDDDD;

                        thead {
                            border-radius: 5px;

                            tr {
                                background: #080C29;

                                th {
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: #FFFFFF;
                                    text-align: left;
                                    padding: 20px 0;

                                    input[type=checkbox] {
                                        margin: 0 15px;
                                        height: 15px;
                                        width: 15px;
                                        // outline: 1px solid #FFFFFF;
                                        border-radius: 3px;
                                    }



                                    &.product-name {
                                        width: 40%;
                                    }

                                    &.item-code {
                                        width: 30%;
                                    }

                                    &.variants {
                                        width: 30%;
                                        text-indent: 20px;
                                    }
                                }
                            }
                        }

                        tbody {
                            background-color: #FFFFFF;

                            tr {
                                background-color: #FFFFFF;


                                td {
                                    background: #FFFFFF;
                                    // border-top: 1px solid #DDDDDD;
                                    // border-bottom: 1px solid #DDDDDD;
                                    padding: 18px 0 17px 0;

                                    &.brand-category {
                                        width: 100%;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 15px;
                                        color: #000000;

                                        input[type=checkbox] {
                                            margin: 0 15px;
                                            height: 15px;
                                            width: 15px;
                                            // outline: 1px solid #FFFFFF;
                                            border-radius: 3px;
                                        }
                                    }


                                    &.product-name {
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: #262626;

                                        input[type=checkbox] {
                                            margin: 0 15px;
                                            height: 15px;
                                            width: 15px;
                                            // outline: 1px solid #FFFFFF;
                                            border-radius: 3px;
                                        }

                                        img {
                                            height: 25px;
                                            width: 25px;
                                            object-fit: contain;
                                            margin-right: 10px;
                                        }

                                        .custom-tt-2 {
                                            cursor: default;
                                        }
                                    }

                                    &.item-code {
                                        width: 30%;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #727272;
                                        word-break: break-all;

                                    }

                                    &.variants {
                                        width: 30%;
                                        font-size: 14px;
                                        line-height: 15px;
                                        color: #727272;
                                        text-indent: 20px;
                                        vertical-align: baseline;
                                    }
                                }
                            }
                        }
                    }
                }

                .load-more-btn {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 30px 0;

                    .loader-component-wrap {
                        background: #EAF5FF;
                        border-radius: 9px;
                        height: 35px;
                        width: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                    }

                    .loader-center {
                        position: relative;
                        background: transparent;

                        .three-bounce-spinner {
                            margin: 0;
                        }
                    }
                }

                .bottom-wrap {

                    // position: absolute;
                    // width: 96%;
                    // right: 2%;
                    // bottom: 0;

                    position: fixed;
                    bottom: 0;
                    background: #fff;
                    width: calc(50% - 45px);
                    right: 0;
                    transition: 0.5s;
                    padding-right: 25px;
                    padding-left: 20px;

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    .divider {
                        margin-top: 0;
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin: 20px 0;

                        .text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #000000;
                            margin-right: 10px;
                        }

                        .create-btn {

                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .catalogue-preview-wrapper {

        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: 100vh;
            left: 0;
            position: fixed;
            right: 0;
            transition: .5s;
            z-index: 6;
        }

        .catalogue-preview-slide {
            background-color: #fff;
            height: calc(100%);
            overflow-x: hidden;
            position: fixed;
            right: 0;
            top: 0;
            transition: .5s;
            z-index: 1;

            &.show {
                width: 100%;

                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            &.hide {
                width: 0%;
            }

            .wrap {
                height: calc(100% - 60px);
                // padding: 30px 20px 0;
                position: relative;

                .details {
                    @include mediaquery(md, max) {
                        padding: 0 60px;
                    }
                }

                .exit-preview-wrapper {
                    margin-bottom: 30px;
                    @include mediaquery(md, max) {
                        // flex-direction: row-reverse;
                        // padding-right: 20px;
                    }
                }

                .footer-catalogue-exit {
                    .exit-preview-wrapper {
                        // margin-bottom: 30px;
                        @include mediaquery(md, max) {
                            flex-direction: row;
                            padding-right: 0;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .catalogue-sorting-popup-wrapper, .catalogue-product-sorting-popup-wrapper {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;
    
        .popup-wrap {
            align-items: center;
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: start;
            left: 50%;
            overflow: auto;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 70%;
            min-height: 60%;
            height: 80%;
            z-index: 1;
            position: relative;
    
            @include mediaquery(md, max) {
                width: 75%;
            }

            &.when-no-content {
                @include mediaquery(md, max) {
                    height: 40%;
                    min-height: 40%;
                }
            }

            .no-data-found {
                display: flex;
                height: 100vh;
                justify-content: center;
                align-items: center;
            }
    
            .heading-wrap{
                display: flex;
                justify-content: space-between;
                width: 100%;

                .heading {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22px;
                    color: #000;
                }
                .close-icon {
                    // position: absolute;
                    // top: 5%;
                    // right: 5%;
                    cursor: pointer;
                }
                .sort-product-controls-wrap {
                    position: relative;
                    width: 100%;
                    .sort-product-controls {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        .MuiFormControl-root {
                            width: 100%;
                            background-color: #fff;
                        }
                    }
                }
            }

            .sort-product-controls-wrap {
                position: relative;
                width: 100%;
                .sort-product-controls {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    @include mediaquery(md, max) {
                        // left: -8px;
                    }
                    .MuiFormControl-root {
                        width: 100%;
                        background-color: #fff;
                    }
                }
            }

            .brand-list-wrap {
                &.scroll-bar-theme {
                    // padding-right: 20px;
                }
            }

            .brand-list-wrap, .product-list-wrap {
                overflow-y: auto;
                width: 100%;
                // margin: 30px 0;

                .brand-list-wrapper {
                    margin-bottom: 15px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    cursor: move;
                    .wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 20px;
                        border: 1px solid rgba(0,0,0,0.1);
                        background-color: #fff;

                        .brandName {
                            padding-right: 20px;
                            width: 250px;
                            text-align: left;
                            @include mediaquery(md, max) {
                                padding-right: 10px;
                                width: 100px;
                                text-align: left;
                            }
                        }
                        .brandskuid {
                            padding-right: 20px;
                            width: 75px;
                            text-align: left;
                            @include mediaquery(md, max) {
                                padding-right: 10px;
                                width: 50px;
                                text-align: left;
                            }
                        }

                        >div {
                            word-break: break-word;
                        }

                        &:hover {
                            background-color: #f1f1f1;
                        }

                        svg {
                            cursor: move;
                        }
                    }
                }
            }

            .footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                margin-top: 30px;
                button {
                    margin-left: 15px;
                }
            }
        }
    }

    .catalogue-product-sorting-popup-wrapper {
        .popup-wrap {
            .product-list-wrap {
                width: calc(100%);
                height: 100%;
                overflow-x: hidden;
                padding-right: 10px;
                margin-top: 80px;
                margin-left: 10px;
                @include mediaquery(md, max) {
                    // padding-right: 0;
                    width: 100%;
                }

                .no-product-found {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22px;
                    color: #000;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}

.d-flex {
    display: flex;
}
.conformation-popup-wrapper {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 6;

    .popup-wrap {
        align-items: center;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 400px;
        z-index: 1;
        position: relative;

        @include mediaquery(md, max) {
            width: 75%;
        }

        .close-icon {
            position: absolute;
            top: 5%;
            right: 5%;
            cursor: pointer;
        }
        .heading {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;

            @include mediaquery(md, max) {
                font-size: 16px;
            }
        }

        .description {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            line-height: 24px;
            margin-top: 16px;
            color: #393e4d;

            @include mediaquery(md, max) {
                font-size: 14px;
            }
        }

        .button-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;

            .cancel-btn {
                // border: 1px solid #7c808e;
                // height: 36px;
                // // width: 92px;
                // background: #fff;
                // border-radius: 8px;
                // margin-right: 12px;
                // font-size: 16px;
                // color: #7c808e;
                // font-weight: 600;
                cursor: pointer;

                border: 1px solid #97A8B8;
                border-radius: 5px;
                background: #fff;
                text-align: center;
                color: #97A8B8;
                margin-right: 10px;
                box-shadow: none;
                padding: 12px 55px 12px 54px;
                font-size: 14px;
                &:hover {
                    background-color: #97A8B8;
                    box-shadow: none;
                    color: #fff;
                }

                @include mediaquery(md) {
                margin-right: 20px;
                }
            }

            .submit-btn {
                // height: 36px;
                // width: 92px;
                background: #249aff;
                border-radius: 8px;
                border: none;
                font-size: 16px;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
                @include mediaquery(md, max) {
                    font-size: 14px;
                }

                background-color: $primary-btn-color;
                padding: 12px 55px 12px 54px;
                text-transform: capitalize;
                font-family: $font-inter;
                color: #fff;
                border-radius: 5px;
                &:hover {
                    background: lighten($primary-btn-color, 10%);
                }   
                &:focus {
                    box-shadow:0px 0px 0 3px rgb(216 223 255) !important;
                    transition:opacity .25s,box-shadow .25s
                }
            }
        }
    }

}
// style for adjusting containers for mobile

// Login starts
.login-container {
    height: calc(100vh - 16px);
	.container-wrapper {
		@include mediaquery(md, max) {
			width: 90%;
			padding: 0 25px;
		}
	}
}
// login end


// Registration Start
.register-container {
    padding-top: 0;
    header {
        width: 70%;
        @include mediaquery(md, max) {
            width: 100%;
        }
    }
    .container-wrapper {
        display: flex;
        padding-right: 0;
        margin-top: 0 !important;
        @include mediaquery(md, max) {
            // padding-right: 16px;
            // display: block;
        }
        .register-wrapper {
            flex-basis: 70%;
            @include mediaquery(md, max) {
                flex-basis: 100%;
            }
            .register-inner_container {
                padding-top: 110px;
                // width: 58%;
                width: 540px;
                margin: 0 auto;
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                // height: calc(100vh - 142px);
                @include mediaquery(md, max) {
                    width: 100%;
                    height: auto;
                    padding-top: 0;
                }

                .alert-message-component-wrap {
                    left: 34%;
                    top: 9%;
                    @include mediaquery(md, max) {
                        top: 8%;
                        left: 50%;
                    }
                }
            }
        }
        .registration__right-panel {
            // position: relative;
            flex-basis: 30%;
            @include mediaquery(md, max) {
                display: none;
            }
            .registration_right-panel_container {
                // background-color: #D9D9D9;
                background-color: $secondary-color;
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
    }
}
// Registration End


// Email Verification Start
.email-verification-container {
    padding-top: 0;
    header {
        display: none;
    }
    .container-wrapper {
        margin-top: 0;
        padding-right: 0;
        @include mediaquery(md, max) {
            padding-right: 16px;
        }
        .verification-email-wrapper {
            padding-bottom: 0;
        }
        .verify-mail-wrapper {
            .mail-deatils {
                margin: 0 auto;
                width: 392px;
                @include mediaquery(md, max) {
                    width: 100%;
                }
            }
        }
        .verify-mail-box-wrapper {
            height: 100vh;
        }
    }
}
// Email Verification End


.password-reset-container {
    height: calc(100vh - 16px);
    .container-wrapper {
        @include mediaquery(md, max) {
            width: 90%;
            padding: 0 25px;
        }
    }
}
.main-wrapper {

    // Heading
    .first-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        text-align: center;
        color: $primary-color;
        padding: 60px 0 0px 0;
        font-family: inherit;
        @include mediaquery(md, max) {
            font-size: 20px;
            padding-top: 40px;
            line-height: 32px;
        }
    }

    // sub Heading
    .second-heading {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $primary-color;
        margin-bottom: 30px;
        font-family: inherit;
        padding: 0 30px;
        @include mediaquery(md, max) {
            font-size: 14px;
            margin-bottom: 35px;
            padding: 0 10px;
        }

        .link {
            font-weight: 600;
            text-decoration-line: underline;
            color: $secondary-color;
            cursor: pointer;
            display: block;
          }
    }

    // form input y spacing + error message
    .form-control {
        position: relative;
        // .field-err-msg {
        //     position: absolute;
        //     font-size: 12px;
        //     color: red;
        //     margin-top: 0;
        // }
    
        // input {
        //     border:#f3f3f4 solid 1px;
        //     font-family: $font-inter;
        // }
    }

    .email-control {
        img {
            position: absolute;
            top: 53%;
            right: 6px;
            transform: translate(-50%, -50%);
        }
    }

    &.for-auth-container {
        .container-wrapper {
            max-width: 490px !important;
            margin-top: unset !important;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0;
            @include mediaquery(md, max) {
                // display: unset;
                width: 100%;
                height: unset;
                margin-top: 50px !important;
            }
            .bg-modal-window {
                background-color: #fff;
                border-radius: 5px;
                @include mediaquery(md, max) {
                    padding: 0 25px;
                }
            }
            form {
                width: 84%;
                margin: 0 auto;
                @include mediaquery(md, max) {
                    width: 95%;
                }
            }
        }
    }

    &.onboarding-container, &.buyer-onboarding-container {
        padding-bottom: 100px;
        // height: calc(100vh - 116px);
        .container-wrapper {
            max-width: 778px !important;
            padding:0;
            @include mediaquery(md, max) {
                margin-top: 50px !important;
                padding: 0 15px !important;
                width: 87%;
            }
            .onboarding-component-wrap {
                padding: 15px 0;
                .tabs-container {
                    padding: 0;
                    width: 518px;
                    margin: auto;
                    @include mediaquery(md, max) {
                        width: 100%;
                    }
                    .first-heading {
                        padding-top: 35px;
                        margin-bottom: 0;
                    }
                    .second-heading {
                        margin-bottom: 15px;
                    }
                }
            }
        }   
    }

    .login-password__hide-show {
        font-family: $font-inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }

    .Mui-disabled {
        background-color: rgba(67, 97, 238, 0.2) !important;
    }

    .password-validation {
        p {
            &:first-of-type {
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-top: 0;
            }
        }
    }
    .mb-0 {
        margin-bottom: 0;
    }

    // select option with icon
    .selection-opt-with-calender {
        position: relative;
        // position: relative;
            background:#F3F3F4 url(../assets/icons/icon-calender.svg) no-repeat 20px 16px !important;
            padding-left: 45px;
            font-family: $font-inter;

            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        
    }

    .selection-opt-with-calender-d-arrow {
        position: relative;
        &::after {
            content: "";
            background: url(../assets/icons/icon-b-dropdown-arrow.svg) no-repeat;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            right: 0;
            top: 54px;
        }
    }

}


// Responsive scrollable table
.supplier-main-wrapper, .buyer-main-wrapper {

    .table-scroll-wrap {
        @include mediaquery(md, max) {
            overflow-x: scroll;
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0,0,0,.5);
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }
        }
        
       
        table {
            @include mediaquery(md, max) {
                border-collapse: collapse;
                border-spacing: 0 !important;
                width: 400% !important;
            }
        }
    }

    .div-scroll-wrap {
        overflow-x: auto;
        &.flex-basis-80P {
            @include mediaquery(md, max) {
                flex-basis: 80%;
            }
        }
        .scroll-items {
            border-collapse: collapse;
            border-spacing: 0 !important;
            width: 190% !important;
            @include mediaquery(md, max) {
                width: 520% !important;
            }
        }
    }

    .Mui-disabled {
        background-color: rgba(67, 97, 238, 0.2) !important;
    }

    .for-two-field {
        &:hover {
            div {

                &:nth-child(2) { 
                    .input-wrap { 
                        .MuiInputBase-colorPrimary {
                            .MuiOutlinedInput-notchedOutline {
                                border-right: 0;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }

                &:nth-child(3) { 
                    .input-wrap { 
                        .MuiInputBase-colorPrimary {
                            .MuiOutlinedInput-notchedOutline {
                                // border-right: 0;
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }

               
               
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        .MuiOutlinedInput-notchedOutline {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border: rgba(0, 0, 0, 0.87) solid 1px;
                        }
                    }
                }
            }
        }
        div {
            &:nth-child(2) {
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        input {
                            border-radius: 0 !important;
                            border-right: none;
                        }
                    }
                }
            }
            &:nth-child(3) {
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        &:hover {
                            .MuiOutlinedInput-notchedOutline {
                                // border-top-right-radius: 5px;
                                // border-bottom-right-radius: 5px;
                            }
                        }
                    }
                }
            }
             .input-wrap {
                 .MuiInputBase-colorPrimary {
                     &:hover {
                         .MuiOutlinedInput-notchedOutline {
                            //  border-radius: 0;
                         }
                     }
                 }
             }
         }
    }

    .for-three-field {
        &:hover {
            div {

                &:nth-child(2) { 
                    .input-wrap { 
                        .MuiInputBase-colorPrimary {
                            .MuiOutlinedInput-notchedOutline {
                                @include mediaquery(md) {
                                // border-right: 0;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                }
                            }
                        }
                    }
                }

                &:nth-child(2), &:nth-child(3) { 
                    .input-wrap { 
                        .MuiInputBase-colorPrimary {
                            .MuiOutlinedInput-notchedOutline {
                                @include mediaquery(md) {
                                // border-right: 0;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                                }
                            }
                        }
                    }
                }
                &:nth-child(3), &:nth-child(4) { 
                    .input-wrap { 
                        .MuiInputBase-colorPrimary {
                            .MuiOutlinedInput-notchedOutline {
                                @include mediaquery(md) {
                                // border-left: 0;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                }
                            }
                        }
                    }
                }
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        .MuiOutlinedInput-notchedOutline {
                            @include mediaquery(md) {
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            border: rgba(0, 0, 0, 0.87) solid 1px;
                            }
                        }
                    }
                }
            }
        }
        div {
            &:nth-child(2), &:nth-child(3) {
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        input {
                            border-radius: 0 !important;
                            border-right: none;
                        }
                    }
                }
            }
            &:nth-child(4) {
                .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        input {
                            border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important;
                        }
                        &:hover {
                            .MuiOutlinedInput-notchedOutline {
                                // border-top-right-radius: 5px;
                                // border-bottom-right-radius: 5px;
                            }
                        }
                    }
                }
            }
             .input-wrap {
                 .MuiInputBase-colorPrimary {
                    input {
                        ::placeholder {
                            font-size: 5px !important;
                            color: red !important;
                        }
                    }
                     &:hover {
                         .MuiOutlinedInput-notchedOutline {
                             border-radius: 0;
                         }
                     }
                 }

                
             }
         }

        
    }

    .for-one-field {
        div {
            &:nth-child(2) {
                // .input-wrap { 
                    .MuiInputBase-colorPrimary {
                        input {
                            border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important;
                            // border-right: none;
                        }
                        &:hover {
                            .MuiOutlinedInput-notchedOutline {
                                border-top-left-radius: 0 !important;
                            border-bottom-left-radius: 0 !important;
                            }
                        }
                    }
                // }
            }
        }
    }

    .custom-black-select-option {
       div {
            .input-wrap {
                .MuiInputBase-colorPrimary {
                    &:hover {
                        .MuiOutlinedInput-notchedOutline {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }
            }
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            color: #fff;
            background: #516EF4 url(../assets/icons/icon-dropdown-arrow.svg);
            background-position: right 10px top 20px;
            background-repeat: no-repeat;
            border-radius: 4px 0px 0px 4px;
            height: 47px;
            padding: 2px 10px;
            font-size: 14px;
        }
    }

    .selection-opt-with-flag {
        // position: relative;
            background:#F3F3F4 url(../assets/icons/icon-flag.svg) no-repeat 20px 11px !important;
            padding-left: 55px;
        
    }

    .two-columns {
        display: flex;
        gap: 50px;
        .label {
            margin-bottom: 10px;
        }
        // div {
        //     &:nth-child(2) {
        //         padding-right: 50px;
        //         @include mediaquery(md, max) {
        //             padding-right: 0;
        //         }
        //     }
        // }
    }
    
}

.img-info {
    margin: 10px 0 0 0;
    font-size: 10px;
    display: block;
    color: #a6a6a6;
    font-weight: 400;
}

.modal-img-info {
    margin: 10px 0 0 0;
    font-size: 10px;
    display: block;
    text-align: center;
    line-height: 15px;
    padding: 0 5px;
    color: #a6a6a6;
    font-weight: 400;
}

.mobile-setting {
    @include mediaquery(md, max) {
        flex-direction: column;
    }
    .left-panel {
        @include mediaquery(md, max) {
            width: auto !important;
        }
    }
    
    .back-icon {
        @include mediaquery(md, max) {
            display: none;
        }
    }

    .accordion-wrapper {
        @include mediaquery(md, max) {
            margin-bottom: 0;
        }
    }
}

// trimmed text on hover show entire text
[data-title] {
	position: relative;
	cursor: help;
}
  
[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -46px;
	padding: 10px;
	background: #000;
	color: #fff;
	white-space: nowrap;
}
[data-title]:hover::after {
	content: '';
	position: absolute;
	bottom: -12px;
	left: 8px;
	border: 8px solid transparent;	
	border-bottom: 8px solid #000;
}

.ellipsis {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.width-250px {
    width: 250px;
}

.progress-bar-wrap {
    margin-bottom: 33px;
    @include mediaquery(md, max) {
        margin-bottom: 20px;
    }
    .progress-bar-counter {
        font-family: $font-inter;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: rgba(38, 38, 38, 0.7);
        margin: 20px 0;
        span {
            color: $secondary-color;
        }
    }
    .progress-bar-container {
        background-color:#E1E1E1;
        border-radius: 50px;
        .progress-bar {
            color:#000;
            background-color:#000;
            transition: 0.5s;
            height:12px;
            border-radius: 50px;
        }
    }
}


.scroll-bar-theme::-webkit-scrollbar {
	width: 5px;
	height: 4px
}


.scroll-bar-theme::-webkit-scrollbar {
    width: 5px;
    height: 4px
}

.scroll-bar-theme::-webkit-scrollbar-track {
    // background: #f1f1f1
}

.scroll-bar-theme::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: 15px
}

// input {
//     &:focus-visible {
//         border: $secondary-color solid 1px !important;
//     }
// }

#menu- {
    .MuiMenu-paper {
        ul {
            li {
                .MuiCheckbox-colorPrimary {
                    svg {
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}

.MuiPopover-root .MuiPaper-elevation::-webkit-scrollbar {
    width: 5px;
    height: 4px
}


.MuiPopover-root .MuiPaper-elevation::-webkit-scrollbar {
    width: 5px;
    height: 4px
}

.MuiPopover-root .MuiPaper-elevation::-webkit-scrollbar-track {
    background: #f1f1f1
}

.MuiPopover-root .MuiPaper-elevation::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: 15px
}

.MuiAutocomplete-popper {
    .MuiPaper-root {
        .MuiAutocomplete-listbox::-webkit-scrollbar {
            width: 5px;
            height: 4px
        }


        .MuiAutocomplete-listbox::-webkit-scrollbar {
            width: 5px;
            height: 4px
        }

        .MuiAutocomplete-listbox::-webkit-scrollbar-track {
            background: #f1f1f1
        }

        .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
            background: $secondary-color;
            border-radius: 15px
        }
    }
}

.ellipsis-for-2-line {
    display: -webkit-box !important;
    height: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-ellipsis {
    padding-bottom: 0 !important;
    height: 49px;
    margin-bottom: 0 !important;
}

input[type="date"]::before {
	color: #999999;
	content: attr(placeholder);
}
input[type="date"] {
	color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
	color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
	content: "" !important;
}

.selection-d-arrow {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        color: #000;
    }
   
    &::after {
        content: "";
        background: url(../assets/icons/icon-b-dropdown-arrow.svg) no-repeat;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
    }
}

.mt-25 {
    margin-top: 25px;
}

@media only screen and (max-width: 1199px) and (min-width: 1152px)  {
    .web-catalogue-wrapper .product-card-wrapper .card-body .cn-title-wrap.show-border {
        overflow: hidden !important;
    }
    .web-catalogue-wrapper .product-card-wrapper .card-body .cn-title-wrap .product-name .ellipsis-for-2-line {
        display: unset;
        height: unset !important;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: unset;
        text-overflow: unset;
        word-break: break-word;
    }
    .web-catalogue-wrapper .product-card-wrapper .product-name a, .web-catalogue-wrapper .product-card-wrapper .product-name > div {
        font-size: 16px !important;
    }
}
.verification-email-wrapper {
  display: block;
  padding-bottom: 10px;

  @include mediaquery(md) {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .verify-mail-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      width: 100%;
    }
    .first-heading {
      font-weight: 600;
      // font-size: 24px;
      line-height: 34px;
      color: $primary-color;
      width: 100%;

      @include mediaquery(md) {
        // width: 80%;
      }
    }
    .second-heading {
      font-weight: 400;
      // font-size: 15px;
      line-height: 22px;
      color: $primary-color;
      margin-top: 30px;

      .link {
        font-weight: 600;
        text-decoration-line: underline;
        color: $secondary-color;
        cursor: pointer;
        display: block;
      }
    }

    .email {
      font-weight: 600;
      color: $primary-color;
      width: 100%;
    }

    .gmail-btn,
    .outlook-btn {
      margin-top: 50px;
      background: #ffffff;
      border: 1px solid $primary-color;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      // text-decoration-line: underline;
      color: $secondary-color;
    }

    .outlook-btn {
      margin-top: 15px;
    }

    .did-not-email-text {
      margin-top: 50px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(38, 38, 38, 0.7);

      span {
        font-weight: 600;
        text-decoration-line: underline;
        color: $secondary-color;
        cursor: pointer;
        display: block;
      }
    }
  }

  .verify-mail-box-wrapper {
    // background:$secondary-color;
    border-radius: 0px;
    width: 100%;

    .verify-mail-text-box {
      padding: 15px 0 15px 15px;
      div {
        border-bottom: 1px solid #c7d5e1;
        height: 50px;
        background-color: #fff;
      }

      .email {
        padding-left: 15px;
        display: flex;
        align-items: center;
      }

      .vertical-center {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: none;
        }

        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: $primary-color;

          @include mediaquery(md) {
            font-size: 16px;
          }

        }

        .first-bar {
          width: 60px;
          height: 10px;
          left: 776px;
          top: 212px;
          background: #c7d5e1;
          opacity: 0.5;
          border-radius: 22px;

          @include mediaquery(md) {
            width: 95px;
          }
        }

        .second-bar {
          width: 250px;
          height: 10px;
          left: 1037px;
          top: 212px;
          background: #c7d5e1;
          opacity: 0.5;
          border-radius: 22px 0px 0px 22px;
        }
      }
    }
  }
}

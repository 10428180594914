@-moz-document url-prefix() {
    .product-share-wrapper .popup-wrapper {
        transform: scale(1);
        left: 30%;
        top: 10%;
    }
    .product-share-wrapper .popup-wrapper {
        top: -7% !important;
    }

    .product-share-wrapper .popup-wrapper .contact-details-form-wrap .input-wrapper .remove-field {
        top: 12px !important;
    }

    .buyer-header-wrapper .user-icon-wrapper .right-arrow {
        position: relative;
        right: 17px;
        top: 3px;
    }
    
    .our-team-wrapper .add-our-team-popup-wrapper .close-icon, .alert-message-component-wrap .message-wrapper .close-icon {
        top: 2px;
        right: 1px;
        transform: scale(0.8);
    }

    .catalogue-buyer-wrapper .right-navigation {
        overflow-y: unset !important;
    }

    .create-brand-wrapper .container-for-firfox {
        transform: scale(0.7);
        position: fixed;
        left: 50%;
        top: 50%;
    }

    .product-preview-wrapper .form-control .label, .product-details-wrapper .form-control .label {
        display: flex;
        align-items: center;
    }

    .information-icon span svg {
        transform: scale(.4);
    }
    .buyer-header-wrapper .user-icon-wrapper .right-arrow {
        right: 7px;
    }

    .hide-scroll #root .supplier-main-wrapper .container-wrapper .company-profile-wrap .edit-product-section .left-panel-wrapper {
        overflow-y: hidden;
    }

    .product-preview-wrapper .form-control .label .custom-tt-2,
    .product-details-wrapper .form-control .label .custom-tt-2
    {
        left: -17px;
    }

    .my-brand-wrapper .product-filter-section .filter-section .search-bar .close-icon,
    .catalogues-wrapper .catalogue-filter-section .filter-section .search-bar .close-icon {
        right: 43px;
    }
    .my-brand-wrapper .product-filter-section .filter-section .search-bar .search-input input,
    .catalogues-wrapper .catalogue-filter-section .filter-section .search-bar .search-input input {
        padding: 6px 32px 6px 5px !important;
    }

    .register-wrapper form .phone-number-wrapper > div .phone-number-wrap input {
        padding-bottom: 13px !important;
    }

    .catalogue-detail-wrapper .contacts-list-section .filter-section .search-wrap .close-icon {
        right: 41px !important;
    }

    .product-preview-wrapper .form-control.packsize-wrapper .packsize-wrap div .css-4mzek5-MuiFormControl-root-MuiTextField-root {
        // margin-top: unset !important;
    }

    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .setting div {
        padding-right: 15px;
    }
}
.left-panel {
    .back-icon {
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
            svg {
                path {
                    fill: $secondary-color;
                }
            }
        }
    }

    .accordion-wrapper {
        .header-section {
            &:hover {
                .title {
                    color: #6e7173;
                }
                .header-icon {
                    svg {
                        // fill: $secondary-color;
                        path {
                            fill: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}

@import "../../../style/all.scss";

.email-verification-success-container {

    header {
        position: relative !important;
        .register-logo-img {
            margin-top: 45px;
            @include mediaquery(md, max) {
                width: 156px !important;
                margin-top: 0;
            }
        }
    }

    a {
        color: $secondary-color;
    }

    .container-wrapper {
        margin-top: 20px !important;
    }

    .pre-launch-success-email-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .first-heading {
            font-weight: 700;
            font-size: 50px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            color: $secondary-color;
            font-family: $font-inter;
            // margin-top: 60px;

            @include mediaquery(md, max) {
                font-size: 30px;
            }
            
        }

        .second-heading {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: $primary-color;
            width: 346px;
            margin-top: 30px;
            font-family: $font-inter;
            margin-bottom: 10px;
            // @include mediaquery(md) {
            //     width: 35%;
            //     font-size: 18px;
            // }
        }

        .recommended-section {
            background: #FFFFFF;
            border: 1px dashed #99AAB9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px 0px 29px 0px;
            margin-top: 30px;
            width: 600px;

            @include mediaquery(md, max) {
                width: 100%;
            }

            .flex-row {
                @include mediaquery(md, max) {
                    flex-direction: column;
                }
            }

            .sooner-text {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #080C29;
                margin-top: 30px;
                font-family: $font-inter;
            }

            .second-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                color: $primary-color;
                width: 100%;
                margin-top: 30px;
                font-family: $font-inter;
                display: flex;
                flex-direction: column;
                @include mediaquery(md, max) {
                    width: 80%;
                }

                .icon-copy {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    text-decoration: none;
                    span {
                        position: relative;
                        top: 4px;
                    }
                }

                svg {
                    width: 20px;
                    height: 20px;
                }

                @include mediaquery(md) {
                    // width: 75%;
                    font-size: 18px;
                }
                a {
                    margin-left: 5px;
                }
            }

            .social-icons {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 30px;

                div {
                    margin-right: 0;
                    background: #FFFFFF;
                    // border: 1px solid #A6B4C1;
                    padding: 10px 7px;
                    border-radius: 50%;
                    
                    a {
                        svg {
                            height:30px;
                            width: 30px;
                        }
                    }
                    
                    &.ld, &.insta {
                        a {
                            svg {
                                &:hover {
                                    circle {
                                            stroke:$secondary-color;
                                            fill:$secondary-color;
                                    }
                                    g {
                                        > path {
                                            fill: white;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.fb {
                        a {
                            svg {
                                &:hover {
                                    circle {
                                            stroke:$secondary-color;
                                            fill:$secondary-color;
                                    }
                                    g {
                                        > path {
                                            &:first-of-type {
                                                fill:$secondary-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .disclaimer {
            font-weight: 400;
            font-size: 16px;    
            line-height: 30px;
            text-align: center;
            color: $primary-color;
            width: 100%;
            margin-top: 30px;
            font-family: $font-inter;
            padding-bottom: 30px;
            a {
                text-decoration: none;
            }
            @include mediaquery(md) {
                width: 44%;
                font-size: 18px;    
            }
        }
    }
}
.product-distribution-wrapper {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 30px;
    }

    .distribution-form {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        box-shadow: 0px 0px 50px rgba(89, 107, 198, 0.05);
        border-radius: 10px;
        min-height: 300px;
        padding: 25px 20px;

        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #686868;
        }

        .product-availablity-wrapper {
            gap: 25px;
        }

        .date-wrapper {
            width: 100%;

            input[type="date"]::before { 
                content: attr(data-placeholder);
                width: 100%;
            }
            
            /* hide our custom/fake placeholder text when in focus to show the default
             * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
             */
            input[type="date"]:focus::before,
            input[type="date"]:valid::before { display: none }

            .product-available-date {
                width: 96%;
                height: 25px;
                cursor: pointer;
                @include mediaquery(md, max) {
                    width: 91%;
                }
                &:hover {
                    border: #000000 solid 1px;
                }
            }
        }

        .product-available-wrapper {
            div {
                background: #F3F3F4;
                border-radius: 5px;
                margin: 0;
                width: 100%;
            }

            .MuiAutocomplete-root {
                background: transparent !important;

                .MuiFormControl-fullWidth {
                    background: transparent !important;

                    .MuiInputBase-colorPrimary {
                        background: transparent !important;
                        padding: 0 !important;

                        .MuiAutocomplete-endAdornment {
                            .MuiButtonBase-root {
                                background-color: transparent !important;

                                &:first-of-type {
                                    &:hover {
                                        svg {
                                            fill: red !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .MuiChip-root {
                background: rgba(105, 129, 241, 0.3);
                border: 1px dashed $secondary-color;
                border-radius: 20px;
                font-weight: 600;
                font-size: 12px;
                line-height: 22px;
                color: $secondary-color;
                width: auto;
            }
        }

        .country-wrapper {
            .MuiAutocomplete-root {
                background: transparent !important;

                .MuiFormControl-fullWidth {
                    background: transparent !important;

                    .MuiInputBase-colorPrimary {
                        background: transparent !important;
                        padding: 0 !important;

                        input {
                            cursor: pointer;
                        }

                        .MuiAutocomplete-endAdornment {
                            .MuiButtonBase-root {
                                background-color: transparent !important;

                                &:first-of-type {
                                    &:hover {
                                        svg {
                                            fill: red !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div {
                background: #F3F3F4;
                border-radius: 5px;
                margin: 0;
                width: 100%;
            }
        }

        .submit-componany {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;

            a {
                color: $secondary-color;
            }
        }

        .country-wrapper,
        .budget-wrapper {
            .MuiAutocomplete-root {
                background: #F3F3F4;

                .MuiInputBase-colorPrimary {

                    &:hover,
                    &:focus {
                        .MuiOutlinedInput-notchedOutline {
                            border: none !important;
                        }
                    }
                }
            }

            .selected-country-list {
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                margin-top: 10px;
                
                .country-wrap {
                    // margin-right: 0.5rem;
                    margin-top: 0.5rem;
                    max-width: 100%;

                    .country {
                        align-items: center;
                        background: #ccd5ff !important;
                        border: 1px dashed #3357ff;
                        border-radius: 17.5px;
                        color: #3357ff;
                        display: flex;
                        font-size: 12px;
                        font-weight: 600;
                        gap: 5px;
                        justify-content: center;
                        line-height: 17px;
                        margin-right: 10px;
                        // opacity: .65;
                        padding: 5px 7px 5px 11px;

                        .svg {
                            display: flex;

                            svg {
                                height: 20px;
                                width: 20px;
                                margin-left: 5px;

                                background: $secondary-color;
                                border: $secondary-color solid 1px;
                                border-radius: 50%;
                                fill: #fff;
                                zoom: 68%;
                                padding: 2px;
                                cursor: pointer;

                                &:hover {
                                    fill: red;
                                    background: #fff;
                                    border: red solid 1px;
                                }
                            }
                        }
                    }
                }

                div {

                    background: #fff;
                    border-radius: 0;
                    margin: 0;
                    width: auto;
                }
            }
        }

        .budget-wrapper {
            .MuiAutocomplete-endAdornment {
                width: auto;
            }

            .store-list-wrap {
                // margin-top: 20px;
                // margin-bottom: 20px;

                .text-wrap {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    .store-text {
                        font-size: 0.875rem;
                        display: inline-block;
                        transform: translateY(0.542328em);
                        line-height: 1.5rem;
                        color: #80899d;
                        margin-bottom: 15px;
                    }
                }
            }

            .retailer-list-wrap {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .no-of-store-wrapper {
                    display: flex;

                    .name {
                        display: flex;
                        align-items: center;
                        flex: 1 1 10%;
                    }

                    .store-checkbox {
                        flex: 1 1 15%;
                        display: flex;
                        align-items: center;
                        @include mediaquery(md, max) {
                            font-size: 12px;
                        }
                    }

                    .store-input {
                        flex: 1 1 65%;
                        padding-left: 10px;
                        @include mediaquery(md, max) {
                            flex: 1 1 17%;   
                        }
                        input {
                            width: 95%;
                            background-color: #FFFFFF;
                            &:hover {
                                border: #000000 solid 1px;
                            }
                            @include mediaquery(md, max) {
                                width: 100%;
                            }
                        }
                    }

                    .remove {
                        flex: 1 1 5%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding-left: 10px;
                        @include mediaquery(md, max) {
                            flex: 1 1 0%;
                            position: relative;
                            right: -16px;
                        }

                        svg {
                            transform: scale(.8);

                            &:hover {
                                fill: red;
                            }
                        }
                    }
                }
            }
        }
    }
}
// FOR DESKTOP RANGE BETWEEN 822 to 960
@media only screen and (min-width: 822px) and (max-width: 960px)  {
        .for-auth-container, .register-container { // login, registration, forget and reset pages
            .container-wrapper, .header-wrap {
                zoom:60%;
            }
        }

        .create-brand-wrapper, .process-video--wrapper, .bulk-upload-wrapper {
            .popup-wrapper {
                zoom:50%;
            }
        }

        // setting profile
        .profile-wrapper {
            .right-panel {
                width: 100% !important;
            }
        }

        .product-share-wrapper {
            .popup-wrapper {

            }
        }

        .register-wrapper form .button-wrapper {
            margin-bottom: 100px;
        }
}

@media only screen and (min-width: 960px) and (max-width: 1310px)  {
    // .create-brand-wrapper, .process-video--wrapper, .bulk-upload-wrapper {
    //     .popup-wrapper {
    //         zoom:60%;
    //     }
    // }

    .register-wrapper form .button-wrapper {
        margin-bottom: 100px;
    }
}

@media only screen and (min-width: 1308px) and (max-width: 1439px)  {
    .product-share-wrapper {
        .popup-wrapper {
            zoom:80%;   
            @-moz-document url-prefix() {
                transform:scale(0.8);
            }
        }
    }
    .create-brand-wrapper, .process-video--wrapper, .bulk-upload-wrapper {
        .popup-wrapper {
            zoom:85%;
        }
    }
}

@media only screen and (min-width: 1151px) and (max-width: 1310px)  {
    .product-share-wrapper {
        .popup-wrapper {
            zoom:70%;   

            @-moz-document url-prefix() {
                transform:scale(0.7);
            }
        }
    }
    .create-brand-wrapper, .process-video--wrapper, .bulk-upload-wrapper {
        .popup-wrapper {
            zoom:70%;
        }
    }

    .website-product-details-wrapper .box-container {
        width: 1100px;
    }
}

@media only screen and (min-width: 1152px) and (max-width: 1440px)  {
    .product-share-wrapper {
        .popup-wrapper {
            zoom:80%;   
            @-moz-document url-prefix() {
                transform:scale(0.8);
            }
            // -moz-transform: scale(0.8);
        }
    }

    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 65%;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1152px)  {
    .product-share-wrapper {
        .popup-wrapper {
            zoom:60% !important;   
            @-moz-document url-prefix() {
                transform:scale(0.6);
            }
        }
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 80%;
    }
    .company-profile-wrap .edit-product-section .left-panel-wrapper {
        zoom: 80%;
    }

    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper {
        height: 300px;
        width: 300px;
    }

    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .image-slider .slider-wrapper .slides-warp {
        height: 300px !important;
    }

    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .image-slider .image-wrap,
    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .image-slider .image-wrap img {
        height: 300px;
        width: 300px;
    }
    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .image-show-slider .image-wrap,
    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .image-show-slider .image-wrap img {
        width: 84px;
        height: 84px;
    }

    .website-product-details-wrapper .product-details-wrapper .left-div, .website-product-details-wrapper .technical-specifiction-wrapper .left-div, .website-product-details-wrapper .certifications-wrapper .left-div, .website-product-details-wrapper .distribution-wrapper .left-div, .website-product-details-wrapper .marketing-wrapper .left-div {
        width: auto;
    }

    .website-product-details-wrapper .overview-section-wrapper .product-image-wrapper .no-product-image-wrapper {
        height: 300px;
        width: 300px;
    }

    .website-product-details-wrapper .box-container {
        width: 1000px;
    }
}

@media only screen and (min-width: 822px) and (max-width: 960px)  {
    .product-share-wrapper {
        .popup-wrapper {
            zoom:51%;   

            @-moz-document url-prefix() {
                transform:scale(0.5);
            }
        }
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 100%;
    }

    .website-product-details-wrapper .box-container {
        width: 800px;
    }
}

@media only screen and (max-width: 767px)  {
    .divider {
        display: none;
    }
}


// FOR MOBILE RANGE BTW 0 to 360
@media only screen and (min-width: 0px) and (max-width: 360px)  {
    .for-auth-container, .onboarding-container, .register-container { // login, registration, forget and reset pages
        .logo-img {
            width: 100px;
        }
    }

    .brand-container { // Supplier
        .logo {
            width: 100px;
            padding-left: 5px;
        }
    }
}

@media only screen and (min-width: 853.33px) and (max-width: 1024px)  {
    .company-profile-wrap .disclaimer-wrapper {
        zoom: 70%;
    }
    .save-btn-wrapper {
        display: block !important;
    }
}



/* ----------- Galaxy S4, S5 and Note 3 ----------- */

@media screen 
  and (device-width: 640px) 
  and (orientation: landscape) {
    .login-container {
        height: unset;
    }
}


@media screen 
  and (device-width: 740px) 
  and (orientation: landscape) {
    .login-container {
        height: unset;
    }
}

@media screen 
  and (device-width: 667px) 
  and (orientation: landscape) {
    .login-container {
        height: unset;
    }
}
// @media (max-height: 640px) and (max-width: 320px) {
//     .login-container {
//         height: unset;
//     }
// }



@media screen and (max-width: 1366px), 
       screen and (max-height: 768px) {
        .create-brand-wrapper .popup-wrapper {
            // zoom: 85%;
        }
}



@media (min-width: 1920px) and (max-width: 2560px)  {
    .create-brand-wrapper .popup-wrapper {
        zoom: 125%;
    }
}

@media (min-width: 1151px) and (max-width: 1366px)  {
    .accordion-wrapper .header-section .title {
        font-size: 16px;
    }
}

@media (min-width: 900px) and (max-width: 1152px)  {
    .accordion-wrapper .header-section .title {
        font-size: 14px;
    }
}
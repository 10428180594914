.buyer-onboarding-container {
	// height: 100vh;
	// padding: 0 !important;

	@include mediaquery(md, max) {
		height: auto;
	}

	.container-wrapper {
		@include mediaquery(md, max) {
			width: 90%;
			margin: 0 auto;
		}
	}

	.onboarding-component-wrap {
		select {
			border: #C9C9C9 solid 1px;

			&:hover {
				border: rgba(0, 0, 0, 0.87) solid 1px;
			}
		}

		input {
			&:focus-visible {
				border: $secondary-color solid 1px !important;
			}
		}

		.tabs-container {
			padding: 0 105px;

			@include mediaquery(md, max) {
				padding: 0;
			}
		}

		padding: 15px;

		@include mediaquery(md, max) {
			padding-left: 0;
			padding-right: 0;
		}

		.hr {
			opacity: 0.1;
			border: 1px solid #000000;
			margin: 53px 0 34px 0;
		}

		.first-heading {
			margin: 20px 0;
			@include mediaquery(md, max) {
				margin-top: 0;
			}
		}

		.second-heading-wrap {

			.second-heading {
				// display: inline-block;
				padding: 0px 40px;

				@include mediaquery(md, max) {
					padding: 0;
					width: 100%;
				}
			}
		}

		.on-boarding-form {
			.MuiInputBase-colorPrimary {
				&:hover {
					fieldset {
						// border-color: $secondary-color;
					}
				}
			}
		}

		#yearSelect,
		#brandSelect {
			&:hover {
				// border-color: $secondary-color;
			}
		}

		.upload-image-div {
			&:hover {
				border: $secondary-color solid 1px !important;
			}
		}

		.button-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&.flex-end {
				align-items: flex-end;
				justify-content: flex-end;
			}

			.button-primary,
			.button-back {
				@include mediaquery(md, max) {
					padding: 8px 20px;
				}
			}

			.button-back {
				padding: 5px 13px;
			}
			.skip-btn {
				font-weight: 600;
				font-size: 16px;
				display: flex;
				align-items: center;
				text-align: center;
				// text-decoration-line: underline;
				// text-underline-position:under;
				color: $secondary-color;
				cursor: pointer;
			}

			.submit-btn {
				width: 150px;
				height: 50px;

				// &.back-btn {
				// 	border: 1px solid #97A8B8;
				// 	border-radius: 5px;
				// 	background: #fff;
				// 	text-align: center;
				// 	color: #97A8B8;
				// 	margin-right: 10px;

				// 	@include mediaquery(md) {
				// 	margin-right: 20px;
				// 	}
				// }
			}
		}

		.tabs-wrap {
			display: flex;
			align-items: inherit;
			justify-content: space-evenly;
			border-bottom: 1px solid rgb(0 0 0 / 10%);
			// cursor: pointer;

			@include mediaquery(md, max) {
				justify-content: space-between;
			}

			// padding-bottom: 15px;

			.tab-items {
				padding-bottom: 20px;
				color: $default-text-color;
				font-size: 16px;
				// @include mediaquery(md, max) {
				// 	font-size: 12px;
				// }
			}

			.selected {
				font-weight: 800;
				color: $primary-color;
				padding-bottom: 5px;
				display: inline;
			}
		}

		.select-more-wrap {
			.text {
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				margin: 10px 0 25px 0;
				color: $secondary-color;
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}

		.tabs-container {
			padding-top: 20px;

			.company-profile-wrap {
				form {

					div {
						width: 100%;

						.upload-image-div {
							&:hover {
								border: rgba(0, 0, 0, 0.87) solid 1px;
							}
						}
					}

					.label {
						margin: 12px 0;
					}


				}
			}

			.industry-wrap,
			.customer-wrapper {

				.industry-list-wrap,
				.interest-list-wrap {
					margin-top: 20px;
					grid-gap: 27px;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-row-gap: 15px;

					.on-hover-draw-line {

						&:hover::before,
						&:hover::after {
							border-radius: 5px;
						}
					}

					div {
						background: #FFFFFF;
						// border: 2px solid #97A8B8;
						border-width: 2px;
						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						// min-height: 50px;
						text-align: center;
						cursor: pointer;
						padding: 15px 25px;
						max-width: 233.81px;

						@include mediaquery(md, max) {
							padding: 25px 15px;
							max-width: unset;
						}

						&.selected {
							// border: 2px solid $secondary-color;

							background: none;
							border: 0;
							box-sizing: border-box;

							// Using inset box-shadow instead of border for sizing simplicity
							box-shadow: inset 0 0 0 2px $secondary-color;

							// Required, since we're setting absolute on pseudo-elements
							position: relative;
							vertical-align: middle;

							&::before {
								background: url(../../../../assets/icons/bluetick.png) no-repeat right 0px !important;
								top: -10px;
								right: -9px;
							}

							&::before,
							&::after {
								box-sizing: inherit;
								content: '';
								position: absolute;
								width: 100%;
								height: 100%;
							}
						}

						span {
							font-weight: 500;
							font-size: 14px;
							line-height: 20px;
							display: flex;
							align-items: center;
							color: #273042;
						}
					}
				}
			}

			.customer-wrapper {
				.interest-list-wrap {
					// grid-gap: 25px;
					grid-gap: 20px;
					grid-row-gap: 30px;

					div {
						padding: 43px 0px;
						max-width: 245px;

						@include mediaquery(md, max) {
							padding: 43px 20px;
						}
					}
				}
			}

			.company-details-wrapper {
				form {
					div {
						width: 100%;

						.upload-image-div {
							&:hover {
								border: rgba(0, 0, 0, 0.87) solid 1px;
							}
						}
					}

					.label {
						margin: 12px 0;
					}
				}

				.logo-wrap {
					position: relative;
					width: 160px;
					height: 160px;

					.logo {
						margin-top: 20px;
						width: 160px;
						height: 160px;
						background: #F3F3F4;
						border-radius: 10px;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						color: rgba(8, 12, 41, 0.4);
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						border: #F3F3F4 solid 1px;

						&:hover {
							border: $secondary-color dashed 1px;
						}
					}

					.edit-icon {
						position: absolute;
						bottom: -10%;
						left: 35%;

						@include mediaquery(md, max) {
							left: 85%;
							right: 0;
						}

						@include mediaquery(md) {
							bottom: -6%;
							left: 85%;
						}
					}
				}

				div {
					// cursor: pointer;

					img {
						width: 160px;
						height: 160px;
						// object-fit: contain;
					}
				}

				.image-preview-section {
					position: relative;

					.preview-img {
						position: relative;
						width: 160px;

						.edit-icon {
							position: absolute;
							// right: -15px;
							bottom: -11px;
							cursor: pointer;
							left: 85%;

							&:hover {
								svg {
									circle {
										fill: #fff;
										stroke: #f1f1f1;
									}

									g {
										path {
											fill: $secondary-color;

										}
									}
								}
							}
						}

						img {
							width: 160px;
							height: 160px;
							border-radius: 5px;
						}
					}

					.remove {
						position: absolute;
						top: 3%;
						cursor: pointer;
						background: #fff;
						width: 24px;
						height: 24px;
						z-index: 1;
						right: 0;
						left: 25%;


						@include mediaquery(md, max) {
							right: 0;
							left: 43%;
						}

						// zoom: 80%;
						svg {
							fill: #666766;

							@include mediaquery(md, max) {
								width: 20px;
								height: 20px;
							}
						}

						&:hover {
							svg {
								fill: red;
							}
						}

						@include mediaquery(md, max) {
							width: 20px;
							height: 20px;
						}
					}
				}

				.button-wrapper {
					div {
						display: flex;
						align-items: flex-end;
						justify-content: flex-end;
					}
				}
			}
		}

		.business-type-multiselect {
			#businessType {
				// background: #F3F3F4 url(http://localhost:3000/static/media/icon-calender.d353341….svg) no-repeat 20px 16px !important;
				// background: #F3F3F4 url(../../../assets/icons/black-cheron-icon.png) no-repeat 20px 16px !important;
				// padding-left: 45px;
				font-family: "Inter", sans-serif;
				border: #C9C9C9 solid 1px;
				padding: 14px 14px;
				background: #F3F3F4;
				border-radius: 6px;
				width: 100%;
				font-family: $font-inter;
				cursor: pointer;
			}

			.MuiInputBase-colorPrimary {
				&:hover {
					fieldset {
						// border-color: $secondary-color;
					}
				}
			}

			div {
				margin: 0;
			}

			fieldset {
				border-right-width: 1px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}

		}
	}
}
.form-input-control {

    .field-err-msg {
        position: absolute;
        font-size: 12px;
        color: red;
        margin-top: 0;

        &.add-new-pro-custom-style {
            bottom: -30px;
            left: 60px;
            @include mediaquery(md, max) {
                left: 0;
            }
        }
    }

    .fields-container {
        border: #E6E6E6 solid 1px;
        border-radius: 10px;
        padding: 25px 20px;
        margin-bottom: 40px;
        box-shadow: 0px 0px 50px rgba(89, 107, 198, 0.05);
        .form-control {
            &:first-of-type {
                margin-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0 !important;
            }

            .itemcode-wrap {
                .MuiInputBase-colorPrimary {
                    padding: 0;
                    textarea {
                        border: #C9C9C9 solid 1px;
                        border-radius: 5px;
                        padding: 5px 10px;
                    }
                }
            }
            .case-price {
                .case-price-value {
                    padding: 20px 0 16px 0;
                }
            }

            .input-radio {
                >div {
                    margin-bottom: 0 !important;
                }
            }

            .text-editor-wrapper {
                // color: red;
                .rdw-editor-wrapper {
                    .rdw-editor-toolbar {
                        .rdw-link-wrapper {
                            .rdw-link-modal {
                                height: auto !important;
                                left: -139px !important;
                                @include mediaquery(md, max) {
                                    left: -100px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    fieldset, select {

    border-color: rgb(255 255 255 / 23%);

    }

    input {
        border:#f3f3f4 solid 1px;
        font-family: $font-inter;
        -webkit-appearance: none;
    }

    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $primary-color;
    }


    textarea {
        font-size: 14px;
        font-family: $font-inter;
    }
    

    ::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $primary-color;
      opacity: 0.4;
    }

    select {
        height: 50px;
        padding: 14px 14px;
        background: #F3F3F4;
        border-radius: 6px;
        width: 100%;
        font-family: $font-inter;
        cursor: pointer;
        // border: #C9C9C9 solid 1px;
        &:hover {
            border: rgba(0, 0, 0, 0.87) solid 1px;
        }
    }
    div {
        font-family: $font-inter;
    }
    input {
        background: #F3F3F4;
        border-radius: 6px;
        font-size: 14px;
        font-family: $font-inter;
        border: #F3F3F4 solid 1px
    }

    .upload-image-wrapper {
        display: block;
        align-items: center;
        justify-content: space-around;
        margin: 20px 0;
        
        @include mediaquery(md) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .upload-image-div {
            height: 10px;
            border: 1px solid #97a8b8;
            border-radius: 5px;
            padding: 20px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(151, 168, 184, 0.1);
            cursor: pointer;

            @include mediaquery(md) {
            width: 40%;
            }
        }

        #file-upload {
            display: none;
        }

        .image-uploaded-wrapper {
            display: flex;
            background: #FFFFFF;
            box-shadow: 0px 0px 44px rgb(215 220 242 / 60%);
            border-radius: 5px;
            align-items: center;
            height: 50px;
            margin-top: 20px;

            @include mediaquery(md) {
                margin-top: 0px;
                width: 60%;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;

                .svg-wrapper {
                    margin-top: 10px;

                    svg {
                    height: 35px;
                    width: 35px;
                    padding-top: 5px;
                    }
                }

                .file-name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 15px;
                    color: $primary-color;
                    white-space: nowrap;
                }
            }

            .remove-file-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 7px;
                cursor: pointer;
                margin-top: 6px;
                span {
                    svg {
                        &:hover {
                            path {
                                stroke: red;
                            }
                        }
                    }
                }
            }

            .file-name-wrapper {
                justify-content: flex-start;
                padding-left: 15px;
            }
        }
    }
}
@media print {

    .web-catalogue-wrapper {
        zoom: 80%;
        transform: scale(0.8);
    }

    .web-catalogue-wrapper .product-card-wrapper .product-image .slider-wrapper .slides-warp .slides .slide .image-wrap img {
        // width: 290px;
        // height: 290px;
        // object-fit: unset;
        // transform: unset;
    }

    .web-catalogue-wrapper .profile-section .information-wrap {
        display: block !important;
    }
    .web-catalogue-wrapper .profile-section .information-wrap .info-section {
        border-right: none !important;
    }
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list,
    .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards {
        grid-template-columns: repeat(3, 1fr) !important;
    }
    .website-product-details-wrapper .box-container {
        width: 1040px !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .category-name-wrap {
        padding-top: 15px;
        padding-bottom: 0;
        margin-bottom: 30px !important;
    }
    .website-product-details-wrapper .manage-section {
        display: none;
    }
    .website-product-details-wrapper .manage-section {
        border: 0px solid rgb(218, 221, 225)
    }
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .pro-items-cards {
        // margin-top: 40px;
    }

    .web-catalogue-wrapper .preview-header-wrapper {
        padding: 0 !important;
    }

    .web-catalogue-wrapper .profile-section .some-of-product-wrap {
        width: 100% !important;
    }

    .web-catalogue-wrapper .preview-banner-wrapper {
        height: auto !important;
    }
    .web-catalogue-wrapper .preview-banner-wrapper img {
        height: auto !important;
        object-fit: unset !important;
    }

    .web-catalogue-wrapper .save-catalogue {
        display: none !important;
    }
    .web-catalogue-wrapper .manage-section, .web-catalogue-wrapper .products-section .search-section,
    .web-catalogue-wrapper .product-card-wrapper .product-image .chevron-wrap {
        display: none !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .no-image-available, .web-catalogue-wrapper .product-card-wrapper .no-image-available img {
        width: 100% !important;
        height: auto !important;
    }
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .category-name-wrap .arrow-icon,
    .web-catalogue-wrapper .products-section .filter-wrapper .tooltip-element-product {
        display: none;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper {
        margin-top: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-info .info .value span {
        overflow: hidden;
        height: 17px;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-image .slider-wrapper .slides-warp .slides {
        height: auto !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        // row-gap: 0 !important;
        // margin-top: 0 !important;
        // gap: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper {
        // padding: 0 !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .category-name-wrap {
        padding-bottom: 0 !important;
        border-width: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-info .info .value .available,
    .web-catalogue-wrapper .product-card-wrapper .product-info .info .label,
    .web-catalogue-wrapper .product-card-wrapper .product-info .info .value .non-available,
    .web-catalogue-wrapper .product-card-wrapper .product-info .info .value {
        font-size: 11px !important;
    }

    .web-catalogue-wrapper .products-section {
        padding: 0 10px !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-info .info .value {
        width: unset !important;
    }
    
    .web-catalogue-wrapper .product-card-wrapper .product-image .slider-wrapper .slides-warp .slides .slide .image-wrap img {
        transform: unset !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .card-body .cn-title-wrap {
        // margin-top: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper {
        padding: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-info .info {
        margin: 4px 0  !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-image .disk-warp {
        display: none !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-name a, .web-catalogue-wrapper .product-card-wrapper .product-name > div {
        font-size: 14px !important;
        line-height: 18px !important;
    }

    .ellipsis-for-2-line, .no-ellipsis {
        height: 22px !important;
    }

    // .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .pro-items-cards  {
    //     margin-top: 40px !important;
    // }
    // .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .pro-items-cards:nth-child(-n+3) {
    //     margin-top: 0px !important;
    // }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .pro-items-cards {
        margin-bottom: 20px !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .show-for-print-pdf {
        display: block !important;
    }
    .web-catalogue-wrapper .product-card-wrapper .hide-for-print-pdf {
        display: none !important;
    }
}

@media print and (orientation: landscape) {
    /* landscape styles */
    
}

@media print and (orientation: portrait) {
    /* portrait styles */
    .web-catalogue-wrapper .preview-banner-wrapper {
        padding-top: 200px;
    }
    .web-catalogue-wrapper .preview-header-wrapper {
        display: flex;
        justify-content: center;
    }
    .web-catalogue-wrapper .preview-header-wrapper .logo-wrap {
        flex-direction: column;
        gap: 20px;
    }
}
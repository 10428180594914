.profile-section-wrapper {
    .heading {
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #262626;

        @include mediaquery(md, max) {
            font-size: 16px;
        }
    }

    .divider {
        margin-top: 20px;
        opacity: 0.1;
        border: 1px solid #000000;
    }

    .profile-inner-container {
        form {
            .profile-form-wrapper {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;

                @include mediaquery(md, max) {
                    flex-direction: column;
                    gap: 40px;
                }

                .profile-left-inner-container {
                    flex: 0 0 250px;

                    @include mediaquery(md, max) {
                        flex: unset;
                    }

                    .user-profile-logo-section-wrap {
                        .logo-wrap {
                            position: relative;

                            @include mediaquery(md, max) {
                                margin: 0 auto;
                                width: 182px;
                            }

                            .logo {
                                margin-top: 20px;
                                width: 182px;
                                height: 213px;
                                background: #F3F3F4;
                                border-radius: 10px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: rgba(8, 12, 41, 0.4);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                cursor: pointer;

                                @include mediaquery(md, max) {
                                    margin: 0 auto;
                                }

                                .img-info {
                                    padding: 0 30px;
                                    text-align: center;
                                }
                            }

                            .edit-icon {
                                position: absolute;
                                right: 58px;
                                bottom: -8px;
                                cursor: pointer;

                                @include mediaquery(md, max) {
                                    right: -10px;
                                    bottom: -8px;
                                }
                            }
                        }

                        .user-edit-sec {
                            cursor: pointer;
                            position: relative;

                            @include mediaquery(md, max) {
                                text-align: center;
                                width: 182px;
                                margin: 0 auto;
                            }

                            img {
                                width: 182px;
                                height: auto;
                                object-fit: contain;
                                border-radius: 22px;
                                border: $secondary-color solid 2px;
                            }

                            .edit-icon {
                                position: absolute;
                                right: 58px;
                                bottom: -8px;
                                cursor: pointer;

                                @include mediaquery(md, max) {
                                    right: -12px;
                                    bottom: -5px;
                                }
                            }
                        }
                    }

                    .user-combine-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #313E42;
                        text-align: center;
                        margin-right: 64px;
                        margin-top: 16px;
                        line-height: 25px;
                        font-family: $font-inter;

                        @include mediaquery(md, max) {
                            margin-right: 0;
                        }
                    }
                }

                .profile-right-inner-container {
                    flex: 0 0 calc(100% - 250px);

                    input {
                        background: #f3f3f4;
                        border-radius: 5px;
                        color: $primary-color;
                    }

                    div {
                        width: 100%;
                        margin-bottom: 2px;
                        position: relative;
                    }

                    .pro-cn {
                        background: #f3f3f4;
                        border-radius: 5px;
                        color: #313E42;

                        #country {
                            padding: 10px 14px !important;
                        }

                        >div {
                            div {
                                display: flex;
                                gap: 10px;
                            }
                        }

                        fieldset {
                            border-right-width: 1px;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }
                    }

                    .name-wrapper {
                        display: block;
                        margin-bottom: 28px;
                        gap: 50px;

                        @include mediaquery(md) {
                            display: flex;
                        }

                        .fname-wrapper {
                            @include mediaquery(md) {
                                // margin-right: 50px;
                            }
                        }
                    }

                    .title-email-wrapper {
                        display: block;
                        margin-bottom: 28px;

                        @include mediaquery(md) {
                            display: flex;
                            color: red;
                        }

                        .fname-wrapper {
                            @include mediaquery(md) {
                                // margin-right: 50px;
                            }
                        }

                        .form-control {
                            .MuiFormControl-root  {
                                margin-top: 0;
                            }
                            
                        }
                    }

                    .phone-country-wrapper {
                        display: block;
                        margin-bottom: 28px;

                        @include mediaquery(md) {
                            display: flex;
                        }

                        .fname-wrapper {
                            @include mediaquery(md) {
                                // margin-right: 50px;
                            }
                        }
                    }
                }
            }

            .button-section {
                text-align: right;

                .button-wrapper {
                    margin-top: 40px;
                    @include mediaquery(md, max) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
.process-video--wrapper {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 6;

    .popup-wrapper {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;

        @include mediaquery(md) {
            width: 740px;
        }

        .first-heading {
            font-weight: 700;
            font-size: 25px;
            line-height: 25px;
            text-align: center;
            color: $primary-color;

            @include mediaquery(md) {
                font-size: 32px;
                line-height: 32px;
            }
        }

        .second-heading {
            margin-top: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #262626;
            opacity: 0.7;
        }

        .video {
            margin: 40px 0;

            iframe {
                background: #000000;
                // opacity: 0.5;
                border-radius: 6px;
                width: 100%;
                height: 280px;

                @include mediaquery(md) {
                    width: 574px;
                    height: 280px;
                }
            }
        }

        .hr {
            opacity: 0.1;
            border: 1px solid #000000;
            margin: 28px 0;
            width: 100%;

            @include mediaquery(md) {
                width: 73%;
            }
        }

        .button-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 75%;

            button {
                // width: 120px;
                // height: 50px;

                // @include mediaquery(md) {
                //     width: 150px;
                //     height: 50px;
                // }

                &.no-thanks-btn {
                    text-transform: capitalize;
                    border: 1px solid #97A8B8;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #97A8B8;
                    font-family: $font-inter;

                    @include mediaquery(md) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                &.play-video-btn {
                    background: $secondary-color;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    font-family: $font-inter;

                    @include mediaquery(md) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}
.alert-message-component-wrap {
    color: #fff;
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -10%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    position: fixed;
    z-index: 99;

    @include mediaquery(md, max) {
        width: 88% !important;
    }
    @include mediaquery(md) {
        top: 5%;
    }

    &.hide-alert {
        transition: all .5s;
        top: -25%;
    }
    .message-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;
        padding: 4px 30px 4px 19px;
        top: 0;
        display: inline-block;
        position: relative !important;
        @include mediaquery(md, max) {
            width: 90%;
        }

        &.error {
            background: #FFD8CD;
            border: 2px solid #EA4E2C;
            border-radius: 5px;
        }

        &.information {
            background: #D9EAFF;
            border: 2px solid #076DD9;
            border-radius: 5px;
        }

        &.success {
            background: #E6FFED;
            border: 2px solid #3AB35A;
            border-radius: 5px;
            position: fixed;
        }
        .message-text {
            font-family: $font-inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            // letter-spacing: 0.05em;
            color: $toast-msg-text-color;
            flex: 1 1 90%;

            // @include mediaquery(md) {
            //     font-size: 16px;
            // }
        }

        .close-icon {
            color: $toast-msg-text-color;
            border-radius: 10px;
            font-weight: 700;    
            cursor: pointer;    
            position: relative;
            right: 5px;
            zoom: 70%;
            top: 8px;
            flex: 1 1 10%;
            position: absolute;
        }
    }
}
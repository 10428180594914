 /* Media Query for Large screens */ 
 @media (min-width: 1281px) { 
    body { 
        // background-color: red; 
    } 
} 

@media only screen and (min-width: 1600px) and (max-width: 1920px) { 
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        grid-template-columns: repeat(5, 1fr) !important;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 2880px) { 
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        grid-template-columns: repeat(6, 1fr) !important;
    }
}


@media only screen and (min-device-width: 1309px) and (max-device-width: 1920px) {
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        gap: 0px !important;
    }
    
    .web-catalogue-wrapper .product-card-wrapper .product-info-wrapper .product-info .info .label {
        flex-basis: 64%;
        font-size: 13px !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-info-wrapper .product-info .info .value .available {
        font-size: 13px !important;
    }
    .web-catalogue-wrapper .product-card-wrapper .product-info-wrapper .product-info .info {
        gap: 7px !important;
    }
    .web-catalogue-wrapper .product-card-wrapper .product-info-wrapper .product-info .info .value {
        font-size: 13px !important;
    }
}

.edit-product-container {
    &.supplier-main-wrapper {
        .container-wrapper {
            border-bottom: #e5e5e5 solid 1px;
            padding-bottom: 12px;
        }
    }
}

.company-profile-wrap {

    .fixed-header {
        position: fixed !important;
        width: 100%;
        left: 0;
        background: #fff;
        z-index: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        top: 59px;

        @include mediaquery(md, max) {
            position: relative !important;
            top: unset;
            left: unset;
            border-bottom: unset;
        }
    }

    .header-section-wrapper {
        padding: 15px 30px;
        display: flex;
        align-items: center;

        @include mediaquery(md, max) {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            gap: 15px;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 15px;
        }

        .back-button-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            @include mediaquery(md, max) {
                justify-content: flex-start;
            }

            .back-icon {
                margin-right: 20px;
                cursor: pointer;

                svg {
                    fill: #374957;
                }
            }

            .text {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: rgba(38, 38, 38, 0.7);
            }

            &:hover {
                .back-icon {
                    svg {
                        fill: $secondary-color;
                    }
                }

                .text {
                    color: $secondary-color;
                }
            }
        }

        .divider {
            border: 1px solid #C9C9C9;
            margin-left: 40px;
            margin-right: 40px;
            height: 40px;
        }

        .heading-text-wrapper {
            display: flex;
            align-items: center;
            width: 50%;
            @include mediaquery(md, max) {
                align-items: flex-start;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                width: 100%;
            }

            .heading-text {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #262626;

                @include mediaquery(md, max) {
                    line-height: 30px;
                }
            }

            .product-label {
                background: rgba(105, 129, 241, 0.3);
                border: 1px solid $secondary-color;
                border-radius: 20px;
                width: auto;
                height: 30px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 30px;
                font-size: 12px;
                line-height: 22px;
                color: $secondary-color;

                @include mediaquery(md, max) {
                    margin-left: 0;
                }
            }

            .btn-preview {
                position: absolute;
                right: 0;
                bottom: 16px;
                text-decoration: none;
                display: flex;
                gap: 10px;
                justify-content: flex-end;
            }
        }
    }

    .seperator {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 10px -24px 40px -24px;

        &.below {
            margin: 10px 30px 40px 30px;

            @include mediaquery(md, max) {
                margin: 10px 10px 40px 10px;
            }
        }
    }

    .fixed-container {
        position: fixed;
        width: calc(100% - 108px);

        @include mediaquery(md, max) {
            position: unset;
            width: unset;
        }
    }

    .cust-head {
        margin-top: 85px;
        @include mediaquery(md, max) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .heading-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .heading {
            font-weight: 600;
            font-size: 22px;
            line-height: 29px;
            color: #000000;
        }

        .edit-btn {
            border: 1px solid #D7D7D7;
            border-radius: 2px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            padding: 7px 20px;
            margin-right: 20px;
            cursor: pointer;
            font-family: "Inter", sans-serif;
        }

        .progress-bar-wrapper {
            width: 100%;
            // background: rgba(243, 243, 244, 0.3);
            // border: 1px solid #F2EEEE;
            // height: 100px;
            // padding: 15px;
            display: flex;
            align-items: center;
            gap: 20px;
            // margin-bottom: 30px;
            padding-bottom: 12px;
            @include mediaquery(md, max) {
                // padding: 0px 15px;
            }

            .left-navigation {
                cursor: pointer;

                @include mediaquery(md, max) {
                    width: unset !important;
                }

                .previous-btn-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    // border-right: 1px solid #C9C9C9;
                    padding-right: 20px;
                    gap: 10px;

                    &:hover {
                        .previous-btn-arrow {
                            border: $secondary-color solid 1px;

                            svg {
                                fill: $secondary-color;
                            }
                        }

                        .text-wrapper {
                            .text {
                                color: $secondary-color;
                            }
                        }
                    }

                    div {
                        &:last-of-type {
                            @include mediaquery(md, max) {
                                display: none;
                            }
                        }
                    }

                    .previous-btn-arrow {
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.19);
                        border-radius: 50%;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            height: 15px;
                            width: 15px;
                        }
                    }

                    .text-wrapper {
                        display: flex;
                        /* align-items: center; */
                        justify-content: center;
                        flex-direction: column;

                        .previous-btn,
                        .text {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 23px;
                            color: #000000;
                        }

                        .text {
                            color: #666666;
                            padding-left: 10px;
                            color: #666666;
                            padding-left: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .center-navigation {
                @include mediaquery(md, max) {
                    width: 100% !important;
                }

                .text-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .component-name,
                    .steps {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;

                        color: #000000;

                        @include mediaquery(md, max) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }

                .progress-bar {

                    // height: 20px;
                    // @include mediaquery(md, max) {
                    //     height: unset;
                    // }
                    .css-5xe99f-MuiLinearProgress-bar1 {
                        background-color: $secondary-color !important;
                    }

                    .css-eglki6-MuiLinearProgress-root {
                        background-color: rgba(51, 87, 255, 0.4)
                    }
                }
            }

            .right-navigation {
                // border-left: 1px solid #C9C9C9;
                padding-left: 20px;
                cursor: pointer;

                @include mediaquery(md, max) {
                    width: unset !important;
                }

                .btn-primary-after-login {
                    float: right;
                }

                .next-btn-wrapper {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    gap: 15px;

                    &:hover {
                        .next-icon {
                            border: $secondary-color solid 1px;

                            svg {
                                fill: $secondary-color;
                            }
                        }

                        div {
                            .text {
                                color: $secondary-color;
                            }
                        }
                    }

                    div {
                        &:first-of-type {
                            @include mediaquery(md, max) {
                                display: none;
                            }
                        }
                    }

                    .next-btn,
                    .text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 23px;
                        color: #000000;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: nowrap;
                    }

                    .text {
                        color: #666666;
                    }

                    .next-icon {
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.19);
                        border-radius: 50%;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            height: 15px;
                            width: 15px;
                        }
                    }
                }


                button {
                    // width: 100%;
                    // text-transform: uppercase;
                    // height: 45px;
                }
            }
        }
    }

    .edit-product-section {
        display: flex;
        // align-items: center;
        padding: 0px 30px;
        margin-top: 30px;

        @include mediaquery(md, max) {
            padding: 0px 0px;
            margin-top: 0;
        }

        .dynamic-container-height {
            height: calc(100vh - 283px);
            overflow-y: scroll;
            padding-right: 20px;

            @include mediaquery(md, max) {
                height: unset;
                overflow-y: unset;
            }
        }

        

        .left-panel-wrapper {
            flex: 1 1 60%;
            padding-right: 40px;
            margin-right: 40px;


            @include mediaquery(md, max) {
                flex: 1 1 100%;
                margin-right: 0;
                padding-right: 0;
                width: 100%;
            }

            
        }
    }

    .right-panel-wrapper {
        flex: 1 1 30%;

        .heading-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            align-items: center;
            gap: 5px;

            svg {
                position: relative;
                top: -2px;
            }

            .heading {
                font-weight: 500;
                font-size: 22px;
                line-height: 24px;
                color: #000000;
            }

            .view-link {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                // text-decoration-line: underline;
                cursor: pointer;
                color: $secondary-color;
            }
        }

        .product-view-container {
            .product-images-wrapper {
                height: 375px;

                .product-image-wrap {
                    img {
                        width: 372px;
                        height: 372px;
                        object-fit: contain;
                    }
                }

                .product-image-slider {
                    .slider-wrapper {
                        .chevron-wrap {
                            zoom: 80%;

                            .btn-prev,
                            .btn-next {
                                padding-left: 0;
                                width: 46px;
                                height: 46px;
                                padding: 0;
                                border-radius: 50%;

                                .prev-arrow,
                                .next-arrow {
                                    svg {
                                        display: flex;
                                        justify-content: center;
                                        margin: 0;
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        transform: scale(0.5);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-preview-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $secondary-color;
            margin: 40px;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }
        }

        .need-help-wrapper {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 25px;

            .icon-wrap {
                background: rgba(67, 97, 238, 0.3);
                opacity: 0.8;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 40px;
            }

            .need-help-text {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 20px;
            }

            .guide-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #262626;
                opacity: 0.7;
                width: 30%;
            }

            .more-infor-text {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                // text-decoration-line: underline;
                cursor: pointer;
                color: $secondary-color;
                margin: 25px 0 35px 0;
            }
        }
    }

    .fixed-footer {
        position: fixed;
        width: 100%;
        padding: 11px 0 !important;
        left: 0;
        bottom: 0;
        background: #fff;
        margin-bottom: 0 !important;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        z-index: -1;

        @include mediaquery(md, max) {
            position: unset;
            padding: unset !important;
            left: unset !important;
            bottom: unset !important;
            border-top: unset;
        }
    }

    .for-previous-btn {
        padding-left: 50px !important;
    }

    .disclaimer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        padding: 0px 30px;
        padding-left: 0 !important;

        @include mediaquery(md, max) {
            padding: unset !important;
            gap: 20px;
            margin-bottom: 20px !important;
            flex-direction: column;
            margin-top: 20px;
        }

        .text {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #313E42;
            // padding-left: 50px;

            @include mediaquery(md, max) {
                padding-left: unset;
                text-align: center;
            }
        }

        

        .save-btn-wrapper {

            // padding-right: 100px;
            &:first-of-type {
                padding-left: 50px !important;
            }

            &:last-of-type {
                padding-right: 50px !important;
                @include mediaquery(md, max) {
                    padding-right: 0 !important;
                }
            }

            @include mediaquery(md, max) {
                padding-right: unset;
                zoom: 70%;
            }

            button {
                // padding: 10px 40px;
                // text-transform: uppercase;
                // height: 35px;
                // width: 80px;
            }

            .btn-secondary-after-login {
                padding: 5px 27px !important;
                text-transform: capitalize;
            }

            .btn-primary-back-btn {
                border: 1px solid #97A8B8;
                border-radius: 5px;
                font-weight: 600;
                color: #97A8B8;
                margin-right: 30px;
                width: auto;
                text-transform: capitalize;
                font-family: $font-inter;

                @include mediaquery(md, max) {
                    margin-right: 10px;
                }
            }

            .save-and-exit-btn {
                width: auto;
                margin-right: 30px;

                @include mediaquery(md, max) {
                    font-size: 14px;
                    margin-right: 10px;
                }

            }

            .save-and-continue-btn {
                width: auto;

                @include mediaquery(md, max) {
                    font-size: 14px;
                }
            }
        }

        .footer-buttons {
            @include mediaquery(md, max) {
                display: flex;
                gap: 20px;
            }
            button {
                @include mediaquery(md, max) {
                    padding: 5px 22px !important;
                }
            }
            .save-btn-wrapper {
                @include mediaquery(md, max) {
                    padding: 0 !important;
                }
                .btn-secondary-after-login {
                    @include mediaquery(md, max) {
                        font-size: 23px !important;
                    }   
                }

               
            }
            .save-and-exit-btn {
                @include mediaquery(md, max) {
                    margin: 0 !important;
                    font-size: 14px !important;
                }
            }
        }
    }

    .image-upload-wrapper {

        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: 100vh;
            left: 0;
            position: fixed;
            right: 0;
            z-index: 6;
            transition: 0.5s;
        }

        .image-upload-sidenav {
            height: calc(100%);
            width: 0;
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            background-color: #FFFFFF;
            overflow-x: hidden;
            transition: 0.5s;

            &.show {
                width: 60%;

                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            &.hide {
                width: 0%;
            }

            #imageupload {
                position: absolute;
            }

            .image-upload {
                padding: 20px 20px 0 20px;

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .text {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        color: #262626;
                    }

                    .close-icon {
                        svg {
                            fill: #C4C4C4;
                            height: 35px;
                            width: 35px;
                            cursor: pointer;

                            &:hover {
                                fill: red;
                            }
                        }
                    }
                }

                .divider {
                    opacity: 0.2;
                    border: 1px solid #000000;
                    margin: 20px 0 40px 0;
                }

                .drop-your-image-wrap {
                    height: 340px;
                    background: #F3F3F4;
                    border: 1.5px dashed #979797;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &:hover {
                        border: 1.5px dashed $secondary-color;

                        svg {
                            path {
                                fill: $secondary-color;
                            }
                        }

                        .text {
                            color: $secondary-color;
                        }
                    }

                    .drop-image {
                        background: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        height: 100px;
                        width: 100px;
                        cursor: pointer;
                    }

                    .text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: #AAAAAA;
                        margin-top: 20px;
                    }


                    &.highlight-upload-section {
                        border: 2px solid rgb(75, 150, 251);
                        opacity: 0.5;
                    }
                }

                .image-upload-success {
                    .image-info-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 20px 0;

                        .image-info {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: calc(100% - 25px);

                            @include mediaquery(md, max) {
                                justify-content: flex-start;
                            }

                            .name {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 26px;
                                color: #000000;
                                word-break: break-all;
                            }

                            .size {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 26px;
                                color: #AAAAAA;
                                white-space: nowrap;
                                margin-left: 15px;
                            }
                        }

                        .close-icon {
                            width: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            @include mediaquery(md, max) {
                                flex: 1 1 0%;
                            }

                            svg {
                                fill: #C4C4C4;
                                height: 25px;
                                width: 25px;
                                cursor: pointer;
                            }

                            &:hover {
                                svg {
                                    fill: red;
                                }
                            }
                        }
                    }
                }

                .product-upload-information-wrapper {
                    .text {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;
                        // text-transform: uppercase;
                        margin: 20px 0 0 0;
                    }

                    .description {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 26px;
                        color: #000000;

                        ul {
                            list-style-type: disc;
                            padding-left: 13px;
                            margin-top: 5px;

                            li {
                                // margin: 10px 0;
                            }
                        }
                    }
                }

                .button-wrapper {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    margin-bottom: 50px;

                    button {
                        width: 100px;
                    }
                }
            }
        }

    }

    .setting-wrapper {
        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: auto;
            left: 0;
            position: fixed;
            right: 0;
            z-index: 6;
            transition: 0.5s;
            top: 0;
        }

        .setting-sidenav {
            height: calc(100%);
            width: 0;
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            background-color: #FFFFFF;
            overflow-x: hidden;
            transition: 0.5s;

            &.show {
                width: 33%;

                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            &.hide {
                width: 0%;

                .setting-wrap {
                    .button-wrapper {
                        overflow: hidden;
                        width: 0%;
                    }
                }
            }

            .setting-wrap {
                padding: 30px 20px 0;

                .heading-wrap {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;

                    .heading {
                        color: #262626;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 29px;
                    }

                    .close-icon {
                        svg {
                            fill: #c4c4c4;
                            cursor: pointer;
                            height: 35px;
                            width: 35px;
                        }
                    }
                }

                .divider {
                    opacity: 0.2;
                    border: 1px solid #000000;
                    margin: 20px 0 0 0;

                    @include mediaquery(md, max) {
                        display: block;
                    }
                }

                .setting {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-column-gap: 50px;
                    // @include mediaquery(md, max) {
                    // grid-template-columns: repeat(1, 1fr);
                    overflow-y: scroll;
                    height: calc(100vh - 226px);
                    // }
                    @include mediaquery(md, max) {
                        height: calc(100vh - 300px);
                    }

                    div {
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        label {
                            color: #000;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 26px;
                            margin-right: 10px;
                        }
                    }
                }

                .button-wrapper {
                    border-top: #cccccc solid 1px;
                    width: calc(33% - 40px);
                    padding-top: 18px;
                    background: #fff;
                    transition: 0.5s;

                    @include mediaquery(md, max) {
                        width: calc(100% - 40px);
                    }
                }
            }
        }
    }
}
.product-details-wrapper {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .form-control {
        margin-top: 28px;

        &.nutritional-label-section-wrap {
            position: relative;

            .remove {
                position: absolute;
                left: 168px;
                top: 8px;
                cursor: pointer;
                z-index: 2;
                background: #fff;
                height: 25px;
                zoom: 80%;

                &:hover {
                    svg {
                        fill: red;
                    }
                }
            }

            .preview-img {
                position: relative;
                width: 160px;
                .edit-icon {
                    position: absolute;
                    right: -15px;
                    bottom: -11px;
                    cursor: pointer;
                    &:hover {
                        svg {
                            circle {
                                fill: #fff;
                                stroke: #f1f1f1;
                            }

                            g {
                                path {
                                    fill: $secondary-color;
                                    
                                }
                            }
                        }
                    }
                }

                img {
                    width: 160px;
                    height: 160px;
                    border-radius: 5px;
                }
            }
        }

        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 5px;

            &.headline {
                font-weight: 700;
                font-size: 18px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 5px;

            }
        }

        .input-wrap {
            width: 100%;

            input {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #080C29;

                &.Mui-disabled {
                    opacity: 1;
                    background: #F3F3F4 !important;
                    color: rgba(0, 0, 0, 0.38);
                }
            }
        }

        .add-image-wrapper {
            margin-top: 25px;
            display: flex;

            @include mediaquery(md, max) {
                overflow-x: scroll;
                overflow-y: hidden;
            }

            .add-image-wrap {
                background: #F3F3F4;
                border: 1.5px dashed #979797;
                border-radius: 5px;
                width: 250px;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #AAAAAA;
                cursor: pointer;
                flex-direction: column;

                @include mediaquery(md, max) {
                    white-space: nowrap;
                }

                .img-info {
                    width: 250px;
                }

                &:hover {
                    border: 1.5px dashed $secondary-color;
                }
            }

            .added-image-wrap {
                display: flex;
                position: relative;

                .default-radio {
                    position: absolute;

                    .primary {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: $secondary-color;
                    }

                    .Mui-checked {
                        svg {
                            &:first-of-type {
                                background: #c6d1fa;
                                border-radius: 50%;

                                path {
                                    display: none;
                                }
                            }
                        }
                    }

                    svg {
                        &:first-of-type {
                            stroke: white;
                            fill: white;

                            path {

                                fill: #000;
                            }
                        }
                    }
                }

                .image {
                    width: 250px;
                    height: 250px;
                    margin-right: 20px;

                    img {
                        width: 250px;
                        height: 250px;
                        // object-fit: contain;
                    }
                }

                .remove {
                    position: absolute;
                    right: 10%;
                    top: 3%;
                    cursor: pointer;
                    background: #fff;
                    width: 24px;
                    height: 24px;

                    // zoom: 80%;
                    svg {
                        fill: #666766;

                        @include mediaquery(md, max) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &:hover {
                        svg {
                            fill: red;
                        }
                    }

                    @include mediaquery(md, max) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }



        .information-lables {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;

            .count-info {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }

            .link-info {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                // text-decoration-line: underline;
                cursor: pointer;
                color: $secondary-color;
            }
        }

        .chips-list {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;

            .chips {
                // width: 100px;
                // height: 29px;
                background: lighten($secondary-color, 30%) !important;
                border-radius: 17.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 17px;
                color: $secondary-color;
                // opacity: 0.65;
                margin-right: 10px;
                padding: 5px 7px 5px 11px;
                border: $secondary-color dashed 1px;
                gap: 5px;

                .remove {
                    display: flex;
                    margin-left: 5px;
                    zoom: 65%;

                    svg {
                        height: 17px;
                        width: 17px;
                        cursor: pointer;
                        background: $secondary-color;
                        border-radius: 50%;
                        padding: 3px;
                        stroke: #fff;
                        fill: #fff;
                        border: $secondary-color solid 1px;
                    }

                    &:hover {
                        svg {
                            background: #fff;
                            fill: red;
                            stroke: red;
                            border: red solid 1px;
                        }
                    }
                }
            }
        }

        &.packsize-wrapper {
            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
            }

            .packsize-wrap {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 30px;

                @include mediaquery(md, max) {
                    display: block;
                }

                >div {
                    // @include mediaquery(md, max) {
                    //     margin-bottom: 15px;
                    // }

                }

                .measlist-dropdown {
                    display: grid;
                    grid-template-columns: 35% 65%;

                    #measlist-select {
                        margin-top: 8px;
                        border-radius: 4px 0px 0px 4px;
                        height: 47px;
                        padding: 2px 15px;
                        font-size: 14px;
                    }

                    .measlist-input {
                        border-radius: 0;

                        input {
                            border-radius: 0 5px 5px 0;
                        }
                    }
                }

                .inner-pack-wrap,
                .outer-pack-wrap,
                .weight-volume-wrap {
                    flex: 1 1 33%;
                }
            }
        }

        &.barcode-itemcode-wrapper {
            // display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
                gap: 25px;
            }

            .barcode-wrap {
                display: grid;
                grid-template-columns: 15% 85%;

                @include mediaquery(md, max) {
                    grid-template-columns: 30% 70%;
                }

                #barcode-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .barcode-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;

                        &:focus-visible,
                        &:active {
                            outline: 0px;
                        }
                    }
                }
            }
        }

        &.product-percase-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            @include mediaquery(md, max) { 
                display: block;
            }   

            .productpercase-wrap {
                @include mediaquery(md, max) {
                    margin-bottom: 20px;
                }
            }
        }

        &.price-msrp-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
                gap: 25px;
            }

            .price-wrap {
                display: grid;
                grid-template-columns: 30% 35% 35%;

                #price-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .price-input,
                .item-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }

            .MSRP-wrap {
                display: grid;
                grid-template-columns: 30% 70%;

                #MSRP-select {
                    margin-top: 8px;
                    border-radius: 4px 0px 0px 4px;
                    height: 47px;
                    padding: 2px 10px;
                    font-size: 14px;
                }

                .MSRP-input {
                    border-radius: 0;

                    input {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }

        &.product-dimension-wrapper {
            .dimension-wrap {
                display: grid;
                grid-template-columns: 15% 28.3% 28.3% 28.3%;

                @include mediaquery(md, max) {
                    grid-template-columns: 100%;
                }

                .pro-dimension {
                    @include mediaquery(md, max) {
                        margin-bottom: 10px;
                    }
                }

                input {
                    @include mediaquery(md, max) {
                        padding-right: 0 !important;
                        padding-left: 5px !important;
                    }
                    &::placeholder {
                        @include mediaquery(md, max) {
                            font-size: 13px;
                        }
                    }
                }

                .width-input,
                .depth-input,
                .height-input {
                    margin: 0;
                }
            }
        }

        .product-volume-weight-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;

            @include mediaquery(md, max) {
                display: block;
            }

            .product-volume-wrap,
            .product-weight-wrap {
                margin: 0;

                @include mediaquery(md, max) {
                    margin: 35px 0 0 0;
                }

                .volumn-wrap {
                    display: grid;
                    grid-template-columns: 30% 70%;

                    .input-wrap {
                        margin: 0;
                    }

                }
            }
        }

        &.country-wrapper {
            div {
                margin: 0;
                width: 100%;
                background: #F3F3F4;
                border-radius: 6px;
                font-size: 14px;
            }

            .MuiInputBase-formControl {
                fieldset {
                    border-right-width: 1px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    legend {
                        display: none !important;
                    }
                }
            }

            // select {
            //     background: #F3F3F4;
            //     border-radius: 6px;
            //     font-size: 14px;
            // }
        }

        .autocomplete-wrapper {
            position: relative;
            width: 100%;

            .separator {
                border-top: 1px solid #5f6368;
                width: 95%;
                text-align: center;
                display: block;
                margin: auto;
            }

            .autocomplete {
                background: #F4F5F7;
                border: none;
                width: 100%;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                min-height: 150px;
                max-height: 200px;
                overflow: auto;
                position: absolute;
                display: block;
                margin: auto;
                z-index: 1;

                div {
                    margin: 10px 0;
                    padding: 5px 12px;

                    &:hover {
                        background: #dedede;
                        color: #333;
                        cursor: pointer;
                    }
                }

                .add-category-wrap {
                    display: flex;
                    align-items: center;
                    color: rgba(67, 97, 238);

                    svg {
                        margin-right: 10px;
                        fill: rgba(67, 97, 238);
                    }

                    &:hover {
                        color: rgba(67, 97, 238);
                    }
                }
            }
        }

        .nutritional-label-section-wrap {
            .logo-wrap {
                position: relative;
                width: 160px;
                height: 160px;

                .logo {
                    margin-top: 20px;
                    width: 160px;
                    height: 160px;
                    background: #F3F3F4;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(8, 12, 41, 0.4);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: #F3F3F4 solid 1px;

                    &:hover {
                        border: $secondary-color dashed 1px;
                    }
                }

                .edit-icon {
                    position: absolute;
                    bottom: -10%;
                    left: 35%;

                    @include mediaquery(md, max) {
                        left: unset;
                        right: -10px;
                    }

                    @include mediaquery(md) {
                        bottom: -6%;
                        left: 85%;
                    }
                }
            }

            div {
                cursor: pointer;

                img {
                    width: 160px;
                    height: 160px;
                    // object-fit: contain;
                }
            }
        }

        .logo-wrap {
            position: relative;
            width: 160px;
            height: 160px;

            .logo {
                margin-top: 20px;
                width: 160px;
                height: 160px;
                background: #F3F3F4;
                border-radius: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: rgba(8, 12, 41, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: #F3F3F4 solid 1px;

                &:hover {
                    border: $secondary-color dashed 1px;
                }
            }

            .edit-icon {
                position: absolute;
                bottom: -10%;
                left: 35%;
                cursor: pointer;
                &:hover {
                    svg {
                        circle {
                            fill: #fff;
                            stroke: #f1f1f1;
                        }

                        g {
                            path {
                                fill: $secondary-color;
                                
                            }
                        }
                    }
                }

                @include mediaquery(md, max) {
                    left: unset;
                    right: -10px;
                }

                @include mediaquery(md) {
                    bottom: -6%;
                    left: 85%;
                }
            }
        }

        select {
            &:focus-visible {
                outline: none;
                border: #3357FF solid 1px;
            }
        }
    }
}

.read-more-link{
    color: blueviolet;
    text-decoration: underline;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .extra-content{
    color: cornflowerblue;
    font-weight: 500;
  }

  .add-comments {
    .position-relative {
        [tooltip] {

            &::before,
            &::after {
                display: none;

                @include mediaquery(md, max) {
                    display: block;
                }
            }
        }
    }
    input {
        padding: 6px 35px 6px 10px !important;
        width: 100%;
        font-family: $font-inter;

        &::after {
            width: 150px;
            white-space: break-spaces;
            line-height: 12px;
            word-break: break-word;
        }

        &::placeholder {
            font-size: 12px;

            @include mediaquery(md, max) {
                color: #F3F3F4;
            }
        }
    }
  }

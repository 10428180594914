// css rules for ipad portrait
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {

    .my-brand-wrapper .brands-wrapper .brand-scroll-wrap {
        max-width: 83%;
    }
    .profile-wrapper {
        .right-panel {
            flex: unset !important;
            width: auto;
        }

        .left-panel {
            // display: none;
        }
    }
    .supplier-main-wrapper {
        .table-scroll-wrap {
                overflow-x: auto;
            table {
                    border-collapse: collapse;
                    border-spacing: 0 !important;
                    width: 300% !important;
            }
        }
    }
    .my-brand-wrapper {
        .brand-detail-wrapper, .brand-manage-section, .product-filter-section {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .only-desktop {
        // display: unset !important;
    }
    
    .company-profile-wrap {
        .header-section-wrapper {
            .heading-text-wrapper {
                .heading-text {
                    width: 85%;
                    line-height: 25px;
                    font-size: 20px;
                }
            }
        }
        .edit-product-section {
            .left-panel-wrapper {
                flex: unset !important;
                width: 100%;
                margin-right: 0;
            }
            .form-control {
                .add-image-wrapper {
                    overflow-x: scroll;
                    overflow-y: hidden;
                }
            }
        }
    }

    .my-brand-wrapper {
        .brands-wrapper {
            margin: 30px 0;
        }
    }
    

    .product-share-wrapper .popup-wrapper {
        width: 85%;
    }

    .catalogue-detail-wrapper {
        margin: 0;
    }

    .catalogue-on-hover-show-detail {
        table {
            
            tbody {
                tr {
                    td {
                        .button-status {
                            &.detail {
                                   visibility: visible;
                            }
                        }
                    }
    
                    &:hover {
                        td {
                            .button-status {
                                &.detail {
                                    visibility: visible;
                                }
                            }
                        }   
                    }
                }
            }
        }
    }

    .catalogues-wrapper {
        margin:0;
        .catalogue-filter-section {
            .filter-section {
                .product-add-btn {
                    .btn-primary-after-login {
                        &.only-desktop  {
                            display: block !important;
                        }
                    }
                }
            }
        }
        .edit-catalogue-wrapper {
            .edit-catalogue-slide {
                &.show  {
                    width: 80%;
                }
                .wrap {
                    .edit-catalogue-detail-wrap {
                        .search-wrap {
                            gap: 20px;
                            .search-input {
                                width: 350px;
                            }
                        }
                    }
                }
            }
        }
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list, .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        display: grid;
        grid-auto-flow: column;

        .product-card-wrapper {
            margin: 30px 30px 30px 10px;
        }
    }

    .web-catalogue-wrapper .profile-section .some-of-product-wrap {
        width: 100% !important;
    }

    .supplier-main-wrapper .custom-black-select-option select {
        background-position: right 5px top 20px;
    }

    // edit product delete img
    .company-profile-wrap .edit-product-section .left-panel-wrapper .form-control .add-image-wrapper .added-image-wrap .remove, .company-profile-wrap .edit-product-section .left-panel-wrapper .form-control .add-image-wrapper .added-image-wrap .remove svg {
        width: 20px;
        height: 20px;
    }
    .company-profile-wrap .edit-product-section .left-panel-wrapper .form-control .chips-list {
        margin-top: 5px;
    }

    // email verification
    .email-verification-container .container-wrapper .verification-email-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .email-verification-container .container-wrapper .verification-email-wrapper .verify-mail-wrapper .mail-deatils {
        margin-top: 40px;
    }

    .btn-primary-after-login {
        &.only-desktop {
            display: flex !important;
            gap: 4px;
        }
    }

    // catalogue details
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 100%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview .title {
        width: 40%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview .text {
        width: 60%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview {
        flex-direction: column;
        gap: 10px;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .pro-margin-bottom-20 {
        margin-bottom: 20px;
    }
    .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .pro-gap-20 {
        gap: 20px !important;
    }
    .catalogue-on-hover-show-detail table tbody tr td .button-status.detail {
        visibility: visible;
    }

    .mobile-setting .left-panel {
        width: auto !important;
    }

    .mobile-setting {
        flex-direction: column;
    }

    .supplier-main-wrapper .two-columns {
        gap: 10px !important;
    }
    .right-panel .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-left-inner-container {
        flex: 0 0 220px;
    }
    .right-panel .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 220px);
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .btn-preview {
        display: block !important;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .btn-preview {
        position: absolute;
        right: 79px;
        // bottom: 16px;
        text-decoration: none;
        display: flex !important;
        gap: 10px;
        justify-content: flex-end;

        bottom: 20px;
        // right: 35px;
    }
    .company-profile-wrap .edit-product-section {
        display: unset;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .product-label {
        padding: 0 15px;
    }

    .my-brand-wrapper .product-filter-section .filter-section .search-bar {
        width: 363px;
    }
    .my-brand-wrapper .profile-section-wrapper {
        margin: 0 10px;
    }

    .website-product-details-wrapper {
        padding: 0 20px !important;
    }
    .website-product-details-wrapper .overview-section-wrapper .product-info-wrapper .product-name {
        margin-top: 0 !important;
    }
    .website-product-details-wrapper .header-section-wrapper {
        margin-top: 20px !important;
    }
    .website-product-details-wrapper .header-section-wrapper .header-wrap {
        flex-direction: row !important;
        align-items: flex-start !important;
    }
    .website-product-details-wrapper .header-section-wrapper .header-wrap {
        width: 100%;
        gap: 10px;
    }
    .website-product-details-wrapper .header-section-wrapper .header-wrap .brand-image,
    .website-product-details-wrapper .header-section-wrapper .header-wrap .tagline-wrapper {
        margin-left: 0 !important;
    }
    .website-product-details-wrapper .overview-section-wrapper .product-info-wrapper .product-selling-item-wrapper {
        flex-direction: column;
        gap: 30px !important;
    }


    .my-brand-wrapper .profile-section-wrapper .form-input-control .socal-media-section {
        justify-content: space-between;
    }
    .my-brand-wrapper .profile-section-wrapper .form-input-control .brand-origin-section .social-links .form-profile-field {
        width: 100%;
    }

    .website-product-details-wrapper .overview-section-wrapper .product-info-wrapper .variant-dropdown select {
        margin-top: 10px !important;
        margin-bottom: 20px;
    }

    .catalogues-wrapper .catalogue-preview-wrapper .catalogue-preview-slide.show {
        width: 100%;
    }

    .web-catalogue-wrapper .product-card-wrapper .product-image .image-wrap img {
        width: 300px !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .product-card-wrapper, 
    .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards .product-card-wrapper {
        height: auto;
        margin: 30px;
        max-width: 300px;
        position: relative;
        width: 280px;
    
    }
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list,
    .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards {
        gap: 0 !important;
    }
    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .product-card-wrapper:first-of-type, 
    .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards .product-card-wrapper:first-of-type {
        margin-left: 0 !important;
    }

    .web-catalogue-wrapper .profile-section .some-of-product-wrap .product-cards .product-card-wrapper {
        // margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .web-catalogue-wrapper .product-card-wrapper:hover {
        box-shadow: unset;
        border-radius: unset;
    }

    .company-profile-wrap .setting-wrapper .setting-sidenav.show {
        width: 70%;
    }

    .catalogues-wrapper .edit-catalogue-wrapper .edit-catalogue-slide .wrap .bottom-wrap {
        width: calc(80% - 40px);
    }

    .website-product-details-wrapper .box-container {
        width: 100% !important;
    }

    .website-product-details-wrapper .overview-section-wrapper, .website-product-details-wrapper .product-details-wrapper .right-div .product-two-col, .website-product-details-wrapper .technical-specifiction-wrapper .right-div .product-two-col, .website-product-details-wrapper .certifications-wrapper .right-div .product-two-col, .website-product-details-wrapper .distribution-wrapper .right-div .product-two-col, .website-product-details-wrapper .marketing-wrapper .right-div .product-two-col {
        flex-direction: column !important;
        flex-wrap: unset !important;
    }

    .company-profile-wrap .heading-wrap .progress-bar-wrapper .left-navigation .previous-btn-wrapper div:last-of-type {
        display: none;
    }
    .company-profile-wrap .heading-wrap .progress-bar-wrapper .right-navigation .next-btn-wrapper div:first-of-type {
        display: none;
    }

    .disclaimer-wrapper .only-desktop {
        display: block !important;
    }

    .company-profile-wrap .header-section-wrapper .back-button-wrapper .text {
        display: none;
    }

    .company-profile-wrap .disclaimer-wrapper {
        zoom: 68%;
        z-index: 999999999;
    }

    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .button-wrapper {
        width: calc(73% - 40px);
    }

    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .setting {
        height: calc(100vh - 268px);
    }

    .website-product-details-wrapper .product-details-wrapper .right-div .product-two-col .distri-col, .website-product-details-wrapper .technical-specifiction-wrapper .right-div .product-two-col .distri-col, .website-product-details-wrapper .certifications-wrapper .right-div .product-two-col .distri-col, .website-product-details-wrapper .distribution-wrapper .right-div .product-two-col .distri-col, .website-product-details-wrapper .marketing-wrapper .right-div .product-two-col .distri-col {
        flex-direction: row !important;
        flex-wrap: wrap !important;
    }

    .website-product-details-wrapper .manage-section .option-wrapper {
        width: 870px;
    }

    .website-product-details-wrapper .manage-section {
        overflow-x: scroll;
    }

    .website-product-details-wrapper .header-section-wrapper .header-wrap .tagline-wrapper {
        width: 100% !important;
    }
    .website-product-details-wrapper .header-section-wrapper .header-wrap .tagline-wrapper .name .text {
        font-size: 22px !important;
    }

    .product-marketing-wrapper .planogram-section-wrapper .image-upload-wrapper .upload-wrapper .image-upload-wrap1 {
        flex-basis: 186px;
    }

    .product-marketing-wrapper .planogram-section-wrapper .image-upload-wrapper .upload-wrapper .form-control {
        flex-basis: 100%;
    }

    .product-preview-wrapper .form-control.packsize-wrapper .packsize-wrap {
        grid-template-columns: repeat(2, 1fr);
    }

    .website-product-details-wrapper .overview-section-wrapper .product-info-wrapper .product-selling-item-wrapper {
        margin-top: 0 !important;
    }

    // .catalogue-buyer-wrapper .left-navigation {
    //     display: none;
    // }
    .catalogue-buyer-wrapper .table-scroll-wrap {
        overflow-x: auto;
    }
    .catalogue-buyer-wrapper .table-scroll-wrap table {
        border-collapse: collapse;
        border-spacing: 0 !important;
        width: 300% !important;
    }
    .catalogue-buyer-wrapper .right-navigation {
        border-left-width: 0 !important;
    }

    .register-container .container-wrapper .register-wrapper .register-inner_container .alert-message-component-wrap {
        left: 50%;
        top: 8%;
    }

    .register-container .container-wrapper .register-wrapper {
        flex-basis: 80%;
    }
    .register-container .container-wrapper .registration__right-panel {
        flex-basis: 20%;
    }
    .register-container header {
        width: 80%;
    }
    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper:first-of-type {
        width: 20%;
    }
    .company-profile-wrap .disclaimer-wrapper .text {
        width: 30%;
        font-size: 12px;
        line-height: 16px;
    }
    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper:last-of-type {
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
    .product-marketing-wrapper .planogram-section-wrapper .image-upload-wrapper .upload-wrapper .image-upload-wrap .skip-btn {
        width: 94px;
    }

    .img-certificate .image-view-popup {
        width: 60%;
    }

    .process-video--wrapper .popup-wrapper, .create-brand-wrapper .popup-wrapper {
        width: 80%;
    }

    .product-table table tbody .catalogue-name .brand-verified-icon {
        padding-left: 15px;
    }
    .main-wrapper.onboarding-container .container-wrapper {
        max-width: 600px !important;
    }
    .main-wrapper.onboarding-container {
        height: calc(100vh - 116px);
    }

    .our-team-wrapper .add-our-team-popup-wrapper .add-our-team-popup-description {
        display: block;
    }
    .our-team-wrapper .add-our-team-popup-wrapper .add-our-team-popup-description .role-description-wrapper {
        border-left-width: 0;
        margin-bottom: 20px;
    }
    .web-catalogue-wrapper .products-section .search-section {
        gap: 20px !important;
    }

    .right-panel .setting-section-wrapper .setting-wrap .button-wrapper {
        width: calc(100% - 40px) !important;
    }
    .setting-wrap .setting {
        overflow-y: scroll;
        height: 240px;
    }

    .my-brand-wrapper .profile-section-wrapper .form-input-control .social-media-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .my-brand-wrapper .upload-cover-wrapper, .my-brand-wrapper .upload-cover-wrapper .cover-image-wrapper {
        // height: auto !important;
    }

    // .my-brand-wrapper .upload-cover-wrapper .cover-image-wrapper {
    //     background-position: top left !IMPORTANT;
    //     background-repeat: no-repeat!important;
    //     background-size: unset !important;
    // }

    .my-brand-wrapper .upload-cover-wrapper .cover-image-wrapper .edit-cover-image {
        visibility:  visible !important;
    }

    .my-brand-wrapper .profile-section-wrapper .form-input-control .brand-origin-section .social-links .label svg {
        padding-right: 10px;
    }

    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container .phone-country-wrapper {
        flex-direction: column;
    }

    .web-catalogue-wrapper .products-section .search-section .search-icon {
        margin-left: 10px;
    }
    .web-catalogue-wrapper .profile-section .information-wrap .info-section {
        margin-top: 0 !important;
    }

    .main-wrapper.onboarding-container, .main-wrapper.buyer-onboarding-container {
        padding-bottom: 0;
    }

    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container .title-email-wrapper .form-control .MuiFormControl-root {
        margin-top: 0 !important;
    }

    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container div {
        margin-bottom: 0;
    }

    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container .name-wrapper,
    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container .title-email-wrapper {
        margin-bottom: 10px !important;
    }

    .alert-message-component-wrap .message-wrapper .message-text {
        word-break: keep-all;
    }

    .change-password-wrapper .password-inner-container form .password-items .flex-justify-content-space-between .show-label .flex-items-center .login-password__hide-show {
        margin-left: 10px;
    }

    .image-viewer-wrapper .image-view-popup {
        width: 55%;
    }
    .profile-section-wrapper .profile-inner-container form .profile-form-wrapper .profile-right-inner-container .phone-country-wrapper {
        gap: 8px;
    }

    .catalogues-wrapper .catalogue-preview-wrapper .catalogue-preview-slide .wrap .footer-catalogue-exit .exit-preview-wrapper {
        flex-direction: row;
        padding-right: 0;
        margin-right: 20px;
    }
    .catalogues-wrapper .catalogue-preview-wrapper .catalogue-preview-slide .wrap .exit-preview-wrapper {
        flex-direction: row-reverse;
        padding-right: 20px;
    }
    .catalogue-buyer-wrapper .left-navigation {
        flex: 1 1 25% !important;
    }

    .onboarding-container .onboarding-component-wrap select {
        text-indent: 10px !important;
    }

    .catalogues-wrapper .edit-catalogue-wrapper .edit-catalogue-slide .wrap .bottom-wrap {
        width: calc(80% - 45px);
    }

    .product-preview-wrapper .form-control.country-wrapper {
        margin-bottom: 64px !important;
    }

    .supplier-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .supplier-main-wrapper .for-three-field:hover div:nth-child(3) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .buyer-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .buyer-main-wrapper .for-three-field:hover div:nth-child(3) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline {
        border-right: -1px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .supplier-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .buyer-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline {
        border-right: -1px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px!important;
    }

    .supplier-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .supplier-main-wrapper .for-three-field:hover div:nth-child(3) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .buyer-main-wrapper .for-three-field:hover div:nth-child(2) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline, .buyer-main-wrapper .for-three-field:hover div:nth-child(3) .input-wrap .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline {
        // border-right: 1 !important;
    }

    .catalogue-buyer-wrapper .right-navigation .verify-email-notification .alert-message-component-wrap {
        top: 12% !important;
    }

    .catalogues-wrapper .catalogue-sorting-popup-wrapper .popup-wrap, .catalogues-wrapper .catalogue-product-sorting-popup-wrapper .popup-wrap {
        min-height: 54% !important;
        height: 54% !important;
    }
}


// css rules for ipad landscape
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .profile-wrapper {
        .right-panel {
            width: 100%;
        }
    }
    .right-panel-wrapper {
        display: none;
    }

    .catalogues-wrapper {
        margin:0;
        .catalogue-filter-section {
            .filter-section {
                .product-add-btn {
                    .btn-primary-after-login {
                        &.only-desktop  {
                            display: block !important;
                        }
                    }
                }
            }
        }
        .edit-catalogue-wrapper {
            .edit-catalogue-slide {
                &.show  {
                    width: 80%;
                }
                .wrap {
                    .edit-catalogue-detail-wrap {
                        .search-wrap {
                            gap: 20px;
                            .search-input {
                                width: 500px;
                            }
                        }
                    }
                }
            }
        }
    }

    .web-catalogue-wrapper  {
        overflow:hidden;
        .products-section .product-list-wrapper .category .product-list {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            display: grid;
            grid-auto-flow: column;
        }
    }

    // catalogue details
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 70%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview .title {
        width: 40%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview .text {
        width: 60%;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .product-overview {
        flex-direction: column;
        gap: 10px;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .pro-margin-bottom-20 {
        margin-bottom: 20px;
    }
    .contact-analytics-wrapper .contact-analytics-slide .wrap .contact-analytics-overview .pro-gap-20 {
        gap: 20px !important;
    }
    .catalogue-on-hover-show-detail table tbody tr td .button-status.detail {
        visibility: visible;
    }
    .supplier-main-wrapper .table-scroll-wrap {
        overflow-x: auto;
    }
    .supplier-main-wrapper .table-scroll-wrap table {
        border-collapse: collapse;
        border-spacing: 0 !important;
        width: 300% !important;
    }

    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .btn-preview {
        display: block !important;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .btn-preview {
        bottom: 34px;
        right: 35px;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .product-label {
        padding: 0 15px;
    }

    .catalogues-wrapper .edit-catalogue-wrapper .edit-catalogue-slide.show {
        width: 70% !important;
    }
}



 
  /* Media query for iPad 8 (10.2-inch screen) in portrait mode */
@media only screen and (min-device-width: 768px) and (max-device-width: 810px) and (orientation: portrait) {
    /* Your CSS rules for the iPad 8 in portrait mode go here */

    .website-product-details-wrapper .box .box-containr .box-inner-details .box-inner-sub-details .box-column-wrap .box-col-one {
        grid-template-columns:100% !important;
    }
    .website-product-details-wrapper .overview-section-wrapper {
        display: block !important;
    }
    .website-product-details-wrapper .box-container {
        width: 100% !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        grid-template-columns: repeat(2,1fr) !important;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list .pro-items-cards {
        zoom: 90%;
    }

    .website-product-details-wrapper .manage-section .option-wrapper .option {
        font-size: 16px !important;
    }

    .buyer-main-wrapper .table-scroll-wrap, .supplier-main-wrapper .table-scroll-wrap {
        overflow-x: scroll;
    }
    .buyer-main-wrapper .table-scroll-wrap table, .supplier-main-wrapper .table-scroll-wrap table {
        border-collapse: collapse;
    border-spacing: 0!important;
    width: 200%!important;
    }

    .only-mobile {
        display: block !important;
    }
    .catalogue-detail-wrapper .contact-analytics-wrapper .contact-analytics-slide.show {
        width: 90%;
    }

    .catalogues-wrapper .edit-catalogue-wrapper .edit-catalogue-slide.show {
        width: 70% !important;
    }

    .catalogues-wrapper .catalogue-preview-wrapper.show-overlay {
        z-index: 9999;
    }

    .company-profile-wrap .disclaimer-wrapper {
        z-index: 9;
    }

    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper:last-of-type {
        display: flex;
    }
    .company-profile-wrap .disclaimer-wrapper .text {
        padding: 0 50px;
        flex-basis: 35%;
    }

    .company-profile-wrap .edit-product-section .dynamic-container-height {
        height: calc(100vh - 330px) !important;
    }
    .web-catalogue-wrapper .preview-header-wrapper .logo-wrap {
        padding-right: 50px;
    }
    .my-brand-wrapper .brands-wrapper {
        margin-left: 10px !important;
    }
    .image-viewer-wrapper .image-view-popup {
        width: calc(70% - 100px)!important;
    }

    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper {
        zoom: 40%;
    }
    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper .save-and-exit-btn {
        height: 80px;
    }

    .mobile-setting {
        flex-direction: column;
    }
    .mobile-setting .left-panel {
        width: auto !important;
    }
    .profile-wrapper .right-panel {
        width: 100%;
    }
    .supplier-main-wrapper .table-scroll-wrap table {
        // width: 93% !important;s
    }
    .profile-wrapper .right-panel {
        width: 93% !important;
    }

    .web-catalogue-wrapper .product-card-wrapper .card-body .cn-title-wrap .product-name .ellipsis-for-2-line {
        display: unset;
        height: unset !important;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: unset;
        text-overflow: unset;
        word-break: break-word;
    }
    [tooltip]:not([flow]):hover::before, [tooltip]:not([flow]):hover::after, [tooltip][flow^=up]:hover::before, [tooltip][flow^=up]:hover::after, [tooltip][flow^=down]:hover::before, [tooltip][flow^=down]:hover::after {
        display: none !important;
    }

    .company-profile-wrap .header-section-wrapper .back-button-wrapper .back-icon {
        margin-right: 0 !important;
    }
    .company-profile-wrap .header-section-wrapper .back-button-wrapper .text {
        display: none !important;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper {
        width: 65% !important;
    }
    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .btn-preview {
        right: 70px;
        top: 20px;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav.show {
        width: 70%;
    }

    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .setting {
        height: calc(100vh - 326px);
    }

    .setting-wrap .button-wrapper {
        bottom: 70px;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .button-wrapper {
        width: calc(70% - 40px);
    }
    .catalogue-on-hover-show-detail table tbody tr td .button-status.detail {
        visibility: visible !important;
    }

    .my-brand-wrapper .product-filter-section .filter-section .search-bar {
        width: 55% !important;
    }

    .company-profile-wrap .header-section-wrapper .heading-text-wrapper .heading-text {
        font-size: 17px !important;
    }
    .register-container .container-wrapper .registration__right-panel {
        flex-basis: 0%;
    }
    .register-container .container-wrapper .register-wrapper {
        flex-basis: 100%;
    }
    .register-container .container-wrapper .register-wrapper .register-inner_container {
        width: 80%;
    }
    .register-container header {
        width: 100%;
    }
    .register-container .container-wrapper .register-wrapper .register-inner_container .alert-message-component-wrap {
        left: 50%;
        top: 8%;
    }
    .company-profile-wrap .heading-wrap .progress-bar-wrapper .center-navigation {
        // zoom: 30%;
    }
    .our-team-wrapper .add-our-team-wrap button {
        display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: right;
    min-width: auto;
    padding: 5px 9px 5px 9px;

        &:first-of-type {
            display: none !important;
        }
    }

    .product-share-wrapper .popup-wrapper {
        width: 80%;
    }

    .our-team-wrapper .add-our-team-wrap svg {
        margin-right: 0 !important;
    }
}


// Ipad Landscape mode
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .catalogues-wrapper .edit-catalogue-wrapper .edit-catalogue-slide.show {
        width: 70% !important;
    }
    .buyer-main-wrapper .table-scroll-wrap, .supplier-main-wrapper .table-scroll-wrap {
        overflow-x: scroll;
    }
    .buyer-main-wrapper .table-scroll-wrap table, .supplier-main-wrapper .table-scroll-wrap table {
        border-collapse: collapse;
    border-spacing: 0!important;
    width: 200%!important;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav.show {
        width: 70%;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .setting {
        height: calc(100vh - 246px);
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .button-wrapper {
        width: calc(70% - 40px);
    }

    .company-profile-wrap .disclaimer-wrapper {
        background-color: #fff;
        z-index: 0;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .setting {
        height: calc(100vh - 326px);
    }

    .setting-wrap .button-wrapper {
        bottom: 70px;
    }
    .company-profile-wrap .setting-wrapper .setting-sidenav .setting-wrap .button-wrapper {
        width: calc(70% - 40px);
    }
    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper:last-of-type {
        zoom: 50%;
    }

    .web-catalogue-wrapper .products-section .product-list-wrapper .category .product-list {
        grid-template-columns: repeat(3,1fr) !important;
    }
    .web-catalogue-wrapper .product-card-wrapper .card-body .cn-title-wrap .product-name .ellipsis-for-2-line {
        display: unset;
        height: unset !important;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: unset;
        text-overflow: unset;
        word-break: break-word;
    }
    .catalogue-on-hover-show-detail table tbody tr td .button-status.detail {
        visibility: visible !important;
    }

    .company-profile-wrap .edit-product-section .dynamic-container-height {
        height: calc(100vh - 400px);
    }
    .company-profile-wrap .edit-product-section .right-panel-wrapper {
        height: calc(100vh - 300px);
    }
    .product-preview-wrapper .form-control .label {
        line-height: 25px;
    }

    .company-profile-wrap .disclaimer-wrapper .save-btn-wrapper .save-and-exit-btn {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    

}
.change-password-wrapper {
    .heading {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #262626;

        @include mediaquery(md, max) {
            font-size: 16px;
        }
    }

    .divider {
        margin-top: 20px;
        opacity: 0.1;
        border: 1px solid #000000;
    }

    .password-inner-container {
        form {
            margin-top: 30px;

            .password-items {
                width: 366px;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                >div {
                    // margin-bottom: 10px;
                }
            }

            input {
                background: #f3f3f4;
                border-radius: 5px;
                color: $primary-color;
                padding-top: 5px;
                width: calc(366px - 28px);

                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            .name-wrapper {
                // width: 30% !important;
                display: block;
                margin-bottom: 28px;
                margin-top: 10px;
                position: relative;

                @include mediaquery(md, max) {
                    width: 100% !important;
                }

                .fname-wrapper {
                    .form-profile-field {
                        margin-top: 0;

                        @include mediaquery(md, max) {
                            width: 100%;
                        }
                    }

                    .field-err-msg {
                        margin-top: 5px;
                    }

                    @include mediaquery(md) {
                        margin-right: 50px;
                    }
                }
            }

            .password-wrapper {
                position: relative;
                z-index: 0;
                transition: all .5s;
                background: #fff;
                // width: 40% !important;
                margin-bottom: 23px;

                @include mediaquery(md, max) {
                    width: 100% !important;
                }

                @include mediaquery(md) {
                    //   margin-top: 10px;
                }

                &.height-160 {
                    height: 160px;
                    transition: all .5s;
                }

                .show-label {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: $primary-color;
                    cursor: pointer;

                    @include mediaquery(md) {
                        font-size: 14px;
                    }
                }

                .password-validation {
                    transition: all 1s;
                    height: 80px;
                    left: 0;
                    padding: 0 10px;
                    position: absolute;
                    transition: all .5s;
                    width: 366px;
                    z-index: -1;
                    transform: translateY(-75%);
                    visibility: hidden;
                    background-color: #fff;

                    &.show {
                        transform: translateY(0%);
                        visibility: visible;
                        transition: all .5s;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #696969;
                        display: flex;

                        svg {
                            height: 25px;
                            width: 25px;
                        }
                    }
                }
            }

            .button-section {

                float: right;
                align-items: right;
                justify-content: space-between;
                width: 100%;

                .button-wrapper {

                    align-items: center;
                    justify-content: flex-end;
                    margin: 40px 0 20px 0;
                    float: right;

                    .save-btn {
                        background: $secondary-color;
                        border-radius: 5px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        padding: 12px 40px;
                    }
                }
            }
        }
    }
}
.invite-reset-password-confirm-component-wrap {
  position: relative;

  div {
    width: 100%;
  }

  // .first-heading {
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 32px;
  //   line-height: 39px;
  //   text-align: center;
  //   color: $primary-color;
  //   margin: 20px 0;
  // }

  // .second-heading {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 18px;
  //   line-height: 20px;
  //   text-align: center;
  //   color: $primary-color;
  //   margin-bottom: 50px;
  //   width: 100%;

  //   @include mediaquery(md) {
  //     width: 50%;
  //     margin: auto;
  //   }
  // }

  form {
    margin-top: 20px;
    width: 100%;

    @include mediaquery(md) {
      width: 50%;
      margin: auto;
      margin-top: 20px;
    }

    div {
      input {
        border: none;
      }
    }

  }

  input {
    background: #f3f3f4;
    border-radius: 5px;
    border: none;
  }

  // ::placeholder {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 17px;
  //   color: $primary-color;
  //   opacity: 0.4;
  // }

  .submit-btn {
    mix-blend-mode: normal;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .label-wrap,
  .login-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $primary-color;
    margin-bottom: 20px;
  }

  .login-label {
    color: rgba(67, 97, 238, 0.9);
    margin-left: 5px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .password-wrapper {
    position: relative;
    // margin-bottom: 28px;
    // height: 90px;
    z-index: 2;
    transition: all .5s;
    background: #fff;

    @include mediaquery(md) {
      margin-top: 10px;
    }

    &.height-160 {
      height: 160px;
      transition: all .5s;
    }

    .show-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $primary-color;
      cursor: pointer;

      @include mediaquery(md) {
        font-size: 14px;
      }
    }

    .password-validation {
      transition: all 1s;
      height: 80px;
      left: 0;
      padding: 0 10px;
      position: absolute;
      transition: all .5s;
      width: 100%;
      z-index: -1;
      transform: translateY(-75%);
      visibility: hidden;
      background-color: #fff;

      &.show {
        transform: translateY(0%);
        visibility: visible;
        transition: all .5s;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #696969;
        display: flex;

        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .password-reset-success-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 74%;
    margin: 0 auto;

    .first-heading {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {

      margin-top: 50px;
      // height: 100px;
      // width: 100px;
    }

    .success-text {
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: $primary-color;
      margin-top: 30px;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $primary-color;
      margin-top: 30px;
    }

    .login-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-decoration-line: none;
      color: rgba(67, 97, 238, 0.9);
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .alert-message-component-wrap {
    .message-wrapper {
      .close-icon {
        left: 90%;
      }
    }
  }
}
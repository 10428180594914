.product-certification-wrapper {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 30px;
    }

    .certification-form {
        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #686868;
        }

        .country-wrapper {
            div {
                margin: 0;
                width: 100%;
                background: #F3F3F4;
                border-radius: 6px;
                font-size: 14px;

                .MuiInputBase-colorPrimary {
                    &:hover {
                        fieldset {
                            // border-right-width: 1px;
                            // border-top-right-radius: 5px;
                            // border-bottom-right-radius: 5px;
                        }
                    }
                }
            }

            fieldset {
                top: 0px !important;

                legend {
                    display: none !important;
                }
            }
        }

        .certification-wrap,
        .other-certification-wrap {
            background: #FFFFFF;
            border: 1px solid #E6E6E6;
            box-shadow: 0px 0px 50px rgba(89, 107, 198, 0.05);
            border-radius: 10px;
            // min-height: 300px;
            padding: 25px 20px;

            .certification-of-origin-image-upload-wrap {
                padding-left: 40px;

                @include mediaquery(md, max) {
                    padding-left: unset;
                }

                .image-upload-wrap {
                    background: #F3F3F4;
                    border: 1px dashed #C0C0C0;
                    border-radius: 5px;
                    height: auto;
                    // cursor: pointer;
                    position: relative;

                    &:hover {
                        border: $secondary-color dashed 1px;
                    }

                    img {
                        height: auto;
                        width: 100%;
                        // object-fit: cover;
                    }

                    .text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: #AAAAAA;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 200px;
                        cursor: pointer;
                    }

                    .pdf-view {
                        height: 200px;
                        width: 100%;
                    }
                }
            }

        }

        .other-certification-wrap {
            margin-top: 30px;

            .certi-form-ctrl {
                margin-bottom: 0px !important;
            }

            .other-country-multiselect-wrap {
                width: 100%;
                margin: 0;
                width: 100%;
                background: #F3F3F4;
                border-radius: 6px;
                font-size: 14px;

                .MuiInputBase-colorPrimary {
                    &:hover {
                        fieldset {
                            // border-right-width: 1px;
                            // border-top-right-radius: 5px;
                            // border-bottom-right-radius: 5px;
                        }
                    }
                }

                .MuiChip-root {
                    background: rgba(105, 129, 241, 0.3);
                    border: 1px dashed $secondary-color;
                    border-radius: 20px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 22px;
                    color: $secondary-color;
                    width: auto;

                    &:hover {
                        background: #fff;
                    }

                    svg {
                        fill: $secondary-color;

                        &:hover {
                            fill: red;
                        }
                    }
                }
            }

            .other-certification--upload-wrap {
                margin-top: 30px;

                .country-label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #686868;
                    mix-blend-mode: normal;
                }

                .other-image-upload {
                    margin-top: 20px;
                    padding-left: 40px;

                    @include mediaquery(md, max) {
                        padding-left: unset;
                    }

                    .header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .text {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #686868;
                        }

                        .remove-text {
                            font-weight: 500;
                            font-size: 14px;
                            display: flex;
                            color: #AAAAAA;
                            cursor: pointer;

                            &:hover {
                                svg {
                                    fill: red;
                                }
                            }
                        }
                    }

                    .image-upload {
                        margin-top: 10px;
                        background: #F3F3F4;
                        border: 1px dashed #C0C0C0;
                        border-radius: 5px;
                        height: auto;
                        position: relative;

                        &:hover {
                            border: $secondary-color dashed 1px;
                        }

                        .text {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #AAAAAA;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 150px;
                            cursor: pointer;
                        }

                        img {
                            height: auto;
                            width: 100%;
                            // object-fit: cover;
                            // cursor: pointer;
                        }
                    }

                    .pdf-view {
                        height: 150px;
                        width: 100%;
                    }

                }
            }
        }
    }

    .image-upload-icons {
        position: absolute;
        right: 1%;
        display: flex;
        top: 3%;
        // visibility: hidden;

        .edit-icon {
            cursor: pointer;

            &:hover {
                svg {
                    circle {
                        fill: #333;
                    }
                }
            }
        }

        .delete-icon {
            background-color: rgb(51, 87, 255);
            border-radius: 50%;
            border: 2px solid #fff;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;

            svg {
                fill: #fff;
            }

            &:hover {
                background-color: #333;

                svg {
                    fill: #fff;
                }
            }
        }

    }
}
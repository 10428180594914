
.buyer-profile-wrapper {
    display: flex;
    margin: 0 -24px;
    height: calc(100vh - 79px);
    margin-top: 59px;
    .left-panel {
        width: 20%;
        background: #E7F0F8;
        padding: 20px;
        @include mediaquery(md, max) {
            padding: 5px 20px;
        }
    }

    .right-panel {
        width: 80%;
        overflow-y: scroll;
        @include mediaquery(md, max) {
            flex: unset;
            width: auto;
            overflow-x: hidden;
            padding-top: 0;
        }
    }
}
.product-technical-specification {
    .heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .techincal-specification-form {
        // background: #FFFFFF;
        // border: 1px solid #E6E6E6;
        // box-shadow: 0px 0px 50px rgba(89, 107, 198, 0.05);
        // border-radius: 10px;
        // min-height: 300px;
        // padding: 25px 20px;

        // display: grid;
        // grid-template-columns: repeat(2,1fr);
        // grid-column-gap: 30px;
        @include mediaquery(md, max) {
            display: block;
        }

        .techhnical-fields-wrap {
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            @include mediaquery(md, max) {
                grid-template-columns: repeat(1, 1fr);
            }
    
        }

        div {
            &:first-of-type {
                // grid-column-end: 3;
                // grid-column-start: 1;
                // grid-row-end: 1;
                // grid-row-start: 1;
            }
        }

        .label {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #686868;

        }

        .industry-wrapper,
        .textfield-wrapper,
        .select-box-wrapper {
            div {
                width: 100%;
                margin: 0;
                background: #F3F3F4;
                border-radius: 5px;
                // padding-right: 30px !important;

                .MuiChip-root {
                    background: rgba(105, 129, 241, 0.3);
                    border: 1px dashed $secondary-color;
                    border-radius: 20px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 22px;
                    color: $secondary-color;
                    width: auto;
                }
            }

            .MuiSelect-select {
                padding: 11px 14px !important;
                padding-right: 30px !important;

                em {
                    font-style: inherit;
                    font-size: 14px;
                    color: rgba(8, 12, 41, 0.4);

                    &::placeholder {
                        color: rgba(8, 12, 41, 0.4);
                    }
                }
            }
        }

        .multiselect-wrapper {
            div {
                
                margin: 0;
                background: #F3F3F4;
                // border-radius: 5px;
                // padding-right: 30px !important;
                @include mediaquery(md) {
                    width: 100%;
                }

                .MuiChip-root {
                    background: rgba(105, 129, 241, 0.3);
                    border: 1px dashed $secondary-color;
                    border-radius: 20px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 22px;
                    color: $secondary-color;
                    width: auto;
                }
            }

            .MuiSelect-select {
                padding: 11px 14px !important;
                padding-right: 30px !important;

                em {
                    font-style: inherit;
                    font-size: 14px;
                    color: rgba(8, 12, 41, 0.4);

                    &::placeholder {
                        color: rgba(8, 12, 41, 0.4);
                    }
                }
            }
        }

        .multiselect-wrapper {
            .MuiSelect-select {
                padding-right: 30px !important;
            }

            label {
                top: -5px;
            }
        }

        .mutltiselect-with-textfield-wrapper {
            .multiselect-with-textfield-wrap {
                display: grid;
                grid-template-columns: 30% 70%;

                .input-wrap {
                    width: 100%;
                    border-radius: 0 5px 5px 0;
                    padding: 0;
                    margin: 0;

                    input {
                        height: 23px;
                    }
                }
            }
        }
    }
}
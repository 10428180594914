
.loader-component-wrap {
    .loader-center {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(255, 255, 255, 0.75);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .three-bounce-spinner  {
            margin: 100px auto 0;
            width: 70px;
            text-align: center;
          
            >div {
                width: 18px;
                height: 18px;
                //background-color: #44378f;
        
                border-radius: 100%;
                display: inline-block;
                -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
                animation: bouncedelay 1.4s infinite ease-in-out both;
            }
            
            .bounce1 {
                -webkit-animation-delay: -0.32s;
                animation-delay: -0.32s;
            }
            
            .bounce2 {
                -webkit-animation-delay: -0.16s;
                animation-delay: -0.16s;
            }
            
            @-webkit-keyframes bouncedelay {
                0%, 80%, 100% { -webkit-transform: scale(0) }
                40% { -webkit-transform: scale(1.0) }
            }
            
            @keyframes bouncedelay {
                0%, 80%, 100% { 
                    -webkit-transform: scale(0);
                    transform: scale(0);
                } 40% { 
                    -webkit-transform: scale(1.0);
                    transform: scale(1.0);
                }
            }
        }
    }
}
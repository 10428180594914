.catalogue-detail-wrapper {
    margin: 0 50px;

    @include mediaquery(md, max) {
        margin: 30px 0;
    }

    .divider {
        opacity: 0.1;
        border: 1px solid #000000;
        margin: 20px 0 11px 0;

        @include mediaquery(md, max) {
            display: block;
        }
    }

    .custom-divider {
        margin: 14px 0 11px 0;
    }

    .back-btn-wrap {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: rgba(38, 38, 38, 0.7);
        display: flex;
        align-items: center;
        margin: 30px 0;

        span {
            cursor: pointer;
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }

            &:hover {
                svg {
                    fill: $secondary-color;
                }

                color: $secondary-color;
            }
        }


    }

    .contacts-manage-section {
        margin: 40px 0px 25px 0px;

        @include mediaquery(md, max) {
            margin: 50px 0px;
            border-bottom: #e6e6e6 solid 1px;
            padding-bottom: 21px;
        }

        .option-wrapper {
            display: flex;
            align-items: center;

            .option {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                cursor: pointer;

                @include mediaquery(md) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-right: 35px;
                    display: flex;
                    width: 120px
                }

                @include hover-underline-animation(#000);

                &.selected {
                    &::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &::after {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 4px;
                    bottom: -21px;
                }
            }
        }
    }

    .catalogue-information-section {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include mediaquery(md, max) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }

        .information-wrap {
            display: flex;

            @include mediaquery(md, max) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .img-section {
                background: #D9D9D9;
                border-radius: 10px;
                width: 175px;
                height: 175px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 25px;

                @include mediaquery(md, max) {
                    margin-right: 0;
                }

                svg {
                    height: 30px;
                    width: 30px;
                }
            }

            .info-wrap {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .heading-text {
                    display: flex;

                    @include mediaquery(md, max) {
                        justify-content: center;
                    }

                    .text {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 25px;
                        color: #080C29;

                        @include mediaquery(md, max) {
                            font-size: 20px;
                        }
                    }
                }

                .pricing-wrap {
                    background: #FFFFFF;
                    border: 1px solid #D4D4D4;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 20px 0;
                    width: 250px;

                    .pricing-text {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;

                        @include mediaquery(md, max) {
                            font-size: 14px;
                        }
                    }

                    .pricing-switch {
                        background: #F5F5F5;
                        border-radius: 0px 5px 5px 0px;

                        .MuiSwitch-sizeMedium {
                            .MuiSwitch-colorPrimary {
                                &.Mui-checked {
                                    color: $secondary-color;

                                }
                            }
                        }
                    }
                }

                .view-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .label {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        display: flex;
                        align-items: center;
                        color: #080C29;
                    }

                    .value {
                        font-size: 18px;
                        margin-top: 10px;
                        color: $secondary-color;
                        font-weight: 700;
                    }
                }
            }
        }

        .catalogues-share-btn {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            color: #FFFFFF;
            width: 173px;
            height: 50px;
            white-space: nowrap;
        }
    }

    .contacts-list-section {


        .filter-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mediaquery(md, max) {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin-top: 40px;
                gap: 15px;
            }

            .text {
                display: flex;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #000000;
            }

            .search-wrap {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                .search-input {
                    position: relative;
                    border-radius: 5px;
                    // width: 200px;

                    @include mediaquery(md) {
                        // width: 400px;
                        margin: 0;
                    }

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    ::placeholder {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                        color: #262626;
                        mix-blend-mode: normal;
                        opacity: 0.7;
                    }

                    >div {
                        // padding-left: 25px;
                    }

                    input {
                        padding: 6px 30px 6px 14px !important;
                        width: 85%;
                        font-family: $font-inter;
                    }
                }

                .search-contact-input {
                    input {
                        width: 300px;

                        &::placeholder {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: #262626;
                            mix-blend-mode: normal;
                            opacity: 0.7;
                        }
                    }

                }

                .close-icon {
                    position: absolute;
                    top: 57%;
                    transform: translate(-50%, -50%);
                    right: 55px;
                    cursor: pointer;
                    zoom: 82%;
                }

                .search-icon-wrap {
                    background: #000000;
                    border-radius: 5px;
                    height: 36px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0 0 10px;
                    cursor: pointer;
                    border: #000 solid 1px;

                    svg {

                        // fill: #FFFFFF;
                        path {
                            stroke: #fff;
                        }
                    }

                    &:hover {
                        background: #fff;
                        border: #000 solid 1px;

                        svg {
                            path {
                                stroke: #000;
                            }
                        }
                    }

                    &:focus {
                        box-shadow: 0px 0px 0 3px rgb(211, 210, 210) !important;
                        transition: opacity .25s, box-shadow .25s
                    }
                }
            }
        }

        .cat-detail {
            table {
                tbody {
                    tr {
                        td {
                            div {
                                .tooltip-wrapper {
                                    top: 70px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .catalogue-contacts-table {
            display: block;
            position: relative;
            // min-height: 460px;
            margin-top: 25px;
            min-height: 460px;
            margin-bottom: 80px;

            .tooltip-wrapper {
                right: 16px;
                top: 120px;
            }

            .loader-center {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
            }


            table {
                @include mediaquery(md, max) {
                    width: 400% !important;
                }
            }

            table {
                border-spacing: 0;
                width: 100%;

                thead {
                    background: #080C29;

                    th {
                        // font-weight: 600;
                        // font-size: 16px;
                        // color: #FFFFFF;
                        // text-align: left;
                        // padding: 20px 0;

                        @include mediaquery(md, max) {
                            // font-size: 14px;
                        }

                        // &:nth-child(1) {
                        //     border-top-left-radius: 10px;
                        // }

                        // &:nth-last-child(1) {
                        //     border-top-right-radius: 10px;
                        // }

                        // &.catalogue {
                        //     width: 30%;
                        //     padding-left: 40px;
                        // }

                        // &.contacts-id,
                        // .products,
                        // .created-date,
                        // .last-date,
                        // .more-option {
                        //     width: 15%;
                        // }

                        // &.more-option {
                        //     color: transparent;
                        // }
                    }
                }

                tbody {
                    tr {
                        // &.odd {
                        //     background: #FFFFFF;
                        // }

                        // &.even {
                        //     background: #F6F7FE;
                        // }

                        td {
                            // border-top: 1px solid #DDDDDD;
                            // border-bottom: 1px solid #DDDDDD;
                            // padding: 18px 0 17px 0;
                            // height: 40px;
                        }

                        // &.has-variant {
                        //     td {
                        //         border-bottom: none;
                        //     }
                        // }

                        // &.product-list-main-variant {
                        //     td {
                        //         border: none;

                        //         .product-name {
                        //             font-weight: 400;
                        //         }
                        //     }
                        // }

                    }

                    .catalogue-name {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: #262626;
                        // margin-left: 40px;
                        display: flex;
                        // flex-direction: column;
                        margin-left: 15px;
                        gap: 15px;
                        display: flex;
                        align-items: center;

                        .contact-name {
                            font-weight: 600;
                            word-break: break-all;
                        }

                        .contact-email {
                            font-weight: 400;
                            color: rgba(104, 104, 104, 0.7);
                            width: 200px;
                            word-break: break-all;

                            @include mediaquery(md, max) {
                                width: 150px;
                            }
                        }

                        .contact-wrap {
                            display: flex;
                            flex-direction: column;
                            width: calc(100% - 63px);
                        }

                        .master-cat-alisis {
                            padding: 15px 13px;
                            border: #c4c4c4 solid 1px;
                            border-radius: 5px;

                            @include mediaquery(md, max) {
                                zoom: 60%;
                            }
                        }

                    }

                    .cust-button-brand {
                        cursor: pointer !important;
                        text-align: center;
                        text-transform: uppercase;
                        color: $secondary-color;
                        background: rgba(67, 97, 238, 0.3);
                        border: 1px solid $secondary-color;
                        border-radius: 5px;
                    }

                    .contacts {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #262626;
                    }

                    .product-count {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #262626;
                    }

                    .share-btn {
                        div {
                            border: 1px solid #D7D7D7;
                            border-radius: 2px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #000000;
                            width: 70px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-transform: capitalize;
                            cursor: pointer;
                        }
                    }

                    .status {
                        border: 1px solid #EA4E2C;
                        border-radius: 2px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #FFD8CD;
                        padding: 5px 25px;
                    }

                    .options {
                        div {
                            // display: flex;
                            // align-items: center;
                            // justify-content: flex-end;
                            // margin-right: 30px;

                            .edit {
                                border: 1px solid #D7D7D7;
                                border-radius: 2px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #000000;
                                padding: 7px 20px;
                                margin-right: 20px;
                            }

                            .more-options-wrapper {
                                position: relative;
                                margin-right: 20px;
                            }
                        }
                    }

                    .draft {
                        cursor: pointer;
                    }

                }
            }

            .no-catalogue-found {
                background-color: #F1F1F1;
                min-height: 460px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .icon-wrap {
                    width: 200px;
                    height: 200px;
                    background: #FFFFFF;
                    border: 2px dashed #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 100px;
                        width: 100px;
                    }
                }

                .text {
                    margin: 30px 0;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #262626;

                    @include mediaquery(md, max) {
                        font-size: 16px;
                    }
                }
            }

        }
    }

    .contact-analytics-wrapper {

        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: 100vh;
            left: 0;
            position: fixed;
            right: 0;
            transition: .5s;
            z-index: 6;
        }

        .contact-analytics-slide {
            background-color: #fff;
            height: calc(100%);
            overflow-x: hidden;
            position: fixed;
            right: 0;
            top: 0px;
            transition: .5s;
            width: 0;
            z-index: 1;
            transition: 0.5s;

            &.show {
                width: 60%;

                @include mediaquery(md, max) {
                    width: 100%;
                    zoom: 80%;
                }
            }

            &.hide {
                width: 0%;
            }

            .wrap {
                padding: 30px 20px 0;
                position: relative;
                height: calc(100% - 60px);

                .head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        color: #262626;
                        word-break: break-all;

                        @include mediaquery(md, max) {
                            font-size: 18px;
                        }
                    }

                    .close-icon {
                        cursor: pointer;

                        svg {
                            height: 30px;
                            width: 30px;
                            fill: #C4C4C4;
                        }

                        &:hover {
                            svg {
                                fill: red;
                            }
                        }
                    }
                }

                .divider {
                    border: 1px solid #000000;
                }

                .contact-analytics-overview {

                    align-items: center;
                    justify-content: space-between;

                    .pro-gap-20 {
                        @include mediaquery(md, max) {
                            gap: 20px !important;
                        }
                    }

                    .pro-margin-bottom-20 {
                        @include mediaquery(md, max) {
                            margin-bottom: 20px;
                        }
                    }

                    .product-overview {
                        display: flex;
                        padding: 10px 10px 10px 0px;

                        @include mediaquery(md, max) {
                            flex-direction: column;
                            gap: 10px;
                        }

                        .title {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 55%;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            color: #262626;

                            @include mediaquery(md, max) {
                                width: 100%;
                            }
                        }

                        .product-area {
                            width: 55%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;

                            @include mediaquery(md, max) {
                                width: 100%;
                            }

                            .product-img {
                                img {
                                    width: 100px;
                                    height: 100px;
                                }
                            }

                            .pro-image {
                                width: 100px;
                                height: 100px;
                                background: #fff url(../../../../../src/assets/icons/table-default-icon.png) no-repeat;
                                border-radius: 10px;
                                background-size: 100%;
                                background-size: 80%;
                                background-position: center;

                            }

                            .product-details {
                                display: flex;
                                flex-direction: column;
                                gap: 15px;

                                h3 {
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 24px;
                                    color: #262626;
                                }

                                .product-title {
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 18px;
                                    line-height: 24px;
                                    word-break: break-all;
                                    padding-right: 20px;
                                }

                                .product-item-code {
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 18px;
                                    display: flex;
                                    flex-direction: column;

                                    span {
                                        cursor: default;
                                        &:first-of-type {
                                            color: #000;
                                        }

                                        &:last-of-type {
                                            color: #727272;
                                            ;
                                        }
                                    }

                                    .custom-tt-2 {
                                        cursor: default;
                                    }
                                }

                                &.title-wrap {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;
                                    flex-direction: row;

                                    .more-option-icon-wrapper {
                                        margin: 0 10px;
                                        position: relative;
                                        cursor: pointer;

                                        .more-option-icon {
                                            cursor: pointer;
                                        }

                                        .tooltip-element-filter-tooltip,
                                        .tooltip-element-action-tooltip {

                                            .tooltip-icon-filter-tooltip,
                                            .tooltip-icon-action-tooltip {
                                                display: flex !important;
                                                justify-content: center !important;
                                                align-items: center !important;
                                            }
                                        }

                                        &:hover {
                                            color: $secondary-color;

                                            div {
                                                div {
                                                    svg {
                                                        path {
                                                            fill: $secondary-color;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        svg {
                                            height: 25px;
                                            width: 25px;
                                            fill: #686868;
                                            transform: rotate(90deg);
                                            margin-left: 5px;

                                            @include mediaquery(md) {
                                                height: 30px;
                                                width: 30px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.title-section {
                                width: 100%;
                            }
                        }

                        .text {
                            display: inline-block;
                            width: 55%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-right: 0px;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 24px;
                            color: $secondary-color;
                            gap: 20px;

                            @include mediaquery(md, max) {
                                width: 100%;
                                justify-content: center;
                            }

                            .count {
                                padding: 5px;
                            }

                            .opens,
                            .time {
                                background: rgba(67, 97, 238, 0.1);
                                padding: 13px 20px;
                                border-radius: 45px;

                                @include mediaquery(md, max) {
                                    padding: 10px 20px;
                                }

                                .internal-text {
                                    color: #485465;
                                    font-weight: 400;
                                }
                            }
                        }

                        .item-start {
                            @include mediaquery(md, max) {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}
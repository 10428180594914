.setting-wrap {
    padding: 30px 20px 0;

    .heading-wrap {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .heading {
            color: #262626;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
        }

        .close-icon {
            svg {
                fill: #c4c4c4;
                cursor: pointer;
                height: 35px;
                width: 35px;
            }
            &:hover {
                svg {
                    fill: red !important;
                }
            }
        }
    }

    .divider {
        opacity: 0.2;
        border: 1px solid #000000;
        margin: 20px 0 0 0;
        @include mediaquery(md, max) {
            display: block;
        }
    }

    .setting {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        @include mediaquery(md, max) {
            grid-template-columns: repeat(1, 1fr);
            overflow-y: scroll;
            height: 270px;
        }

        div {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                margin-right: 10px;
            }
            .MuiSwitch-root {
                .MuiSwitch-colorPrimary {
                    padding: 0;
                    top: 9px;
                    left: 9px;
                }
            }

            input {
                left: unset;
                width: unset;
            }

            .MuiSwitch-colorPrimary {
                &.Mui-checked {
                    color: $secondary-color;
                }   
                &.Mui-checked+.MuiSwitch-track {
                    background-color: $secondary-color;
                }
            }
        }
    }
    .button-wrapper {
        margin-top: 20px;
        position: fixed;
        bottom: 10px;
        right: 15px;
        @include mediaquery(md, max) {
            bottom: 0;
            padding-bottom: 20px;
        }
        button {
            width: 80px;
        }
    }
}
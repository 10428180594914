.buyer-catalogue-container {
    .container-wrapper {
        @include mediaquery(md, max) {
            padding: 0 !important;
        }
    }
}

.catalogue-buyer-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 99px;


    @include mediaquery(md, max) {
        flex-direction: column;
    }

    .table-scroll-wrap {
        @include mediaquery(md, max) {
            overflow-x: auto;
        }

        table {
            @include mediaquery(md, max) {
                border-collapse: collapse;
                border-spacing: 0 !important;
                width: 400% !important;
            }
        }
    }

    .left-navigation {
        padding-top: 20px;
        flex: 1 1 15%;
        // display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        gap: 15px;
        height: calc(100vh - 40px);

        .left-nav-wrap {
            // margin-top: 40px;
        }

        @include mediaquery(md, max) {
            // flex: unset;
            width: 100%;
        }

        .heading {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #262626;
            margin-bottom: 20px;
        }

        .sub-menu {
            div {
                font-weight: 600;
                font-size: 14px;
                line-height: 25px;
                color: rgba(38, 38, 38, 0.8);
                // cursor: pointer;
                margin: 5px 0;

                .skip-btn {
                    color: unset;

                    &::after {
                        bottom: 4px;
                    }
                }

                .selected {
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    .right-navigation {
        padding: 20px 30px;
        flex: 1 1 85%;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        // height: calc(100vh - 40px);
        // overflow-y: scroll;
        padding-bottom: 100px;

        @include mediaquery(md, max) {
            // flex: unset;
            width: 100%;
            padding: 20px 0px;
        }

        .image-viewer-wrapper {
            .image-view-popup {
                @include mediaquery(md, max) {
                    width: 65% !important;
                }
            }
        }

        .heading {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            display: flex;
            align-items: center;
            color: #262626;
            // margin-top: 40px;

            @include mediaquery(md, max) {
                padding: 0 20px;
                margin-top: 0;
            }

        }

        .search-delete-wrap {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            @include mediaquery(md, max) {
                padding: 0 20px;
                gap: 10px;
            }

            .search-wrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 20px;
                // margin-bottom: 5px;
                width: 100%;

                .search-input-close {
                    position: relative;

                    .close-icon {
                        cursor: pointer;
                        position: absolute;
                        right: 5px;
                        top: 5px;
                    }
                }

                .search-input {
                    width: 400px;
                }

                .search-input {
                    position: relative;
                    border-radius: 5px;
                    width: 200px;
                    margin-top: 0;
                    margin-bottom: 0;

                    @include mediaquery(md) {
                        width: 400px;
                    }

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    ::placeholder {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                        color: #262626;
                        mix-blend-mode: normal;
                        opacity: 0.7;
                    }

                    >div {
                        padding-left: 10px;
                    }

                    input {
                        padding: 6px 25px 6px 5px !important;
                        width: 100%;
                        font-family: $font-inter;
                    }
                }
            }

            .delete-wrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                border: 1px solid #AAAAAA;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                @include mediaquery(md, max) {
                    padding: 7px 8px;
                    // margin-bottom: 5px;
                }

                >div {
                    display: flex;
                }

                &:hover {
                    border: red solid 1px;

                    svg {
                        stroke: red;
                    }
                }

                .text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: #AAAAAA;
                }
            }

            .search-icon-wrap {
                background: #000000;
                border-radius: 5px;
                height: 36px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 0 10px;
                cursor: pointer;
                border: #000 solid 1px;

                svg {
                    path {
                        stroke: #fff;
                    }
                }

                &:hover {
                    background: #fff;
                    border: #000 solid 1px;

                    svg {
                        path {
                            stroke: #000;
                        }
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0 3px rgb(211, 210, 210) !important;
                    transition: opacity .25s, box-shadow .25s
                }
            }
        }

        .catalogue-list-wrap {
            margin-top: 20px;

            @include mediaquery(md, max) {
                padding: 0 20px;
            }

            .buyer-catalgoue-list {
                td {
                    .product-name {
                        input[checkbox] {
                            flex-basis: 10%;
                        }
                        .pro-image-wrap {
                            flex-basis: 7%;
                        }
                        .cat-buy-pro-name {
                            flex-basis: 83%;
                        }
                    }
                }
                .brand-name {
                    width: 100%;
                    line-height: 30px;
                    line-height: 20px;
                }

                .save-date,
                .last-view {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #262626;
                    opacity: 0.5;
                }

                .button-status {
                    // background: rgba(51, 87, 255, 0.1);
                    // cursor: pointer;
                }

                .last-updated {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #262626;
                }

                .star-icon {

                    margin-right: 15px;

                    .tooltip-element-filter-tooltip,
                    .tooltip-element-action-tooltip {

                        .tooltip-icon-filter-tooltip,
                        .tooltip-icon-action-tooltip {
                            display: flex !important;
                            justify-content: center !important;
                            align-items: center !important;
                        }
                    }

                    svg {
                        height: 30px;
                        width: 30px;
                        // margin-right: 15px;
                        cursor: pointer;
                    }

                    .fill {
                        fill: #FFCD3C;
                    }

                    .default-star {
                        fill: #d4d4d4;

                        &:hover {
                            fill: #FFCD3C;
                        }
                    }
                }

                .more-options-wrapper {
                    svg {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }

        .bata-sodu {
            .product-list-main {
                .options {
                    .more-options-wrapper {
                        .tooltip-wrapper {
                            right: -4px;
                        }
                    }
                }
            }
        }

        .conformation-popup-wrapper {

            .popup-wrap {
                width: 500px;
                @include mediaquery(md, max) {
                    width: 70%;
                }
                .heading {
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        .verify-email-notification {
            display: flex;
            align-items: center;
            gap: 50px;
            // background-color: #fafafa;
            // border-radius: 3px;
            margin: 1rem 0px 0px;
            padding: 8px;
            // min-width: 48px;
            -webkit-box-align: baseline;
            word-break: break-word;
            position: fixed;
            left: 0;
            top: 60px;
            width: calc(100% - 4px);
            margin-top: 0;
            background: #FFD8CD;
            border: 2px solid #EA4E2C;
            text-align: center;
            justify-content: center;
            padding: 0 0;
            z-index: 3;

            button {
                font-size: 12px;
            }

            .alert-message-component-wrap {
                top: 16%;
                @include mediaquery(md, max) {
                    top: 14%;
                }
            }

            .email-notification-wrap {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    fill: #3357FF;
                }

                .text {
                    font-size: 1em;
                    line-height: 1.714;
                    font-weight: 500;
                    margin-bottom: 0px;
                    letter-spacing: -0.005em;
                }
            }
        }
    }
}
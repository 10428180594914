.accordion-wrapper {
    margin: 0 10px 30px 10px;

    .header-section {
        display: flex;
        align-items: center;
        cursor: pointer;
        @include mediaquery(md, max) {
            justify-content: space-between;
        }
        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            color: #313E42;
            @include mediaquery(md, max) {
                font-size: 16px;
            }
        }

        .header-icon {
            display: flex;
            align-items: center;

            svg {
                height: 25px;
                width: 25px;
            }
        }
    }

    .tabs-wrapper {
        background: #C7D5E1;
        border-radius: 10px;
        padding: 15px;
        margin-top: 10px;
        max-height: 200px;
        @include mediaquery(md, max) {
            margin-bottom: 15px;
        }
        .tab {
            font-weight: 500;
            font-size: 14px;
            color: #313E42;
            opacity: 0.5;
            padding: 10px;
            cursor: pointer;

            &.selected {
                background: #FFFFFF;
                border-radius: 3px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #313E42;
                opacity: 1;
                cursor: default;
            }
        }

        &.close {
            max-height: 0;
            padding: 0;
            transition: all 0.2s;
            height: 0;
        }
        &.open {
            padding: 15px;
            max-height: 150px;
            transition: all 0.2s;
            height: 150px;
        }
    }
}
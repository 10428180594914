.forgot-password-component-wrap {

  // .first-heading {
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 32px;
  //   line-height: 39px;
  //   text-align: center;
  //   color: $primary-color;
  //   margin: 60px 0 20px 0;
  //   font-family: $font-inter;
  //   @include mediaquery(md, max) {
  //     font-size: 26px;
  //     margin-top: 40px;
  //   }
  // }

  // .second-heading {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 15px;
  //   line-height: 20px;
  //   text-align: center;
  //   color: $primary-color;
  //   margin-bottom: 50px;
  //   width: 100%;
  //   font-family: $font-inter;

  //   @include mediaquery(md, max) {
  //     font-size: 16px;
  //     margin-bottom: 35px;
  //   }

  //   @include mediaquery(md) {
  //     width: 68%;
  //     margin: auto;
  //     line-height: 22px;
  //   }
  // }

  form {
    // margin-top: 20px;
    width: 100%;

    // .label {
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 14px;
    //   line-height: 20px;
    //   font-family: $font-inter;
    //   color: $primary-color;
    //   @include mediaquery (md, max) {
    //   }
    // }

    div {
      width: 100%;
      input {
        border: none;
      }
    }
    
    @include mediaquery(md) {
      // width: 80%;
      // margin: auto;
      // margin-top: 20px;
    }
  }

  // input {
  //   background: #f3f3f4;
  //   border-radius: 5px;
  //   border: none;
  // }
  // ::placeholder {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 17px;
  //   color: $primary-color;
  //   opacity: 0.4;
  // }

  .submit-btn {
    // mix-blend-mode: normal;
    // border-radius: 5px;
    // border: none;
    // box-shadow: none;
    // height: 50px;
    // margin-top: 10px;
    // margin-bottom: 20px;
  }

  .label-wrap,
  .login-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-color;
    margin-bottom: 20px;
    text-underline-position:under;
  }

  .label-wrap {
    padding-bottom: 50px;
  }

  .login-label {
    color: rgba(67, 97, 238, 0.9);
    margin-left: 5px;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: none;
    margin-top: 5px;
  }
}

.product-table {
    display: block;
    position: relative;
    // min-height: 460px;
    min-height: 460px;

    .loader-center {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    table {
        border-spacing: 0;
        width: 100%;
        border-collapse: collapse; 
        thead {
            background: #080C29;

            th {
                font-weight: 600;
                font-size: 14px;
                color: #FFFFFF;
                text-align: left;
                padding: 20px 0;
                font-family: $font-inter;
                @include mediaquery(md, max) {
                    font-size: 14px;
                }

                input[type=checkbox] {
                    margin: 0 15px 0 0;
                    height: 16px;
                    width: 16px;
                    // outline: 1px solid #FFFFFF;
                    border-radius: 3px;
                    cursor: pointer;
                    // transform: scale(1.1);
                }

                &:nth-child(1) {
                    border-top-left-radius: 5px;
                    padding-left: 25px;
                }

                &:nth-last-child(1) {
                    border-top-right-radius: 5px;
                }

                &.product {
                    width: 40%
                }

                &.seller-id,
                .variant,
                .status,
                .more-option {
                    width: 15%;
                }

                &.more-option {
                    color: white !important;
                }
            }
        }

        tbody {

            .catalogue-name {
                .name-alisis {
                    background: #BFC9FF;
                    width: 63px;
                    height: 60px;
                    border-radius: 6px;
                    color: #515D9F;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    letter-spacing: 0px;
                    @include mediaquery (md, max) {
                        width: 40px;
                        height: 40px;
                        font-size: 14px;
                    }
                }

                .brand-verified-icon {
                    width: calc(100% - 63px);
                }

                .master-cat-alisis {
                    svg {
                        @include mediaquery(md, max) {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            tr {
                border-top: 1px solid #DDDDDD;
                // border-bottom: 1px solid #DDDDDD;
                // background-color: #F1F1F1;
                &:last-of-type {
                    border-bottom: 1px solid #DDDDDD;
                }
                &.odd {
                    background: #FFFFFF;
                }

                &.even {
                    background: #F6F7FE;
                }

                &.parent-cat {
                    td {
                        background-color: #f1f1f1 !important;
                    }
                    
                }

                td {
                    // border-top: 1px solid #DDDDDD;
                    // border-bottom: 1px solid #DDDDDD;
                    padding: 18px 0 17px 0;
                    font-size: 14px;
                    &:nth-child(1) {
                        padding-left: 20px;
                        border-left: #dddddd solid 1px !important;
                    }
                    &:last-of-type {
                        border-right: #dddddd solid 1px !important;
                    }

                    &.c-date {
                        @include mediaquery(md, max) {
                            padding-right: 30px;
                        }
                    }
                    &.tabel-cell {
                        display: table-cell;
                    }
                }

                &.has-variant {
                    td {
                        border-bottom: none;
                    }
                }

                &.product-list-main-variant {
                    td {
                        border: none;

                        .product-name {
                            font-weight: 400;
                            width: 300px;
                            word-break: break-word;
                        }
                    }
                }

            }

            // .brand-verified-wrap {
            //     .brand-verified-icon {
            //         position: relative;
            //         &:after {
            //             content: "";
            //             background: url(../../../../assets/icons/brand-verified.svg) no-repeat !important;
            //             width: 18px;
            //             height: 18px;
            //             position: absolute;
            //             top: 0;
            //             right: -22px;
            //         }
            //     }
            // }

            .product-name {
                display: flex;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #262626;
                // margin-left: 10px;
                padding-right: 20px;
                align-items: center;

                input[type="checkbox"] {
                    display: flex;
                    margin-right: 10px;
                    // transform: scale(1.3);
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }

                .pro-image-wrap {
                    display: flex;
                    position: relative;
                    padding-right: 30px;
                    padding-left: 5px;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    .pro-image {
                        // margin-top: 20px;
                        width: 30px;
                        height: 30px;
                        background: #fff url(../../src/assets/icons/table-default-icon.png) no-repeat;
                        border-radius: 10px;
                        background-size: 100%;
                        background-size: 80%;
                        background-position: center;
                        // font-weight: 500;
                        // font-size: 14px;
                        // line-height: 17px;
                        // color: rgba(8, 12, 41, 0.4);
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        // cursor: pointer;
                    }
                }

                .brand-verified-icon {
                    width: calc(100% - 95px);
                    word-break: break-all;
                    width: 300px;
                    @include mediaquery(md, max) {
                        width: calc(100% - 95px);
                    }
                }
            }

            .product-draft-status {
                border: 1px solid $secondary-color;
                border-radius: 2px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: $secondary-color;
                padding: 5px 25px;
            }

            .sku-id {
                font-weight: 500;
                // font-size: 14px;
                line-height: 17px;
                color: #262626;
                display: unset;
                .custom-tt-2 {
                    display: unset;
                }
            }

            .brand {
                >span {
                    word-break: break-all;
                    padding-right: 20px;
                    width: 200px;
                }
            }

            .variant-count {
                font-weight: 500;
                // font-size: 14px;
                line-height: 17px;
                color: #262626;
            }

            .status {
                border: 1px solid #EA4E2C;
                border-radius: 2px;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #eb4d2c;
                padding: 5px 25px;
            }

            .share-btn {
                div {
                    border: 1px solid #D7D7D7;
                    border-radius: 2px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    width: 70px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }

            .icon-copy {
                // span {
                //     display: flex;
                    svg {
                        cursor: pointer;
                        // zoom: 140%;
                        -moz-transform: scale(.7);
                        transform: scale(.7);
                        path {
                            stroke: gray;
                        }
                        &:hover {
                                path {
                                    // stroke: $secondary-color;
                                    stroke: $secondary-color;
                                }
                        }
                    }

                    
                // }

                .to-remove-unwanted-space {
                    position: absolute;
                    width: 100%;
                    height: 21px;
                    bottom: 15px;
                    left: 0;
                }
            }

            .options {
                >div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: 30px;

                    .pre-cat {
                        // zoom: 70%;
                        padding-top: 10px;
                        margin-right: 23px;
                        svg {
                            cursor: pointer;
                            path {
                                fill: rgb(3 3 3);
                            }
                            &:hover {
                                path {
                                    fill:$primary-btn-color
                                }
                            }
                        }
                    }

                    .cat-lock-icon {
                        margin-right: 15px;
                        svg {
                            transform: scale(.7);
                            path {
                                fill: #ccc;
                            }
                        }
                    }

                    .icons-shadow {
                        margin-right: 15px;
                        // background: #FFFFFF;
                        // border: 1px solid #EAEAEA;
                        // box-shadow: 0px 0px 32px rgb(105 129 241 / 20%);
                        border-radius: 5px;
                        padding: 8px;
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        svg {
                            path {
                                stroke: gray;
                                fill: gray;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    stroke: $secondary-color;
                                    fill: $secondary-color;
                                }
                            }
                        }
                    }

                    .share {
                        width: 20px;
                        height: 20px;
                        svg {
                            width: 20px;
                            height: 20px;
                            path {
                                // fill: $secondary-color;
                                stroke: gray
                            }
                        }
                        &:hover {
                            svg {
                                path {
                                    stroke: $secondary-color;
                                }
                            }
                        }
                    }

                    .edit {
                        border: 1px solid #D7D7D7;
                        border-radius: 2px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                        padding: 7px 20px;
                        margin-right: 20px;
                        cursor: pointer;
                        font-family: $font-inter;
                        background: transparent;

                        &:hover {
                            background: #D7D7D7 !important;
                        }

                        &:focus {
                            box-shadow:0px 0px 0 3px rgb(239 239 240) !important;
                            transition:opacity .25s,box-shadow .25s
                        }
                    }

                    .edit-icon {
                        border: 0px solid #D7D7D7;
                        border-radius: 2px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                        // padding: 7px 0px;
                        // margin-right: 20px;
                        // margin-left: 20px;
                        margin: 7px 20px;
                        cursor: pointer;
                        font-family: $font-inter;
                        background: transparent;

                        svg {
                            &:hover {
                                path {
                                    fill: $primary-btn-color;
                                }
                            }
                        }
                    }

                    .more-options-wrapper {
                        position: relative;
                        margin-right: 20px;

                        svg {
                            zoom: 70%;
                        }

                    }
                }
            }

        }
    }

    .no-data-found {
        background-color: #F1F1F1;
        min-height: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1.5px solid #F1F1F1;

        &:hover {
            border: 1.5px solid $secondary-color;

            svg {
                path {
                    fill: $secondary-color;
                }
            }

            .text {
                color: $secondary-color;
            }
        }

        .icon-wrap {
            width: 200px;
            height: 200px;
            background: #FFFFFF;
            border: 2px dashed #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 100px;
                width: 100px;
            }
        }

        .text {
            margin: 30px 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #262626;

            @include mediaquery(md, max) {
                font-size: 16px;
                text-align: center;
                padding: 0 40px;
            }
        }

        .add-product {
            button {
                background: $secondary-color;
                border-radius: 5px;
                font-weight: 600;
                font-size: 16px;
                text-transform: capitalize;
                height: 50px;
                color: #FFFFFF;
            }
        }
    }
}


.catalogue-on-hover-show-detail {
    table {
        
        tbody {
            tr {
                td {
                    .button-status {
                        &.detail {
                           visibility: hidden;
                           
                           @include mediaquery(md, max) {
                               visibility: visible;
                               margin-right:10px;
                           }
                        }
                    }
                }

                &:hover {
                    td {
                        .button-status {
                            &.detail {
                                visibility: visible;
                            }
                        }
                    }   
                }
            }
        }
    }
}

.freeze-table-body-scroll {
    padding-bottom: 100px;
    thead {
    display:table;
    width:100%;
    table-layout:fixed;
    }
    tbody {
        display:block;
        // height:310px;
        overflow:auto;

        tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }
    }
}
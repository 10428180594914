.email-verification-container {
  .container-wrapper {
	  margin-top: 0 !important;
  .verification-email-wrapper {
    display: block;
    padding-bottom: 10px;

    @include mediaquery(md) {
      grid-gap: 15px;
      // grid-template-columns: repeat(2, 1fr);
      grid-template-columns: 40% 1fr !important;
      display: grid;
    }

    .verification-page_logo {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-top: 25px;
      @include mediaquery(md, max) {
        margin-bottom: 35px;
      }
      .register-logo-img {
        height: auto;
        width: 146px;
        @include mediaquery(md, max) {
        //   width: 95px;
		width: 120px;
        }
      }
    }

    .verify-mail-wrapper {
      .mail-deatils {
        width: 326px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        div {
          width: 100%;
        }
        
		.first-heading, .second-heading {
			text-align: left;
      padding: 0;
		}

        .email {
          font-weight: 600;
          color: $default-text-color;
          width: 100%;
        }

        .gmail-btn,
        .outlook-btn {
          margin-top: 50px;
          background: #ffffff;
          border: 1px solid $default-text-color;
          border-radius: 5px;
          font-weight: 600;
          font-size: 14px;
          // text-decoration-line: underline;
          color: $secondary-color;
          text-transform: capitalize;
          display: flex;
          gap: 10px;
          padding: 12px 0;
          font-family: $font-inter;
          // text-underline-position: under;
          @include mediaquery(md, max) {
            margin-top: 30px;
          }
        }

        .outlook-btn {
          margin-top: 15px;
        }

        .did-not-email-text {
          margin-top: 50px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: rgba(38, 38, 38, 0.7);
          text-underline-position: under;

          @include mediaquery(md, max) {
            margin-top: 20px;
            font-size: 14px;
            margin-bottom: 40px;
          }

          span {
            font-weight: 600;
            // text-decoration-line: underline;
            text-decoration: none;
            color: $secondary-color;
            cursor: pointer;
            display: inline-block;
          }
        }
      }
    }

    .verify-mail-box-wrapper {
      // background: $secondary-color;
      border-radius: 0px;
      width: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;

      .verify-mail-text-box {
        padding: 40px 0 0px 40px !important;
        div {
          border-bottom: 1px solid #c7d5e1;
          height: 50px;
          background-color: #fff;
        }

        .email {
          padding-left: 15px;
          display: flex;
          align-items: center;
        }

        .vertical-center {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: none;
            flex-basis: 50%;
            width: 100%;
          }

          .label {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $default-text-color;

            @include mediaquery(md) {
              font-size: 16px;
            }

          }

          .first-bar {
            width: 60px;
            height: 10px;
            left: 776px;
            top: 212px;
            background: #c7d5e1;
            opacity: 0.5;
            border-radius: 22px;

            @include mediaquery(md) {
              width: 95px;
            }
          }

          .second-bar {
            width: 100% !important;
            height: 10px;
            left: 1037px;
            top: 212px;
            background: #c7d5e1;
            opacity: 0.5;
            border-radius: 22px 0px 0px 22px;
          }
        }
      }
    }
  }
  }
}
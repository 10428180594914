  .pagination {
        @include mediaquery(md, max) {
            zoom: 66%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
        }
    .pagination-component-wrapper {

        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 10px 0px 10px 10px;
        
        .MuiPagination-root {
            .MuiPagination-ul {
                li {
                    &:first-child {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-weight: 500;
                        >button {
                            width: 100px;
                            font-family: $font-inter;
                            font-weight: 500;
                            @include mediaquery(md, max) {
                                width: 40px;
                            }
                            &::after {
                                margin-left: 10px;
                                content: 'Prev';
                                color: #fff;
                                @include mediaquery(md, max) {
                                    content: "";
                                    display: none;
                                }
                            }

                            &.Mui-disabled {
                                &::after {
                                    color: #686868;
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &:last-child {
                        font-weight: 500;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        >button {
                            width: 100px;
                            font-family: $font-inter;
                            font-weight: 500;
                            @include mediaquery(md, max) {
                                width: 40px;
                            }
                            
                            &::before {
                                margin-right: 10px;
                                content: 'Next';
                                @include mediaquery(md, max) {
                                    content: "";
                                    display: none;
                                }
                            }

                            &.Mui-disabled {
                                &::after {
                                    color: #686868;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }

                    .MuiPaginationItem-page {
                        background: rgba(0, 0, 0, 0.05);
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #686868;
                        border: none;
                        margin: 8px;
                        font-family: $font-inter;
                        &:hover {
                            background: $secondary-color;
                            color: #FFFFFF;
                        }
                    }

                    .Mui-selected {
                        color: pink;
                        background: $secondary-color;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #FFFFFF;
                        font-family: $font-inter;
                        &:hover {
                            background: #546eeb;
                        }
                        &:focus {
                            box-shadow:0px 0px 0 3px rgb(176 208 247) !important;
                            transition:opacity .25s,box-shadow .25s
                        }
                    }

                    .MuiPaginationItem-previousNext {
                        background: $secondary-color;
                        color: #fff;
                        &:hover {
                            background: #173ef0;
                        }
                        svg {
                            fill: #fff;
                        }
                        &:focus {
                            box-shadow:0px 0px 0 3px rgb(176 208 247) !important;
                            transition:opacity .25s,box-shadow .25s
                        }
                    }

                    .Mui-disabled {
                        background: rgba(0, 0, 0, 0.05) !important;
                        color: #686868;
                        // opacity: 1;

                        svg {
                            fill: #686868;
                        }
                    }
                }
            }
        }
    }
}
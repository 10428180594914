.product-view-container {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 10px;

    .coutry-flag {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 10px 10px 10px 0;

        .image-wrap {
            background: #ECECEC;
            border-radius: 17.5px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 18px;
                height: 18px;
                object-fit: contain;
            }
        }
    }

    .product-images-wrapper {
        position: relative;

        .product-image-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .no-product-images-found-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 5px;
        }

        .slider-wrapper {
            .slides-warp {
                .chevron-wrap {
                    .btn-next {
                        background: #FFFFFF;
                        box-shadow: 0px 0px 18px rgb(0 0 0 / 15%);
                        border-radius: 17.5px;
                        right: 3%;
                        cursor: pointer;
                        &:hover {
                            background: $secondary-color;
                            .next-arrow {
                                svg {
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    .btn-prev {
                        background: #FFFFFF;
                        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
                        border-radius: 17.5px;
                        left: 3%;
                        cursor: pointer;
                        &:hover {
                            background: $secondary-color;
                            .prev-arrow {
                                svg {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .disk-warp {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                bottom: 5%;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 15px;

                .disk {
                    background-color: #E7E7EA;
                    opacity: 0.5;

                    &.fill {
                        background: #E7E7EA;
                        height: 10px;
                        width: 10px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .variant-info-wrap {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #6E6E6E;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            color: #fff;
            background-color: $secondary-color;
            padding: 6px 11px 6px 11px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 700;
        }
    }

    .product-info-wrap {
        padding: 0 30px;

        .product-name-category-wrap {
            margin: 20px 0;
            border-top: 1px dashed #CCCCCC;
            border-bottom: 1px dashed #CCCCCC;
            padding: 20px 0;

            .product-name-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #262626;
                    word-break: break-word;
                }

                .view-text-wrap {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    // text-decoration-line: underline;
                    color: #6981F1;
                    text-transform: capitalize;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }

            .usps-wrap {
                margin-top: 20px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 10px;
                .usps {
                    // height: 29px;
                    background: #F3F3F3;
                    border-radius: 17.5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    color: #262626;
                    opacity: 0.65;
                    margin-right: 10px;
                    padding: 5px 7px 5px 11px;
                    border: #c0c8d2 dashed 1px;
                }
            }
        }
    }

    .product-item-wrap {
        padding: 0 30px;
        margin-top: 20px;

        .wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            .label {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }

            .value {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: right;
                color: #727272;
            }
        }

    }

    .product-price-wrap {
        margin: 20px 30px 0 30px;
        border-top: 1px dashed #CCCCCC;
        padding-top: 15px;

        .wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            .label {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }

            .price {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: $secondary-color;
            }
        }
    }
}
.buyer-header-wrapper {
    position: fixed;
    width: 100%;
    background: #080C29;
    // padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    z-index: 4;
    .logo-wrapper {
        flex: 1 1 15%;
        @include mediaquery(md, max) {
            flex: unset;
        }
        img {
            zoom: 80%;
            @include mediaquery(md, max) {
                zoom: unset;
            }
        }
    }
    .right-menu {
        flex: 1 1 85%;
        display: flex;
        @include mediaquery(md, max) {
            flex: unset;
        }
    }
    @include mediaquery(md, max) {
        overflow: hidden;
    }

    @include mediaquery(md) {
        // padding: 0px 30px;
    }

    .mobile-menu {
        svg {
            fill: #fff
        }
    }

    .logo-wrapper {
        // margin-right: 20px;
    }

    .vertical-line {
        opacity: 0.2;
        border-right: 1px solid #FFFFFF;
        height: 25px;
        margin: 0 30px;
    }

    .brand-head-profile {
        @include mediaquery(md, max) {
            width: 70px;
        }
    }

    .menu-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .menu {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            margin: 10px 20px;
            cursor: pointer;
            position: relative;
            padding: 21px 0;
            margin-top: 0;
            margin-bottom: 0;
            @include hover-underline-animation(#fff);

            &.selected {

                // color: $secondary-color;
                &::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            &::after {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                height: 4px;
            }

            &:hover {
                // color: $secondary-color;
            }
        }
    }

    .user-icon-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include mediaquery(md) {
            width: 100%;
            button {
                // zoom: 80%;
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .icon-navigation {
            height: 59px;

            .menu-wrapper {
                margin: 0 10px 0 0;
                display: flex;
                align-items: center;
                height: 59px;

                .support {

                    svg {
                        fill:#c4c4c4;

                        @include mediaquery(md,max) {
                            height: 25px;
                            width: 25px;
                        }
                    }
                }
                li {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    margin: 31px 15px;
                    cursor: pointer;
                    position: relative;

                    // padding: 31px 0;
                    // margin-top: 0;
                    // margin-bottom: 0;
                    @include mediaquery(md, max) {
                        margin: 31px 10px;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }

                    a {
                        position: relative;

                        .bubble-count {
                            content: "";
                            position: absolute;
                            background-color: #DD1F2C;
                            color: #fff;
                            font-size: 12px;
                            width: 28px;
                            height: 28px;
                            border-radius: 50px;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            top: -18px;
                            left: 7px;

                            @include mediaquery(md, max) {
                                font-size: 8px;
                                width: 24px;
                                height: 24px;
                                top: -15px;
                                left: 5px;
                            }
                        }

                        svg {
                            @include mediaquery(md, max) {
                                width: 16px;
                            }
                        }
                    }
                }
            }
        }

        .avatar {
            height: 30px;
            width: 30px;

            @include mediaquery(md, max) {
                height: 20px;
                width: 20px;
            }
        }

        .right-arrow {
            fill: #fff;
            transform: rotate(90deg);
            height: 20px;
            width: 20px;
            cursor: pointer;
            margin-left: 5px;

            @include mediaquery(md, max) {
                height: 14px;
                width: 14px;
            }
        }
    }

    .slider-menu-wrap {

        &.show-overlay {
            background: rgba(0, 0, 0, .5);
            bottom: 0;
            height: 100vh;
            left: 0;
            position: fixed;
            right: 0;
            transition: .5s;
            z-index: 6;
        }

        .slider-wrap {
            background-color: #fff;
            height: calc(100% - 60px);
            overflow-x: hidden;
            position: fixed;
            left: 0;
            top: 60px;
            transition: .5s;
            width: 0;
            z-index: 1;
            height: calc(100% - 60px);
            // width: 100%;

            .menu-content {
                padding: 15px;
                position: relative;

                .close-icon {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    svg {
                        fill: #c4c4c4;
                        height: 30px;
                        width: 30px;
                    }

                    &:hover {
                        svg {
                            fill: red;
                        }
                    }
                }

                .menu-icon {
                    font-size: 18px;
                    @include mediaquery(md, max) {
                        font-size: 16px;
                    }
                    .menu {
                        padding: 15px 0 15px 0;
                        border-bottom: #e0e0e0 solid 1px;
                    }
                }
            }

            &.show {
                width: 100%;
            }

            &.hide {
                width: 0;
            }
        }
    }
}
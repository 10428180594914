.rename-popup-wrapper {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1;

    .add-catalogue-popup {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 50%;
        overflow: auto;
        padding: 30px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 73%;
        z-index: 1;

        @include mediaquery(md) {
            width: 513px;
        }
    }

    .first-heading {
        font-weight: 600;
        font-size: 28px;
        line-height: 29px;
        color: #262626;
        @include mediaquery(md, max) {
            font-size: 20px;
        }
    }

    .second-heading {
        margin: 10px 0 20px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
    }

    form {
        position: relative;
        width: 100%;

        div {
            width: 85%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;

            @include mediaquery(md, max) {
                width: 100%;
            }

        }

    }

    .close-icon {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;

        svg {
            cursor: pointer;
            height: 25px;
            width: 25px;
            fill: #C4C4C4;
        }

        &:hover {
            svg {
                fill: red;
            }
        }
    }

    .add-new-catalogue-button {
        width: 73%;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-transform: capitalize;

        @include mediaquery(md, max) {
            width: 100%;
        }
    }

    .field-err-msg {
        margin-bottom: 10px;
    }
}
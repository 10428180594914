.web-catalogue-wrapper {
    overflow: hidden;

    //Code for make header static in catalogue website preview mode
    // &.web-catalogue-details-wrapper {
    //     overflow: initial;

    //     @include mediaquery(md, max) {
    //         overflow: initial;
    //     }
    // }
    @include mediaquery(md, max) {
        overflow: hidden;
    }

    .exit-preview-wrapper {
        background: $secondary-color;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
        @include mediaquery(md, max) {
            padding: 0 20px;
        }
        .text-wrap {
            display: flex;

            .heading,
            .description {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
            }

            .description {
                font-weight: 500;
                margin-left: 5px;
            }
        }

        .exit-text {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            // text-decoration-line: underline;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    .footer-catalogue-exit {
        .exit-preview-wrapper {
            margin: 0 50px;
            border-top: rgba(0, 0, 0, 0.1) solid 1px;
            background: transparent;
            justify-content: flex-end;
            padding: 0;

            .text-wrap {
                display: none;
            }

            .exit-text {
                background-color: $primary-btn-color;
                padding: 5px 13px;
                text-transform: capitalize;
                font-family: $font-inter;
                color: #fff;
                border-radius: 5px;

                &:hover {
                    background: lighten($primary-btn-color, 10%);
                }

                &:focus {
                    box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                    transition: opacity .25s, box-shadow .25s
                }
            }

            .skip-btn-white {
                @include hover-underline-animation(transparent);
                padding-bottom: 5px;
                cursor: pointer;
                color: white !important;
                font-size: unset;
                font-weight: 500;

                @include mediaquery(md, max) {
                    font-size: 14px !important;
                }

                @include mediaquery(md, max) {
                    &::after {
                        transform: scaleX(0);
                        transform-origin: bottom left;
                    }
                }
            }
        }
    }

    .preview-header-wrapper {
        padding: 0 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        @include mediaquery(md, max) {
            margin-top: 0px;
            padding: 0 18px 0 18px;
            justify-content: center;
            transform: scale(.8);
            display: block;
            text-align: center;
        }

        .logo-wrap {
            @include mediaquery(md, max) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
            }

            .logo {
                img {
                    height: 95px;
                    width: auto;
                    object-fit: contain;
                    border: #f1f1f1 solid 1px;
                    padding: 5px;
                    @include mediaquery(md, max) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .text-wrap {

            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-left: 40px;

            @include mediaquery(md, max) {
                margin-left: 0;
                text-align: center;
            }

            .flex-items-center {
                @include mediaquery(md, max) {
                    justify-content: center;
                }
            }

            .brand-name {
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                color: #262626;
                text-transform: capitalize;

                @include mediaquery(md, max) {
                    font-size: 24px;
                }
            }

            .brand-type {
                background: rgba(105, 129, 241, 0.3);
                border: 1px solid $secondary-color;
                border-radius: 20px;
                font-weight: 600;
                font-size: 12px;
                color: $secondary-color;
                padding: 5px 10px;
                margin-left: 15px;
            }

            .tagline {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #262626;
                opacity: 0.7;
                // text-transform: capitalize;

                @include mediaquery(md, max) {
                    text-align: center;
                }
            }
        }
    }

    .preview-banner-wrapper {
        height: auto;
        width: 100%;
        margin: 20px 0;

        @include mediaquery(md, max) {
            height: auto;
        }

        img {
            height: auto;
            width: 100%;
            // object-fit: cover;
            border: #f1f1f1 solid 1px;

            @include mediaquery(md, max) {
                height: auto;
                object-fit: unset;

            }
        }

        .no-image-found {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            color: #a6a6a6;
            font-size: 20px;
            font-weight: 400;

            @include mediaquery(md, max) {
                height: auto;
            }
        }
    }

    .manage-section {
        margin: 50px 50px 30px 50px;

        @include mediaquery(md, max) {
            margin: 30px 30px 40px 30px;
            border-bottom: #e6e6e6 solid 1px;
            padding-bottom: 11px;
        }

        // @include mediaquery(md, max) {
        //     margin: 50px 30px;
        // }

        .option-wrapper {
            display: flex;
            align-items: center;

            .option {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                cursor: pointer;

                @include mediaquery(md) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-right: 35px;
                }

                @include hover-underline-animation(#000);

                &.selected {
                    &::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &::after {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 4px;
                    bottom: -12px;
                }
            }
        }

        .divider {
            opacity: 0.1;
            border: 1px solid #000000;
            transform: rotate(0.26deg);
            margin-top: 13px;
        }
    }

    .profile-section {
        padding: 0 50px;

        @include mediaquery(md, max) {
            padding: 0 30px;
        }

        .information-wrap {
            display: flex;
            align-items: flex-start;
            gap: 30px;

            @include mediaquery(md, max) {
                flex-direction: column;
            }

            .info-section {
                flex: 1 1 80%;
                border-right: 2px dashed rgba(0, 0, 0, 0.2);
                margin-top: 50px;

                @include mediaquery(md, max) {
                    flex: unset;
                    width: 100%;
                    border-right: none;
                    margin-top: 0;
                }

                .vision-wrap {
                    @include mediaquery(md, max) {
                        margin-bottom: 0 !important;
                    }
                }

                .story-wrap,
                .mission-wrap,
                .vision-wrap {
                    margin-bottom: 50px;

                    .heading {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        color: #262626;
                    }

                    .description {
                        margin-top: 20px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        color: rgba(38, 38, 38, 0.7);
                        padding-right: 30px;
                        word-break:break-word;

                        @include mediaquery(md, max) {
                            padding-right: 0;
                        }

                        li {
                            list-style-type: disc;
                        }

                        ol {
                            @include mediaquery(md, max) {
                                padding-left: 20px;
                            }
                            li {
                                list-style-type: auto;
                                word-break: break-word;
                            }
                        }
                    }

                }
            }

            .overview-section {
                flex: 1 1 20%;
                margin-top: 50px;

                @include mediaquery(md, max) {
                    margin-top: 0 !important;
                }

                .overview-text {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    color: #262626;
                    text-transform: capitalize;
                }

                .brand-origin,
                .year-trading,
                .number-of-skus,
                .msrp-range {
                    margin: 25px 0;

                    .text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #262626;
                        margin-bottom: 12px;
                    }

                    .country-img {

                        background: #ECECEC;
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            height: 20px;
                            width: 20px;
                            object-fit: contain;
                        }
                    }

                    .desc {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #6E6E6E;
                    }
                }

                .social-section {
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .text {
                        padding-top: 30px;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        color: #262626;
                    }

                    .social-icons {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 15px;
                        margin-top: 10px;

                        .fb {
                            &:hover {
                                svg {
                                    path {
                                        &:first-of-type {
                                            fill: $secondary-color;
                                        }
                                    }
                                }
                            }
                        }

                        .twitter,
                        .instagram,
                        .linkedin,
                        .website {

                            svg {
                                height: 15px;
                                width: 15px;
                            }

                            &:hover {
                                svg {
                                    path {
                                        fill: $secondary-color;
                                    }
                                }
                            }
                        }

                        a {
                            color: #262626;
                        }
                    }
                }
            }
        }

        .some-of-product-wrap {
            margin-top: 50px;
            width: 75%;
            margin-bottom: 50px;

            @include mediaquery(md, max) {
                width: 100%;
            }

            .head-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .heading {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    color: #262626;

                    @include mediaquery(md, max) {
                        width: calc(100% - 80px);
                    }
                }

                .view-more {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    // text-decoration-line: underline;
                    color: $secondary-color;
                    mix-blend-mode: normal;
                    cursor: pointer;
                }
            }

            .product-cards {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                margin-top: 10px;
                gap: 50px;

                @include mediaquery(md, max) {
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                    overflow-scrolling: touch;
                    display: grid;
                    grid-auto-flow: column;
                    gap: 0;
                }

                &.some-of-our-pro {
                    .product-card-wrapper {
                        @include mediaquery(md, max) {
                            zoom: 70%;
                        }
                    }
                }
            }
        }
    }

    .products-section {
        padding: 0 50px;
        position: relative;

        @include mediaquery(md, max) {
            padding: 0 30px;
        }

        .search-section {
            display: flex;
            align-items: center;
            gap: 10px;

            .position-relative {
                [tooltip] {

                    &::before,
                    &::after {
                        display: none;

                        @include mediaquery(md, max) {
                            display: block;
                        }
                    }
                }
            }

            position: absolute;
            right: 50px;
            top: -97px;

            @include mediaquery(md, max) {
                position: unset;
            }

            .position-relative {
                @include mediaquery(md, max) {
                    width: 100%;
                }
            }

            input {
                padding: 6px 35px 6px 10px !important;
                width: 100%;
                font-family: $font-inter;

                &::placeholder {
                    font-size: 12px;

                    @include mediaquery(md, max) {
                        color: white;
                    }
                }
            }

            .search-product {
                margin: 0;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                div {
                    width: 370px;

                    @include mediaquery(md, max) {
                        width: 100%;
                    }
                }
            }

            .close-icon {
                position: absolute;
                top: 17%;
                right: 2%;
                cursor: pointer;
            }

            .search-icon {
                background: #000000;
                border-radius: 5px;
                height: 33px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin: 0 0 0 10px;
                cursor: pointer;
                border: #000 solid 1px;

                svg {
                    path {
                        stroke: #fff;
                    }
                }

                &:hover {
                    background: #fff;
                    border: #000 solid 1px;

                    svg {
                        path {
                            stroke: #000;
                        }
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0 3px rgb(211, 210, 210) !important;
                    transition: opacity .25s, box-shadow .25s
                }

                @include mediaquery(md, max) {
                    width: 57px;
                }

            }
        }

        .filter-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            @include mediaquery(md, max) {
                margin-top: 20px;
            }

            .tooltip-icon-product {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .result-text {
                font-weight: 600;
                font-size: 26px;
                line-height: 30px;
                color: #000000;
                text-transform: capitalize;

                @include mediaquery(md, max) {
                    font-size: 22px;
                }
            }

            .tooltip-element-product {
                .tooltip-icon-product {
                    &:hover {
                        .icon-click-text {
                            color: $primary-btn-color;
                        }
                        svg {
                            fill: $primary-btn-color;
                        }
                    }
                }
            }
        }

        .product-list-wrapper {
            margin-top: 50px;
            padding-bottom: 50px;

            @include mediaquery(md, max) {
                margin-top: 30px;
            }

            .category {
                margin-bottom: 50px;
                page-break-before: always;

                @include mediaquery(md, max) {
                    margin-bottom: 0;
                }

                .category-name-wrap {

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 18px;
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

                    .category-name {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        color: #000000;
                        text-transform: capitalize;

                        @include mediaquery(md, max) {
                            font-size: 20px;
                        }
                    }

                    .arrow-icon {
                        cursor: pointer;

                        svg {
                            height: 30px;
                            width: 30px;
                        }

                        &:hover {
                            svg {
                                fill: $secondary-color;
                            }
                        }
                    }
                }

                .product-list {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    margin-top: 20px;
                    gap: 30px;
                    row-gap: 40px;

                    @include mediaquery(md, max) {
                        overflow-x: auto;
                        -webkit-overflow-scrolling: touch;
                        overflow-scrolling: touch;
                        display: grid;
                        grid-auto-flow: column;
                        gap: 0;
                    }

                    .pro-items-cards {
                        @include mediaquery(md, max) {
                            zoom: 70%;
                        }

                        &:nth-child(6n) {
                            page-break-after: auto;
                        }

                        &:last-child {
                            page-break-after: avoid;
                        }

                        &:hover {
                            // box-shadow: #a8a9aa 0 6px 24px 0;
                            // border-radius: 10px;

                            box-shadow: 0 2px 16px 4px rgb(40 44 63 / 7%);
                            border-radius: 5px;

                            // box-shadow:0px 0px 0 5px rgb(216 223 255) !important;
                            //     transition:opacity .25s,box-shadow .25s;    
                            // border-radius: 5px;
                            // border: $secondary-color solid 1px;
                            @include mediaquery(md, max) {
                                box-shadow: unset;
                                border-radius: unset;
                            }

                            .product-image {
                                .slider-wrapper {
                                    .slides-warp {
                                        .slides {
                                            .slide {
                                                .image-wrap {
                                                    img {
                                                        // transform: scale(1);
                                                        opacity: 1;
                                                        animation: flash 1.5s;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .no-data-found {
                padding-bottom: 20px;

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                    padding: 30px 0;
                    border: #f1f1f1 solid 1px;
                    color: #797979;
                }
            }
        }

        .load-more-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0;

            .loader-component-wrap {
                background: #EAF5FF;
                border-radius: 9px;
                height: 35px;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
            }

            .loader-center {
                position: relative;
                background: transparent;

                .three-bounce-spinner {
                    margin: 0;
                }
            }
        }
    }

    .product-card-wrapper {
        // max-width: 300px;
        // width: 280px;
        // height: auto;
        // position: relative;
        // margin: 30px;
        // padding: 10px;
        border: #fff solid 1px;
        padding: 10px 10px 10px 10px;

        @include mediaquery(md, max) {
            padding: 0;
        }

        .show-for-print-pdf {
            display: none;
        }

        .product-image {
            .slider-wrapper {
                .slides-warp {
                    .slides {
                        height: 300px !important;

                        .slide {
                            // display: table;
                            width: 100%;
                            height: 100%;

                            .image-wrap {
                                // display: table-cell;
                                // vertical-align: middle;
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                align-items: center;
                                height: 100%;

                                img {
                                    // max-width: 586px;
                                    // max-height: 700px;
                                    width: 100%;
                                    height: auto;
                                    // height: unset !important;
                                    object-fit: unset;
                                    transform: scale(.9);
                                }
                            }
                        }
                    }
                }
            }
        }



        @-webkit-keyframes flash {
            0% {
                opacity: .4;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes flash {
            0% {
                opacity: .4;
            }

            100% {
                opacity: 1;
            }
        }


        @include mediaquery(md, max) {
            max-width: 300px;
            width: 280px;
            height: auto;
            position: relative;
            margin: 30px;
        }

        @include mediaquery(md, max) {
            margin: 30px 30px 30px 0px;
        }

        &:first-of-type {
            margin-left: 0;
        }

        .card-body {

            // padding: 0 10px;
            .cn-title-wrap {
                display: flex;
                gap: 10px;
                margin-top: 15px;
                justify-content: space-between;
                // margin-bottom: 10px;

                &.show-border {
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
                }

                .product-name {
                    .ellipsis-for-2-line {
                        height: 38px;
                    }
                }
            }
        }

        .flag-img-wrap {
            // position: absolute;
            // z-index: 1;
            // right: 3%;
            // top: 3%;

            .flag-img {
                background: #ECECEC;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0px;
                // margin-top: 20px;

                img {
                    height: 15px;
                    width: 15px;
                    object-fit: contain;
                }
            }
        }

        .product-name {

            >div,
            >a {
                word-break: break-all;
            }
        }

        .product-image {
            position: relative;

            .image-wrap {
                background-color: #FFFFFF;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 300px;
                    border-radius: 0;
                    object-fit: cover;

                    // transition: transform .2s;
                    @include mediaquery(md, max) {
                        width: 300px;
                    }

                    &:hover {
                        // transform: scale(1.2);
                    }
                }

                .pro-img {
                    width: 310px;
                }

                .pro-img-wrap {
                    cursor: pointer;
                }
            }

            .chevron-wrap {

                .btn-prev,
                .btn-next {
                    padding: 0;

                    .next-arrow,
                    .prev-arrow {
                        background: #fff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        width: 30px;
                        cursor: pointer;

                        svg {
                            zoom: 70%;
                        }

                        &:hover {
                            background: $secondary-color;

                            svg {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .disk-warp {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                bottom: 0%;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 15px;

                .disk {
                    background: #E7E7EA;
                    opacity: 0.5;

                    &.fill {
                        background: #E7E7EA;
                        opacity: 1;
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }

        .no-image-available {
            height: 300px;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #C7C7C7;

            img {
                height: 300px;
                width: 100%;
            }
        }

        .product-name {

            // margin-bottom: 15px;
            a,
            >div {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                color: #262626;
                margin: 0 0 0 0;
                // border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
                padding-bottom: 15px;
                display: block;
                text-underline-position: under;
                line-height: 25px;
                word-break: break-word;

                &:hover {
                    color: $secondary-color;
                }

                cursor: pointer;
                text-decoration: none;
            }
        }

        .no-usps-found {
            border-top: 1px dashed rgba(0, 0, 0, 0.5);
            padding-top: 15px;
        }

        .view-more-less-wrap {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            // text-decoration-line: underline;
            color: #6981F1;
            display: flex;
            align-items: center;
            // cursor: pointer;
            position: relative;

            padding-bottom: 4px;

            .skip-btn {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-top: 10px;
            }

            a {
                position: absolute;
                left: 95px;
                top: -15px;
                width: 195px;
                height: 58px;
            }
        }

        .usps-wrap {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;
            row-gap: 10px;
            margin-bottom: 10px;

            .usps {
                align-items: center;
                background: #f3f3f3;
                border: 1px dashed #c0c8d2;
                border-radius: 17.5px;
                color: #262626;
                display: flex;
                font-size: 12px;
                font-weight: 600;
                justify-content: center;
                line-height: 17px;
                margin-right: 10px;
                // opacity: .65;
                padding: 5px 7px 5px 11px;
                word-break: break-all;
            }
        }

        .product-info-wrapper {

            cursor: pointer;

            .product-info {

                &.show-border {
                    border-top: 1px dashed rgba(0, 0, 0, 0.5);
                    margin-top: 0px;

                    @include mediaquery(md, max) {
                        padding: 2px 0;
                    }
                }

                .info {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin: 6px 0;
                    // gap: 74px;

                    .label {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #727272;

                        @include mediaquery(md, max) {
                            font-size: 16px;
                        }
                    }

                    .value {
                        text-align: right;
                        word-break: break-all;
                        width: 50%;
                        color: #262626;

                        .available {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: #262626;

                            @include mediaquery(md, max) {
                                font-size: 16px;
                            }
                        }

                        .non-available {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 17px;
                            color: #262626;
                        }
                    }
                }

                .price-info {
                    border-top: 1px dashed rgba(0, 0, 0, 0.5);
                }
            }
        }
    }

    .email-verification-popup {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;

        .email-verification-wrap {
            align-items: center;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 50%;
            overflow: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 400px;
            z-index: 1;
            position: relative;

            @include mediaquery(md, max) {
                zoom: 70%;
            }

            .close-icon {
                align-items: flex-end;
                display: flex;
                justify-content: flex-end;
                width: 100%;

                position: absolute;
                top: 10px;
                right: 10px;

                svg {
                    fill: #c4c4c4;
                    cursor: pointer;
                    height: 25px;
                    width: 25px;

                    &:hover {
                        fill: red;
                    }
                }
            }

            .first-heading {
                margin-top: 15px;
                color: #262626;
                font-size: 18px;
                font-weight: 600;
                line-height: 29px;
                width: 100%;
                text-align: center;
            }

            .second-heading {
                color: dimgray;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                margin: 0px 0 20px;
                width: 100%;
                text-align: center;
            }

            form {
                position: relative;
                width: 100%;

                div {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    // width: 85%;
                    width: 100%;

                    .field-err-msg {
                        left: 0 !important;
                    }

                    button {
                        // width: 73%;
                        width: 100%;
                    }
                }
            }

            .brand-information-wrap {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: flex-start;
                border-bottom: 1px solid #f1f1f1;
                padding: 15px 0;

                .brand-logo {
                    width: 100px;

                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 0px;
                        border: #f1f1f1 solid 1px;
                        padding: 5px;
                    }
                }

                .catalogue-error-icon {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        fill: #ba3939;
                        height: 50px;
                        width: 50px;
                    }
                }

                .brand-info {
                    margin-left: 20px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .name {
                        color: #262626;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        text-transform: capitalize;
                    }

                    .tagline {
                        color: #262626;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                        opacity: .7;
                    }
                }
            }
        }
    }

    .no-usps-found {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #727272;
    }

    .save-catalogue {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .login-section-wrapper {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;

        .login-wrap {
            align-items: center;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 50%;
            overflow: auto;
            padding: 30px 50px;
            position: fixed;
            top: 40%;
            transform: translate(-50%, -50%);
            width: 500px;
            z-index: 1;

            @include mediaquery(md, max) {
                // zoom: 70%;
                width: 70%;
            }

            .heading-wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;

                @include mediaquery(md, max) {
                    margin: 25px 0 0 0;
                }

                .arrow-icon {
                    svg {
                        fill: #c4c4c4;
                        cursor: pointer;
                        height: 25px;
                        width: 25px;
                    }

                    &:hover {
                        svg {
                            fill: $primary-btn-color;
                        }
                    }
                }

                .close-icon {
                    align-items: flex-end;
                    display: flex;
                    justify-content: flex-end;
                    // width: 100%;
                    // position: absolute;
                    // top: 10px;
                    // right: 10px;

                    svg {
                        fill: #c4c4c4;
                        cursor: pointer;
                        height: 25px;
                        width: 25px;
                    }


                    svg {
                        &:hover {
                            fill: red;
                        }
                    }
                }
            }

            .register-wrapper {
                .register-inner_container {
                    height: 500px;

                    .alert-message-component-wrap {
                        position: sticky !important;
                        transform: unset;
                    }

                    .form-input-control {
                        .form-control {
                            .button-wrapper {
                                padding-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }

        .login-component-wrap {
            // width: 70%;
            display: block;

            .first-heading {
                padding-top: 10px;
            }

            .form-input-control {
                .forgot-password-label {
                    margin-bottom: 0;
                    @include mediaquery(md, max) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

    }

    .blur-background {
        filter: blur(5px);
        -webkit-filter: blur(5px);
    }
}

.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
    display: block !important;
  }  
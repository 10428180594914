
// underline animation on hover move from left to right

@mixin hover-underline-animation ($color) {
	display: inline-block;
	position: relative;
	color: $color;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: $color;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}

	&:hover {
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

.tab-items {
	@include hover-underline-animation(#000);
	&.selected {
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}
.skip-btn {
    @include hover-underline-animation($secondary-color);
    padding-bottom: 5px;
	cursor: pointer;
	@include mediaquery(md, max) {
		font-size: 14px !important;
	}
	@include mediaquery(md, max) {
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

.skip-btn-white {
    @include hover-underline-animation(#fff);
    padding-bottom: 5px;
	@include mediaquery(md, max) {
		font-size: 14px !important;
	}
	@include mediaquery(md, max) {
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}
// underline animation on hover move from left to right



// Moving hover line on elements
$gray: #97A8B8;

// Basic styles
.on-hover-draw-line {
	background: none;
	border: 0;
	box-sizing: border-box;

	// Using inset box-shadow instead of border for sizing simplicity
	box-shadow: inset 0 0 0 1px $gray;

	// Required, since we're setting absolute on pseudo-elements
	position: relative;
	vertical-align: middle;

	&::before,
	&::after {
		box-sizing: inherit;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.on-hover-draw-line {
    	transition: color 0.25s;

	&::before,
	&::after {
		// Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
		border: 1px solid transparent;
		width: 0;
		height: 0;
	}

	// This covers the top & right borders (expands right, then down)
	&::before {
		top: 0;
		left: 0;
	}

	// And this the bottom & left borders (expands left, then up)
	&::after {
		bottom: 0;
		right: 0;
	}

	// Hover styles
	&:hover::before,
	&:hover::after {
		width: 100%;
		height: 100%;
	}

	&:hover::before {
		border-top-color: $secondary-color; // Make borders visible
		border-right-color: $secondary-color;
		transition:
		width 0.25s ease-out, // Width expands first
		height 0.25s ease-out 0.25s; // And then height
		border-radius: 10px;
	}

	&:hover::after {
		border-bottom-color: $secondary-color; // Make borders visible
		border-left-color: $secondary-color;
		transition:
		border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
		width 0.25s ease-out 0.5s, // And then exanding width
		height 0.25s ease-out 0.75s; // And finally height
		border-radius: 10px;
	}
}

// Moving hover line on elements
